// nota fiscal
const NotasFiscais = {
  text: 'Notas Fiscais',
  link: '/notafiscal/notasFiscais',
  icon: 'fas fa-file-invoice-dollar'
};

const MeuPerfil = {
       text: 'Meu perfil',
      link: '/notafiscal/cadastros/emitentes',
      icon: 'fas fa-user-cog'
};

const EmitirNovaNotaFiscal = {
  text: 'Emitir Nova Nota Fiscal',
  link: '/notafiscal/emitir-nova-nota-fiscal',
  icon: 'fas fa-file'
};

const Produtos = {
  text: 'Produtos',
  link: '/notafiscal/cadastros/produtos',
  icon: 'fas fa-cubes'
};

const Emissores = {
  text: 'Emissores',
  link: '/notafiscal/contador',
  icon: 'fas fa-cubes'
};
const EmissorPerfil = {
  text: 'Perfil do emissor',
 link: '/notafiscal/cadastros/emitentes',
 icon: 'fas fa-user-cog'
};

const Unidades = {
  text: 'Unidades',
  link: '/notafiscal/cadastros/unidades',
  icon: 'fas fa-cube'
};

const Cliente = {
  text: 'Clientes',
  link: '/notafiscal/cadastros/destinatarios',
  icon: 'fas fa-file-import'
};

const Natureza = {
  text: 'Natureza',
  link: '/notafiscal/cadastros/naturezaop',
  icon: 'fas fa-hand-holding-usd'
}

const Impostos = {
  text: 'Classe de Imposto',
  link: '/notafiscal/cadastros/impostos',
  icon: 'fas fa-landmark'
}

const InfosAdicionais = {
  text: 'Informações Adicionais',
  link: '/notafiscal/cadastros/infosAdicionais',
  icon: 'fas fa-sticky-note'
}

const Rascunhos = {
  text: 'Rascunhos',
  link: '/notafiscal/cadastros/rascunhos',
  icon: 'fas fa-pencil-ruler'
}
// const ContadorPainel = {
//   text: 'Info do Emissor',
//   link: '#',
//   icon: 'fas fa-chart-area',
//   submenu: [
//     EmissorPerfil,
//     NotasFiscais,
//     EmitirNovaNotaFiscal,
//     Produtos,
//     Unidades,
//     Cliente,
//     Natureza,
//     Impostos,
//     InfosAdicionais,
//     Rascunhos
//
//   ]
// };

export const menuNotaFiscal = [
  MeuPerfil,
  NotasFiscais,
  EmitirNovaNotaFiscal,
  Produtos,
  Unidades,
  Cliente,
  Natureza,
  Impostos,
  InfosAdicionais,
  Rascunhos
];
export const menuNotaFiscalContador = [
  EmissorPerfil,
  NotasFiscais,
  EmitirNovaNotaFiscal,
  Produtos,
  Unidades,
  Cliente,
  Natureza,
  Impostos,
  InfosAdicionais,
  Rascunhos
];
export const menuNotaFiscalContadorBloqueado = [
  Emissores,
];
