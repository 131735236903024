import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Snackbar} from "../../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../../util/error";
import {CnpjValidator, CpfValidator} from "../../../../../../util/customValidators/cpfCnpjValidator";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-cadastro-emitente',
  templateUrl: './cadastro-emitente.component.html',
  styleUrls: ['./cadastro-emitente.component.scss']
})

export class CadastroEmitenteComponent implements OnInit {

  @Input() enableClearOnEscapePressed = true;
  @Input() public emitenteExistente;

  emitente: Emitente;
  public emitenteAux: Emitente = new Emitente();
  editar: boolean = false;
  emitenteForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;


  constructor(public activeModal: NgbActiveModal,
              public snackbar: Snackbar,
              public erroUtil: ErrorUtil,
              public fb: FormBuilder,) {}

  ngOnInit() {
    this.emitenteForm = this.fb.group({
      name: ['', Validators.required],
      cpfCNPJ: ['', Validators.compose( [Validators.required, CnpjValidator, CpfValidator])],
      fant: ['', Validators.required],
      ie: ['', Validators.required],
      crt: ['', Validators.required],
    });
  }

  editEmitente(){

  }
  newEmitente(){

  }
}

export class Emitente{
  cpfCNPJ: number;
  name: string;
  fant: string;
  ie: number;
  crt: string;
  default: boolean;
}
