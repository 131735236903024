import {Component, Inject, Input, OnInit} from '@angular/core';
import {ConnectionService} from "ng-connection-service";

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent implements OnInit {

  status = 'ONLINE';
  isConnected = true;


  constructor(    private connectionService: ConnectionService,) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        // this.snackBar.openCustom('Conexão normalizada!', 'bg-primary', 'end', 'bottom', 10000);
        this.status = "ONLINE";
      }
      else {
        // this.snackBar.openCustom('Atenção! Sem conexão!', 'bg-danger', 'end', 'bottom', 10000);
        this.status = "OFFLINE";
      }
    })
  }

  ngOnInit() {
  }

}
