import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {DialogComponent} from "@syncfusion/ej2-angular-popups";
import { EmitType } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input() public pdfUrl: string;

  @ViewChild('ejDialog', {static: true}) ejDialog: DialogComponent;
  // Create element reference for dialog target element.
  // @ViewChild('container', {static: false, read: ElementRef }) container: ElementRef;
  // The Dialog shows within the target element.

  public targetElement: HTMLElement;
  public closeOnEscape: boolean = false;
  public pdfSecure: any;

  constructor(public modalService: NgbModal,
              private sanitizer: DomSanitizer,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.pdfSecure = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
    this.initilaizeTarget();
  }

  // Initialize the Dialog component's target element.
  public initilaizeTarget: EmitType<object> = () => {
    // this.targetElement = this.container.nativeElement.parentElement;
  }

  // Sample level code to handle the button click action
  public onOpenDialog = function(event: any): void {
    // Call the show method to open the Dialog
    this.ejDialog.show();
  }


}
