import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatAutocompleteTrigger, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ImpostoProvider} from 'src/providers/nfe/imposto';
import Swal from 'sweetalert2';
import {CadastroImpostosComponent} from './cadastro-impostos/cadastro-impostos.component';
import { CheckPermission } from 'src/service/checkPermission';


@Component({
  selector: 'app-impostos',
  templateUrl: './impostos.component.html',
  styleUrls: ['./impostos.component.scss']
})
export class ImpostosComponent implements OnInit {

  displayedColumns = ['editar', 'excluir', 'isShared','description'];
  public carregando: Boolean = true;
  public impostos: any = [];
  dataSource: MatTableDataSource<any>;
  formGroup: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('trigger', { static: false }) trigger: MatAutocompleteTrigger;


  constructor(private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public impostoProvider: ImpostoProvider,
    public checkPermission: CheckPermission) { }

  ngOnInit() {
    try {
      this.generateTable();

      this.formGroup = this.formBuilder.group({
        description: ['', Validators.required],
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  generateTable() {
    this.carregando = true;
    this.impostos = [];
    this.getImpostos().then(() => {
      this.loadTable();
      this.carregando = false;
    });
  }
  
  getImpostos() {
    return new Promise((resolve, reject) => {
      this.impostoProvider.getImposto().then((result : any) => {
        this.impostos = result.sort((a, b) => {
          if (a.isShared === b.isShared) {
            return a.description.localeCompare(b.description);
          }
          return a.isShared ? -1 : 1;
        });
        resolve(true);
      }).catch(err => {
        this.carregando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
     });
    });
  }

  loadTable() {
    this.dataSource = new MatTableDataSource<any>(this.impostos);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  newImposto() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      const modalRef = this.modalService.open(CadastroImpostosComponent, modalOptions);
      modalRef.result.then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          text: "A classe de imposto foi adicionada!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        })
        this.generateTable();
      }, () => {
        //this.generateTable();
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: "Não foi possivel adicionar a classe de imposto",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
    }
  }

  editImposto(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try{
      const modalRef = this.modalService.open(CadastroImpostosComponent, modalOptions);
      modalRef.componentInstance.editar = true;
      modalRef.componentInstance.impostoExistente = row;
      modalRef.result.then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          text: "A classe de imposto foi editada!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        })
        this.generateTable();
      }, () => {
      });
    }
    catch (error){
      Swal.fire({
        title: 'Erro!',
        text: "Não foi possivel editar a classe de imposto",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
    }
  }

  deleteImposto(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível deletar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar?',
      text: "Não há como reverter isso!",
      reverseButtons: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#f05050',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando = true;
        this.impostoProvider.deleteImposto(row.id).then((result) => {
          this.generateTable();
          Swal.fire(
            'Sucesso!',
            'A classe de imposto foi deletada!',
            'success'
          )
        }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });
      }
    });
  }

}
