import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Country} from "../../model/basicos/Country";

@Injectable()
export class CountriesProvider {

  apiCountry: string = "/general-data/v1/country";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCountry,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Country[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  getCountryById(id) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCountry+'/'+id,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Country[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
