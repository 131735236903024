import {PersonDTOResponse} from "../dfe/PersonDTOResponse";
import {Pessoa} from "../../Pessoa";
import {Injectable} from "@angular/core";
import {RemoveEspecialCaracters} from "../../../util/pipes/removeEspecialCaracters";

@Injectable()
export class PessoaMapper {

  public serverModelToClientModel(apiModel: PersonDTOResponse): Pessoa {

    const pessoa = new Pessoa(); // your Person constructor

    if (apiModel != null) {
      let pipe = new RemoveEspecialCaracters();
      // map each property
      pessoa.id = apiModel.id;
      pessoa.cpf = apiModel.cpfCNPJParticipant;
      pessoa.cnpj = apiModel.cpfCNPJParticipant;
      pessoa.nome = pipe.transform(apiModel.name).toUpperCase();
      pessoa.ie = apiModel.ie;
    }

    return pessoa; // and return YOUR model
  }

}
