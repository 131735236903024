import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfosAdicionaisProvider } from 'src/providers/nfe/infosAdicionais';
import { ErrorUtil } from 'src/util/error';
import { Snackbar } from 'src/util/snackbar';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastro-infos-adicionais',
  templateUrl: './cadastro-infos-adicionais.component.html',
  styleUrls: ['./cadastro-infos-adicionais.component.scss']
})
export class CadastroInfosAdicionaisComponent implements OnInit {

  @Input() enableClearOnEscapePressed = true;

  infosAdicionaisForm: FormGroup
  infosAdicionaisExistente: any;
  enviado: boolean = false;
  enviando: boolean = false;
  editar: boolean = false;
  public isShared: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackbar: Snackbar,
    public erroUtil: ErrorUtil,
    public infosAdicionaisProvider: InfosAdicionaisProvider, 
    public checkPermission: CheckPermission
  ) { }

  ngOnInit() {
    try {
      this.infosAdicionaisForm = this.fb.group({
        description: ['', Validators.required],
        infoCompl: [''],
        infoFisco: [''],

      });

    } catch (error) {
      console.error(error)
    }
    if (this.editar) {
      this.fillInfosAdicionaisForm();
    }
  }


  fillInfosAdicionaisForm() {
    this.infosAdicionaisForm.controls.description.setValue(this.infosAdicionaisExistente.description);
    this.infosAdicionaisForm.controls.infoCompl.setValue(this.infosAdicionaisExistente.additionalInfo);
    this.infosAdicionaisForm.controls.infoFisco.setValue(this.infosAdicionaisExistente.infoFisco);
    this.isShared = this.infosAdicionaisExistente.isShared;
  }

  setInfosAdicionaisObj() {
    let obj = {
      description: this.infosAdicionaisForm.get('description').value,
      additionalInfo: this.infosAdicionaisForm.get('infoCompl').value,
      infoFisco: this.infosAdicionaisForm.get('infoFisco').value,
      isShared: this.isShared
    }
    if (this.editar) {
      obj['id'] = this.infosAdicionaisExistente.id;
    }
    return (obj);
  }

  newInfosAdicionais() {
    this.enviado = true;
    if (this.infosAdicionaisForm.valid) {
      this.enviando = true;
      this.infosAdicionaisProvider.postInfosAdicionais(this.setInfosAdicionaisObj()).then((result) => {
        this.activeModal.close('success');
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        this.enviado = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  editInfosAdicionais() {
    this.enviado = true;
    if (this.infosAdicionaisForm.valid) {
      this.enviando = true;
      this.infosAdicionaisProvider.putInfosAdicionais(this.setInfosAdicionaisObj()).then((result) => {
        this.activeModal.close('success');
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        this.enviado = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      });
    }
  }

}
