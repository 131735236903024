import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment.prod';
import {ReceiverDTOResponse} from '../../model/dto/ReceiverDTOResponse';

@Injectable()
export class ReceiverProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/invoice-issuer/v1/nfe/"+localStorage.getItem('userId');

  setApiBase(){
    this.apiBase = "/invoice-issuer/v1/nfe/"+localStorage.getItem('userId');
  }

  getAllReceivers() {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getAllReceiversByDate(dataInicial,dataFinal) {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + '/period/'+dataInicial+'/'+dataFinal, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            //console.log("RESULT", result);
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getAllReprovedReceiversByDate(dataInicial, dataFinal){
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + '/period/'+dataInicial+'/'+dataFinal+'/reproved/0/1000/ASC', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            //console.log("RESULT", result);
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getReceiverById(id: string) {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('id') + "/" + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postReceiver(receiver: ReceiverDTOResponse) {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, receiver, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putReceiver(receiver: ReceiverDTOResponse) {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, receiver, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delReceiver(id: number) {
    this.setApiBase();
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  cancelNfe(obj){
    this.setApiBase();
    return new Promise ((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase + '/cancellation', obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
      .subscribe((result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      });
    });
  }

  editNfe(obj){
    this.setApiBase();
    // console.log("obj", obj);
    return new Promise ((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase + '/correction-letter', obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
      .subscribe((result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      });
    });
  }

  getNfe(userId, nfeId){
    this.setApiBase();
    return new Promise ((resolve, reject) => {
      this.http.get(environment.apiLogin + "/invoice-issuer/v1/nfe/" + 'editing/' + userId + '/' + nfeId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
      .subscribe((result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      });
    });
  }

  getFileDfeLote(selectedDocs, type){
    this.setApiBase();
    return new Promise ((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}/zip/${type}`, selectedDocs, {responseType: 'blob' ,headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
