import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {City} from "../../model/basicos/City";

@Injectable()
export class CitiesProvider {

  apiCity: string = "/general-data/v1/city/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getCity(idCity: number) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCity + idCity,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: City[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
        });
    });
  }

  getCityByState(idEstado) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCity + "by_state/"  + idEstado,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: City[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  getCityById(id) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCity + id,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: City[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
