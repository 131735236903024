import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSelectChange} from '@angular/material';
import {EditSettingsModel} from '@syncfusion/ej2-angular-treegrid';
import {ProductProvider} from 'src/providers/nfe/product';
import * as moment from 'moment';
//import { NgxPaginationModule } from 'ngx-pagination'; // Módulo da dependência de paginação
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CadastroDestinatarioComponent} from '../cadastros/destinatarios/cadastro-destinatario/cadastro-destinatario.component';
import {CadastroNaturezaopComponent} from '../cadastros/naturezaop/cadastro-naturezaop/cadastro-naturezaop.component';
import {HttpErrorResponse} from '@angular/common/http';
import {nfeJsonProvider} from 'src/providers/nfe/nfeJson';
import {UserProvider} from 'src/providers/user/user';
import {OpnatureProvider} from 'src/providers/nfe/opnature';
import Swal from 'sweetalert2';
import {CadastroImoveisComponent} from '../cadastros/cadastro-imoveis/cadastro-imoveis.component';
import {CadastroImpostosComponent} from '../cadastros/impostos/cadastro-impostos/cadastro-impostos.component';
import {CountriesProvider} from 'src/providers/basicos/countries';
import {StatesProvider} from 'src/providers/basicos/states';
import {CitiesProvider} from 'src/providers/basicos/cities';
import {DatePicker} from '../../../../util/datePicker';
import {ImpostoProvider} from 'src/providers/nfe/imposto';
import {InfosAdicionaisProvider} from 'src/providers/nfe/infosAdicionais';
import {ReceiverProvider} from 'src/providers/nfe/receiver';
import {CadastroInfosAdicionaisComponent} from '../cadastros/infos-adicionais/cadastro-infos-adicionais/cadastro-infos-adicionais.component';
import {Snackbar} from 'src/util/snackbar';
import {CadastroRascunhosComponent} from '../cadastros/rascunhos/cadastro-rascunhos/cadastro-rascunhos.component';
import {RascunhosProvider} from '../../../../providers/nfe/rascunhos';
import {Router} from "@angular/router";
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {CadastroProdutoComponent} from "../cadastros/produtos/cadastro-produto/cadastro-produto.component";
import {SharedService} from "../../../../service/shared";

@Component({
  selector: "app-novanotafiscal",
  templateUrl: "./emitirNovaNotaFiscal.html",
  styleUrls: ["./emitirNovaNotaFiscal.scss"],
})
export class NovaNotaFiscalComponent implements OnInit {
  // @ViewChild("gridProdutos", { static: false }) grid;

  protected _onDestroy = new Subject<void>();

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourthFormGroup: FormGroup;
  public nfReferenciadaForm: FormGroup;
  public chavesNfeForm;
  public produtosDevolucaoForm;
  public devolucaoFormGroup: FormGroup;
  //public chavesCteForm;
  public chavesCupomForm;
  public chavesProdutorForm;
  public fifthFormGroup;
  public TotalIcms;
  public sixFormGroup: FormGroup;
  public complementarImpostosFormGroup: FormGroup;
  public tipoComplementar: FormControl;
  public tipoImposto: FormControl;
  public emissores = [];
  public seventhFormGroup;

  public contentArray = [];
  // tslint:disable-next-line: no-use-before-declare
  public emissorSelecionado = new Pessoa();
  // tslint:disable-next-line: no-use-before-declare
  public enderecoEmissorSelecionado = new Endereco();
  public json = new jsonNfe();
  public disableAddress = true;
  public idEnderecoEmissorSelecionado?: number;
  public idEnderecoDestinatarioSelecionado?: number;
  public indFinal = false;
  public indPres: number = 0;
  public enderecoRetirada = null;
  public enderecoRecebimento = null;
  public dataEntradaSaidaUTC = moment.utc(moment());
  public dataEmissaoUTC = moment.utc(moment());
  public dataEmissao: string;
  //public indFinal
  public enderecoFrete = null;
  public enderecoFreteClicado = false;

  public rascunhos: any = [];
  public search_rascunhos: FormControl = new FormControl("");
  public filter_rascunhos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public naturezas: Natureza[] = [];
  public search_naturezas: FormControl = new FormControl("");
  public filter_naturezas: ReplaySubject<Natureza[]> = new ReplaySubject<Natureza[]>(1);
  // tslint:disable-next-line: no-use-before-declare
  public naturezaSelecionada: Natureza = {
    id: -1,
    descricao: "",
    tipo: "",
    tipoNum: 1,
    finalidade: "",
    finalidade_id: "",
  };

  public destinatarios = [];
  public destinatarios2 = [];
  public search_destinatarios: FormControl = new FormControl("");
  public filter_destinatarios: ReplaySubject<Pessoa[]> = new ReplaySubject<Pessoa[]>(1);
  // tslint:disable-next-line: no-use-before-declare
  public destinatarioSelecionado = new Pessoa();
  // tslint:disable-next-line: no-use-before-declare
  public enderecoDestinatarioSelecionado = new Endereco();

  public products: Produto[] = [];
  public search_produtos: FormControl = new FormControl("");
  public filter_produtos: ReplaySubject<Produto[]> = new ReplaySubject<Produto[]>(1);

  public impostos : any = [];
  public search_impostos: FormControl = new FormControl("");
  public filter_impostos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public infosAdicionais: any = [];
  public search_infosAdicionais: FormControl = new FormControl("");
  public filter_infosAdicionais: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public retiradaDifCheckbox: boolean = false;
  public dataSourceMain: DataSourceMain[] = [];
  public editSettings: EditSettingsModel;
  public toolbar: string[];
  public valorTotal = [];
  public valorTotalDisplay = [];
  public ncmValidationRules: Object;
  public cstValidationRules: Object;
  public cfopValidationRules: Object;
  public qtdValidationRules: Object;
  public vlrUnValidationRules: Object;
  public vlrProdValidationRules: Object;
  public ipiValidationRules: Object;
  public icmsValidationRules: Object;
  public icmsStValidationRules: Object;
  public prodDetalhamento = [];
  public pagamentoFormGroup;
  public produtosToggle = [];
  public faturaForm;
  public returnedArray;
  public uf;
  public chaveReferenciada;
  public faturamentoFormGroup: FormGroup;
  public parcelasArray : FormArray;
  // public faturas: any[] = [];
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  public chaves = [];
  public receivingAddressAux: boolean = false;
  public deliveryAddressAux: boolean = false;
  public receivingAddress: any;
  public deliveryAddress: any;

  public frete: boolean = false;
  public freteInfoAux: boolean = false;
  public freteInfo: any;
  public reboque: boolean = false;
  public clientes = [];

  public enviando: boolean = false;

  public usingOldNfe: boolean = !!history.state.nfeId;

  public complementarType: any = "";
  public complementarTax: any = "";

  public ajusteForm: FormGroup;

  public percentageMask: any = {
    mask: Number,
    scale: 2,
    signed: false,
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: ',',
    mapToRadix: ['.'],
    min: 0.0,
    max: 100.00
  };

  public numberMask: any = {
    mask: Number,
    scale: 2,
    signed: false,
    thousandSeparator: ' ',
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: ',',
    mapToRadix: [''],
    min: 0.0,
    max: 99999999999.99 //11v2
  };
  faturamento: boolean = false;

  public nfe_json: any;
  enviandoRequisicao: boolean = false;
  produtosTributacao = [];
  public sendEmail = false;
  public draft: boolean = false; // quando cria um novo rascunho
  public editDraft: boolean = false; // quando edita um rascunho anterior
  public fromDraft: boolean = false; // quando cria uma nova nota fiscal a partir de um rascunho
  public draftObj: any;
  public sugestao;
  public sendEmailClient = false;

  public draftForm: FormGroup;
  // tslint:disable-next-line: variable-name
  public selectFatura: FormControl;

  public descricoesSelect = {
    emissor: false,
    natureza: false,
    cliente: false,
}

  public nfeAjusteFormGroup: FormGroup;

  constructor(
    public datepicker: DatePicker,
    private _formBuilder: FormBuilder,
    public router: Router,
    // tslint:disable-next-line: variable-name
    public productProvider: ProductProvider,
    public modalService: NgbModal,
    public userProvider: UserProvider,
    public countriesProvider: CountriesProvider,
    public opnatureProvider: OpnatureProvider,
    public statesProvider: StatesProvider,
    public citiesProvider: CitiesProvider,
    public nfeJsonProvider: nfeJsonProvider,
    private sharedService: SharedService,
    public impostoProvider: ImpostoProvider,
    public infosAdicionaisProvider: InfosAdicionaisProvider,
    public rascunhosProvider: RascunhosProvider,
    public snackBar: Snackbar,
    public receiverProvider: ReceiverProvider,
  ) {}
  // public produtos: Produto[] = [];

  ngOnInit() {
    //Recebe o valores do back end:
    try {
      this.enviandoRequisicao = true;
      moment.locale("pt-br");
      this.getDrafts().then((result) => {
        this.getEmitters().then((result) => {
          if (result) {
            this.getNatures().then((result) => {
              if(result){
                this.getDestinataries().then((result) =>{
                  if(result){
                    this.getProdutos().then((result) => {
                      if(result){
                        this.getImpostos().then((result) => {
                          if (result) {
                            this.getInfosAdicionais().then((result) => {
                              if (result) {
                                this.getDataSourceMain();
                                if (this.usingOldNfe) {
                                  this.receiverProvider.getNfe(history.state.userId, history.state.nfeId).then((result) => {
                                    if (result) {
                                      console.log("nfe base", result);
                                      this.nfe_json = result['nfeObj'];
                                      this.fillNfeForms();
                                    }
                                  })

                                } else {
                                  this.enviandoRequisicao = false;
                                  this.sharedService.currentData.subscribe(data => {
                                    if (data.draft) {
                                      this.draft = true;
                                    }
                                    if (data.editDraft) {
                                      this.editDraft = true;
                                      this.draftObj = {
                                        id: data.obj.id,
                                        description: data.obj.description,
                                        isShared: data.obj.isShared
                                      };
                                      this.setNFeDraft(this.draftObj.id);
                                    }
                                  });
                                  this.sharedService.clearData();
                                }
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                })
              }
            });
          }
        });
      });
      this.draftForm = new FormGroup({
        type: new FormControl(false),
        draft:  new FormControl(""),
      });
      this.setForms();
    } catch (exception) {
      console.error(exception);
    }
    // Recebe estados do back-end
    this.statesProvider.getAllStates().then((result : any) => {
      this.uf = result;
      this.uf.sort(function(a, b){
        if(a.name < b.name){
          return -1;
        } if (a.name > b.name){
          return 1;
        } return 0;
      });
    });
  }

  setForms(){
    this.descricoesSelect.emissor = false;
    this.descricoesSelect.natureza = false;
    this.descricoesSelect.cliente = false;
    //formulario do emissor
    this.firstFormGroup = this._formBuilder.group({
      address: ["", Validators.required],
      stateInscription: [{value: "", disabled: true}],
      tributaryRegime: [{value: "", disabled: true}],
      street: [{value: "", disabled: true}],
      number: [{value: "", disabled: true}],
      complement: [{value: "", disabled: true}],
      neighbourhood: [{value: "", disabled: true}],
      cep: [{value: "", disabled: true}],
      uf: [{value: "", disabled: true}],
      city: [{value: "", disabled: true}],
    });
    //formulario destinatario
    this.secondFormGroup = this._formBuilder.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      stateInscription: [{value: "", disabled: true}],
      cpfCnpj: [{value: "", disabled: true}],
      street: [{value: "", disabled: true}],
      number: [{value: "", disabled: true}],
      complement: [{value: "", disabled: true}],
      neighbourhood: [{value: "", disabled: true}],
      cep: [{value: "", disabled: true}],
      phone: [{value: "", disabled: true}],
      uf: [{value: "", disabled: true}],
      city: [{value: "", disabled: true}],
      country: [{value: "", disabled: true}],
    });
    //formulario natureza da operação
    this.thirdFormGroup = this._formBuilder.group({
      opNature: ["", Validators.required],
      opType: [{value: "", disabled: true}],
      opFinality: [{value: "", disabled: true}],
      nfeReferenciada: [{value: "", disabled: true}, Validators.required],
    });
    this.devolucaoFormGroup = this._formBuilder.group({
      chaveDevolucao: [{value: "", disabled: false}],
      devolucaoParcial: [{value: false, disabled: false}],
      volumesDevolucao: [{value: "", disabled: false}],
      cfopDevolucao: [{value: "", disabled: false}],
    });
    //informações gerais
    this.fourthFormGroup = this._formBuilder.group({
      series: [{value: "", disabled: true}],
      number: [{value: "", disabled: true}],
      gmt: [""],
      dtEntExt: [""],
      dtEmi: new FormControl(null),
      indFinal: [""],
      indPres: ["0", Validators.required],
      valorTotalDesconto: [""],
    });

    //STEP 5 - PRODUTOS
    this.fifthFormGroup = new FormArray([]);
    this.novoFormProd();

    this.TotalIcms = this._formBuilder.group({
      ValorBaseCalculo: ["", Validators.required],
      ValorIcms: ["", Validators.required],
      ValorIcmsDesonerado: ["", Validators.required],
      ValorFundoCombatePobrezaUfDestino: ["", Validators.required],
      ValorIcmsUfDestino: ["", Validators.required],
      ValorIcmsUfRemetente: ["", Validators.required],
      ValorFundoCombatePobreza: ["", Validators.required],
      ValorBaseCalculoSt: ["", Validators.required],
      ValorIcmsSt: ["", Validators.required],
      ValorFundoCombatePobrezaSt: ["", Validators.required],
      ValorFundoCombatePobrezaStRetido: ["", Validators.required],
      ValorProdutosServicos: ["", Validators.required],
      ValorFrete: ["", Validators.required],
      ValorSeguro: ["", Validators.required],
      ValorDesconto: ["", Validators.required],
      ValorImpostoImportacao: ["", Validators.required],
      ValorIpi: ["", Validators.required],
      ValorIpiDevolvido: ["", Validators.required],
      ValorPis: ["", Validators.required],
      ValorCofins: ["", Validators.required],
      ValorOutrasDespesas: ["", Validators.required],
      ValorNotaFiscal: ["", Validators.required]
    });
    this.sixFormGroup = this._formBuilder.group({
      InfoCompl: [""],
      InfoAoFisco: [""]
    });
    //Frete
    this.seventhFormGroup = this._formBuilder.group({
      modalidadeFrete: ["9", Validators.required],
      formaEnvio: [{value: ""}, Validators.required],
      totalFrete: [{value: "", disabled: false},],
      qtdVolumes: [{value: "", disabled: false}],
      pesoBruto: [{value: "", disabled: false}],
      pesoLiquido: [{value: "", disabled: false}],
      especie: [{value: "", disabled: false}],
      marca: [{value: "", disabled: false}],
      numeracao: [{value: "", disabled: false}],
      numeroLacres: [{value: "", disabled: false}],
      endereco: [{value: "", disabled: true},],
      placa: [{value: "", disabled: true}],
      uf: [{value: "", disabled: true}],
    });
    //Pagamento
    this.pagamentoFormGroup = new FormArray([
      new FormGroup({
        IndicadorFormaPagamento: new FormControl(""),
        MeioPagamento: new FormControl("", Validators.required),
        ValorPagamento: new FormControl(null, Validators.required),
        desc_pagamento: new FormControl(null),
      }),
    ]);

    this.nfReferenciadaForm = new FormGroup({
      referenciada: new FormControl(0),
    });
    this.chavesNfeForm = new FormArray([
      new FormGroup({
        chave: new FormControl(""),
      })
    ]);
    this.produtosDevolucaoForm = new FormArray([
      new FormGroup({
        produto: new FormControl("", Validators.required),
        quantidade: new FormControl("", Validators.required)
      })
    ]);

    this.chavesCupomForm = new FormArray([
      new FormGroup({
        modelo: new FormControl(""),
        ecf: new FormControl(""),
        coo: new FormControl(""),
      })
    ]);
    this.chavesProdutorForm = new FormArray([
      new FormGroup({
        estado: new FormControl(""),
        data: new FormControl(""),
        modelo: new FormControl(""),
        cpfCnpj: new FormControl(""),
        ie: new FormControl(""),
        serie: new FormControl(""),
        numero: new FormControl(""),
      })
    ]);
    this.complementarImpostosFormGroup = new FormGroup({
      codigo_cfop: new FormControl(""),
      situacao_tributaria: new FormControl(""),
      bc: new FormControl(""),
      valor: new FormControl(""),
      beneficio_fiscal: new FormControl(""),
      aliquota_mva: new FormControl(""),
      exigibilidade: new FormControl(""),
      item_servico: new FormControl(""),
      incentivo_fiscal: new FormControl(""),
    });
    //Formulario de faturas
    this.selectFatura = new FormControl(0);
    this.faturamentoFormGroup = new FormGroup({
      nFat: new FormControl(""),
      vOrig: new FormControl(""),
      vDesc: new FormControl(""),
      vLiq: new FormControl(""),
    });

    this.tipoComplementar = new FormControl("");
    this.tipoImposto = new FormControl("");

    this.parcelasArray = new FormArray([
      new FormGroup({
        dVenc: new FormControl(""),
        vDup: new FormControl(""),
      })
    ]);

    this.ajusteForm = new FormGroup({
      valor_icms: new FormControl(null),
      valor_icms_st: new FormControl(null),
      situacao_tributaria: new FormControl(null),
      codigo_cfop: new FormControl(''),
    });

    //Seta o horario do informações gerais
    this.setDataEmissao();
  }

  async initializeSearchDraft() {
    this.rascunhos.sort((a, b) => a.description.localeCompare(b.description));
    await this.filter_rascunhos.next(this.rascunhos.slice());
    this.search_rascunhos.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRascunhos();
      });
  }

  async initializeSearchNature() {
    this.naturezas.sort((a, b) => a.descricao.localeCompare(b.descricao));
    await this.filter_naturezas.next(this.naturezas.slice());
    this.search_naturezas.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterNaturezas();
      });
  }

  async initializeSearchDestinatary() {
    this.destinatarios.sort((a, b) => a.nome.localeCompare(b.nome));
    await this.filter_destinatarios.next(this.destinatarios.slice());
    this.search_destinatarios.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDestinataries();
      });
  }

  async initializeSearchProducts() {
    this.products.sort((a, b) => a.description.localeCompare(b.description));
    await this.filter_produtos.next(this.products.slice());
    this.search_produtos.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
      });
  }

  async initializeSearchTaxes() {
    this.impostos.sort((a, b) => a.description.localeCompare(b.description));
    await this.filter_impostos.next(this.impostos.slice());
    this.search_impostos.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTaxes();
      });
  }

  async initializeSearchInfoAdicionals() {
    this.infosAdicionais.sort((a, b) => a.description.localeCompare(b.description));
    await this.filter_infosAdicionais.next(this.infosAdicionais.slice());
    this.search_infosAdicionais.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterInfosAdicionais();
      });
  }

  protected filterRascunhos() {
    if (!this.rascunhos) return;
    let search = this.search_rascunhos.value;
    if (!search) {
      this.filter_rascunhos.next(this.rascunhos.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_rascunhos.next(
      this.rascunhos.filter((rascunho) => rascunho.description.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.description.toLowerCase().indexOf(search) - b.description.toLowerCase().indexOf(search))
    );
  }

  protected filterNaturezas() {
    if (!this.naturezas) return;
    let search = this.search_naturezas.value;
    if (!search) {
      this.filter_naturezas.next(this.naturezas.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_naturezas.next(
      this.naturezas.filter((natureza) => natureza.descricao.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.descricao.toLowerCase().indexOf(search) - b.descricao.toLowerCase().indexOf(search))
    );
  }

  protected filterDestinataries() {
    if (!this.destinatarios) return;
    let search = this.search_destinatarios.value;
    if (!search) {
      this.filter_destinatarios.next(this.destinatarios.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_destinatarios.next(
      this.destinatarios.filter((destinatario) => destinatario.nome.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.nome.toLowerCase().indexOf(search) - b.nome.toLowerCase().indexOf(search))
    );
  }

  protected filterProducts() {
    if (!this.products) return;
    let search = this.search_produtos.value;
    if (!search) {
      this.filter_produtos.next(this.products.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_produtos.next(
      this.products.filter((product) => product.description.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.description.toLowerCase().indexOf(search) - b.description.toLowerCase().indexOf(search))
    );
  }

  protected filterTaxes() {
    if (!this.impostos) return;
    let search = this.search_impostos.value;
    if (!search) {
      this.filter_impostos.next(this.impostos.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_impostos.next(
      this.impostos.filter((imposto) => imposto.description.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.description.toLowerCase().indexOf(search) - b.description.toLowerCase().indexOf(search))
    );
  }

  protected filterInfosAdicionais() {
    if (!this.infosAdicionais) return;
    let search = this.search_infosAdicionais.value;
    if (!search) {
      this.filter_infosAdicionais.next(this.infosAdicionais.slice());
      return;
    }
    search = search.toLowerCase();
    this.filter_infosAdicionais.next(
      this.infosAdicionais.filter((infoAdicional) => infoAdicional.description.toLowerCase().indexOf(search) > -1)
        .sort((a, b) => a.description.toLowerCase().indexOf(search) - b.description.toLowerCase().indexOf(search))
    );
  }

  changeSendEmail(value){
    this.sendEmail = value.checked;
  }

  changeSendEmailClient(event){
    this.sendEmailClient = event.checked;
    if (this.sendEmailClient == true && (this.destinatarioSelecionado.email == "" || this.destinatarioSelecionado.email == null)) {
      Swal.fire({
        title: 'Deseja cadastrar um email?',
        text: "Este cliente não possui e-mail cadastrado!",
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Cadastrar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.editCliente(this.destinatarioSelecionado, true);
        } else {
          this.sendEmailClient = false;
          // event.source.checked = false;
        }
      });
    }
  }

  fillNfeForms() {
    Promise.all([
      this.fillNfeFormsEmitter(),
      this.fillNfeFormsNature(),
      this.fillNfeFormsDestinatary(),
      this.fillNfeFormsGeneralInfo(),
      this.fillNfeFormsProduct(),
      this.fillNfeFormsTransport(),
      this.fillNfeFormsFormOfPayment(),
      this.fillNfeFormsBill(),
      this.fillNfeFormsReferenced(),
      this.fillNfeFormsFinalDetails()
    ]).then((result) => {
      if (result) {
        console.log("ALL FORMS ARE ok");
        this.enviandoRequisicao = false;
      }
    }).catch((error) => {
      this.enviandoRequisicao = false;
      console.log(error);
    });
  }

  fillNfeFormsEmitter(){
    return new Promise((resolve, reject) => {
      console.log(this.nfe_json);
      this.descricoesSelect.emissor = false;
      this.firstFormGroup.get('address').setValue("");
      if (this.nfe_json.emissor && this.nfe_json.emissor.id) {
        this.descricoesSelect.emissor = true;
        this.firstFormGroup.get('address').setValue(this.nfe_json.emissor.id);
        this.selectEmitterAddress(this.nfe_json.emissor.id);
      }
      console.log("emitter ok");
      resolve(true);
    });
  }

  fillNfeFormsNature(){
    return new Promise((resolve, reject) => {
      this.descricoesSelect.natureza = false;
      if (this.nfe_json.natureza_operacao) {
        let natureza = this.naturezas.find((element) => element.descricao == this.nfe_json.natureza_operacao);
        if (natureza) {
          this.thirdFormGroup.get('opNature').setValue(natureza.id);
          this.selectNature(natureza.id);
        }
      }
      console.log("nature ok");
      resolve(true);
    });
  }

  fillNfeFormsDestinatary() {
    return new Promise((resolve, reject) => {
      this.descricoesSelect.cliente = false;
      if (!this.nfe_json.cliente.hasOwnProperty('cnpj')) resolve(true);
      const foundDestinatary = this.destinatarios.find((destinatario) => Array.isArray(destinatario.enderecos) && destinatario.enderecos.some((endereco) => endereco.inscricaoEstadual === this.nfe_json.cliente.ie));
      if (foundDestinatary) {
        this.secondFormGroup.get('name').setValue(foundDestinatary.arrayKey);
        this.selectDestinatary(foundDestinatary.arrayKey).then((result) => {
          if (result) {
            console.log("destinatary ok");
            resolve(true);
          }
        });
      } else {
        const foundDestinatary2 = this.destinatarios.find((destinatario) => destinatario.cpfCnpj === this.nfe_json.cliente.cnpj || destinatario.cpfCnpj === this.nfe_json.cliente.cpf);
        if (foundDestinatary2) {
          this.secondFormGroup.get('name').setValue(foundDestinatary2.arrayKey);
          this.selectDestinatary(foundDestinatary2.arrayKey).then((result) => {
            if (result) {
              console.log("destinatary ok")
              resolve(true);
            }
          });
        } else if (!this.editDraft && !this.fromDraft) {
          this.snackBar.openLong("Destinatário não encontrado", "erro");
          resolve(true);
        }
      }
    });
  }

  fillNfeFormsComplementary() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.impostos) {
        let imposto = this.nfe_json.impostos;
        this.complementarImpostosFormGroup.patchValue({
          codigo_cfop: imposto.codigo_cfop,
          situacao_tributaria: imposto.situacao_tributaria,
          bc: imposto.bc_icms,
          valor: imposto.valor_icms,
          beneficio_fiscal: imposto.beneficio_fiscal,
          aliquota_mva: imposto.aliquota_mva,
          exigibilidade: imposto.exigibilidade,
          item_servico: imposto.item_servico,
          incentivo_fiscal: imposto.incentivo_fiscal,
        });
        console.log("complementary ok");
      }
    });
  }

  fillNfeFormsAdjust(){
    return new Promise((resolve, reject) => {
      this.ajusteForm.patchValue({
        ...(this.nfe_json.codigo_cfop ? {codigo_cfop: this.nfe_json.codigo_cfop} : {}),
        ...(this.nfe_json.situacao_tributaria ? {situacao_tributaria: this.nfe_json.situacao_tributaria} : {}),
        ...(this.nfe_json.valor_icms ? {valor_icms: this.nfe_json.valor_icms} : {}),
        ...(this.nfe_json.valor_icms_st ? {valor_icms_st: this.nfe_json.valor_icms} : {}),
      });
      console.log("adjust ok");
      resolve(true);
    });
  }

  fillNfeFormsGeneralInfo() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.pedido) {
       this.setDataEmissao();
        this.fourthFormGroup.get('indPres').setValue(this.nfe_json.pedido.presenca);
        this.fourthFormGroup.get('indFinal').setValue(this.nfe_json.cliente.consumidor_final);
        if (this.nfe_json.data_entrada_saida) {
          this.fourthFormGroup.get('dtEntExt').setValue(this.nfe_json.data_entrada_saida);
        }
        if (this.nfe_json.pedido.desconto) {
          this.fourthFormGroup.get('valorTotalDesconto').setValue(this.nfe_json.pedido.desconto);
        }
      }
      console.log("general info ok");
      resolve(true);
    });
  }

  fillNfeFormsProduct(){
    return new Promise ((resolve, reject) => {
      if (this.nfe_json.produtos) {
        while (this.fifthFormGroup.controls.length != this.nfe_json.produtos.length) {
          this.novoFormProd();
        }

        let i = 0;
        this.nfe_json.produtos.forEach((nfe_json_product) => {
          // if (this.naturezaSelecionada.finalidade != "NF-e complementar") {
          this.setProductTaxes(nfe_json_product, i);
          // }
          if (nfe_json_product.nome) {
            this.products.forEach((product) => {
              if (nfe_json_product.nome.toLowerCase() == product.description.toLowerCase()) {
                this.selectProduct(product, i);
                this.fifthFormGroup.controls[i].get("unCommerceValue").setValue(nfe_json_product.subtotal);
                this.fifthFormGroup.controls[i].get("totalProdValue").setValue(nfe_json_product.total);
                this.fifthFormGroup.controls[i].get("comercialQt").setValue(nfe_json_product.quantidade);
                this.fifthFormGroup.controls[i].get("gtin").setValue(nfe_json_product.gtin);
                this.fifthFormGroup.controls[i].get("ncm").setValue(nfe_json_product.ncm);
                if (nfe_json_product.quantidade_tributavel || nfe_json_product.unidade_tributavel) {
                  this.fifthFormGroup.controls[i].get("slideTributario").setValue(true);
                  this.changeTributacao(true, i);
                  this.fifthFormGroup.controls[i].get("tributacaoQt").setValue(nfe_json_product.quantidade_tributavel);
                  this.fifthFormGroup.controls[i].get("tributacaoUn").setValue(nfe_json_product.unidade_tributavel);
                  this.fifthFormGroup.controls[i].get("unTribValue").setValue(nfe_json_product.subtotal_tributavel);
                  this.fifthFormGroup.controls[i].get("gtin_tributavel").setValue(nfe_json_product.gtin);
                }

              }
            });
          }
          i++;
        });
        console.log("product ok");
        resolve(true);
      }
    });
  }

  fillNfeFormsTransport() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.pedido) {
        this.selectFrete(this.nfe_json.pedido.modalidade_frete).then((result) => {
          if (result) {
            this.seventhFormGroup.get('modalidadeFrete').setValue(this.nfe_json.pedido.modalidade_frete.toString());
            if (this.nfe_json.pedido.modalidade_frete.toString() != "9") {
              this.nfe_json.pedido.frete ? this.seventhFormGroup.get('totalFrete').setValue(this.nfe_json.pedido.frete) : this.seventhFormGroup.get('totalFrete').setValue("");
              this.nfe_json.transporte.volume ? this.seventhFormGroup.get('qtdVolumes').setValue(this.nfe_json.transporte.volume) : this.seventhFormGroup.get('qtdVolumes').setValue("");
              this.nfe_json.transporte.peso_bruto ? this.seventhFormGroup.get('pesoBruto').setValue(this.nfe_json.transporte.peso_bruto) : this.seventhFormGroup.get('pesoBruto').setValue("");
              this.nfe_json.transporte.peso_liquido ? this.seventhFormGroup.get('pesoLiquido').setValue(this.nfe_json.transporte.peso_liquido) : this.seventhFormGroup.get('pesoLiquido').setValue("");
              this.nfe_json.transporte.especie ? this.seventhFormGroup.get('especie').setValue(this.nfe_json.transporte.especie) : this.seventhFormGroup.get('especie').setValue("");
              this.nfe_json.transporte.marca ? this.seventhFormGroup.get('marca').setValue(this.nfe_json.transporte.marca) : this.seventhFormGroup.get('marca').setValue("");
              this.nfe_json.transporte.numeracao ? this.seventhFormGroup.get('numeracao').setValue(this.nfe_json.transporte.numeracao) : this.seventhFormGroup.get('numeracao').setValue("");
              this.nfe_json.transporte.lacres ? this.seventhFormGroup.get('numeroLacres').setValue(this.nfe_json.transporte.lacres) : this.seventhFormGroup.get('numeroLacres').setValue("");
              this.nfe_json.transporte.endereco ? this.seventhFormGroup.get('endereco').setValue(this.nfe_json.transporte.endereco) : this.seventhFormGroup.get('endereco').setValue("");

              if (this.nfe_json.transporte.retirada) {
                this.setReceivingAddress().then((result) => {
                  if (result && this.receivingAddress.cpfCNPJ != null) {
                    this.receivingAddressAux = true;
                  }
                });
              }
              if (this.nfe_json.transporte.entrega) {
                this.setDeliveryAddress().then((result) => {
                  if (result && this.deliveryAddress.cpfCNPJ != null) {
                    this.deliveryAddressAux = true;
                  }
                });
              }

              if (this.nfe_json.transporte.razao_social || this.nfe_json.transporte.nome_completo) {
                this.setFormaEnvio().then((result) => {
                  if (result) {
                    this.setTransportadora().then((result) => {
                      if (result) {
                        if (this.nfe_json.transporte.cpf) {
                          this.freteInfo.cpfCNPJ = this.nfe_json.transporte.cpf;
                        }
                        if (this.nfe_json.transporte.cnpj) {
                          this.freteInfo.cpfCNPJ = this.nfe_json.transporte.cnpj;
                        }
                        this.freteInfoAux = true;
                      }
                    });
                  }
                });
              }
            }
          }
        });
      }
      console.log("transport ok");
      resolve(true);
    });
  }

  fillNfeFormsFormOfPayment() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.pedido) {
        while (this.pagamentoFormGroup.controls.length < this.nfe_json.pedido.forma_pagamento.length) {
          this.novoPagamento();
        }
        let i = 0;
        this.pagamentoFormGroup.controls.forEach((element) => {
          element.get('IndicadorFormaPagamento').setValue(this.nfe_json.pedido.pagamento[i]);
          element.get('MeioPagamento').setValue(this.nfe_json.pedido.forma_pagamento[i]);
          element.get('ValorPagamento').setValue(this.nfe_json.pedido.valor_pagamento[i]);
          i++;
        });
      }
      console.log("transport ok");
      resolve(true);
    });
  }

  fillNfeFormsBill(){
    return new Promise((resolve, reject) => {
      if (this.nfe_json.fatura && this.nfe_json.fatura.numero) {
        this.selectFaturamento("1").then((result) => {
          if (result) {
            this.selectFatura.setValue(1);
            this.faturamentoFormGroup.patchValue({
              nFat: this.nfe_json.fatura.numero,
              vOrig: this.nfe_json.fatura.valor,
              vDesc: this.nfe_json.fatura.desconto,
              vLiq: this.nfe_json.fatura.valor_liquido,
            });
          }
        });
        if (this.nfe_json.parcelas.length) {
          while (this.parcelasArray.controls.length != this.nfe_json.parcelas.length) {
            this.novaFatura();
          }
          let i = 0;
          this.nfe_json.parcelas.forEach((element) => {
            if (element.vencimento != "" && element.valor != "" ){
              this.parcelasArray.controls[i].patchValue({
                dVenc: element.vencimento,
                vDup: element.valor,
              });
            }
            i++;
          });
        }
      }
      console.log("bill ok");
      resolve(true);
    });
  }

  fillNfeFormsReferenced(){
    return new Promise((resolve, reject) => {
      if (this.naturezaSelecionada.finalidade == "NF-e complementar") {
        this.removeAllReferenciadas();
        this.chaveReferenciada = this.nfe_json.nfe_referenciada;
        console.log("referenced ok");
        resolve(true);
      } else if (this.nfe_json.nfe_referenciada) {
        this.selectReferenciada("1").then((result) => {
          if(result){
            this.setNfeReferenciada().then((result) => {
              if(result){
                console.log("referenced ok");
                resolve(true);
              }
            });
          }
        });
      }
      else if(this.nfe_json.cte_referenciada){
        console.log("cte referenciada", this.nfe_json.cte_referenciada);
        this.selectReferenciada("2").then((result) => {
          if(result){
            this.setCteReferenciada().then((result) => {
              if(result){
                console.log("referenced ok");
                resolve(true);
              }
            });
          }
        });
      }
      else if(this.nfe_json.ecf_referenciada){
        this.selectReferenciada("3").then((result) => {
          if(result){
            this.setEcfReferenciada().then((result) => {
              if(result){
                console.log("referenced ok");
                resolve(true);
              }
            });
          }
        });
      } else if (this.nfe_json.nf_rural_referenciada) {
        this.selectReferenciada("4").then((result) => {
          if(result){
            this.setNfRuralReferenciada().then((result) => {
              if(result){
                console.log("referenced ok");
                resolve(true);
              }
            });
          }
        });
      } else {
        console.log("referenced ok");
        resolve(true);
      }
    });
  }

  fillNfeFormsFinalDetails() {
    return new Promise((resolve, reject) => {
      this.setInfoCompl().then((result) => {
        if (result) {
          this.setInfoFisco().then((result) => {
            if (result) {
              this.setSendEmail().then((result) => {
                if (result) {
                  console.log("final details ok");
                  resolve(true);
                }
              });
            }
          });
        }
      });
    });
  }

  setReceivingAddress(){
    return new Promise ((resolve, reject) => {
      let receivingAddress = {
        properties: [
          {
            stateAbr: this.nfe_json.transporte.retirada.uf,
            cep: this.nfe_json.transporte.retirada.cep,
            street: this.nfe_json.transporte.retirada.endereco,
            houseNumber: this.nfe_json.transporte.retirada.numero,
            neighborhood: this.nfe_json.transporte.retirada.bairro,
            cityName: this.nfe_json.transporte.retirada.cidade,
            telephone: this.nfe_json.transporte.retirada.telefone,
            complement: this.nfe_json.transporte.retirada.complemento,
          }
        ],
        email: this.nfe_json.transporte.retirada.email,
        substituto_tributario: this.nfe_json.transporte.retirada.taxSubstitute,
        cpfCNPJ: this.nfe_json.transporte.retirada.cnpj ? this.nfe_json.transporte.retirada.cnpj : this.nfe_json.transporte.retirada.cpf,
        name: this.nfe_json.transporte.retirada.razao_social ? this.nfe_json.transporte.retirada.razao_social : this.nfe_json.transporte.retirada.nome_completo,
        ie: this.nfe_json.transporte.retirada.ie ? this.nfe_json.transporte.retirada.ie : "",
        ieType: "",
      };

      if(this.nfe_json.transporte.retirada.ieType == 1){
        receivingAddress.ieType = 'ICMS_TAXPAYER'
      }
      else if (this.nfe_json.transporte.retirada.ieType == 2){
        receivingAddress.ieType = 'EXEMPTED_TAXPAYER'
      }

      this.receivingAddress = receivingAddress;
      resolve(true);
    });
  }

  setDeliveryAddress(){
    return new Promise ((resolve, reject) => {
      let deliveryAddress = {
        properties: [
          {
            stateAbr: this.nfe_json.transporte.entrega.uf,
            cep: this.nfe_json.transporte.entrega.cep,
            street: this.nfe_json.transporte.entrega.endereco,
            houseNumber: this.nfe_json.transporte.entrega.numero,
            neighborhood: this.nfe_json.transporte.entrega.bairro,
            cityName: this.nfe_json.transporte.entrega.cidade,
            telephone: this.nfe_json.transporte.entrega.telefone,
            complement: this.nfe_json.transporte.entrega.complemento,
          }
        ],
        email: this.nfe_json.transporte.entrega.email,
        substituto_tributario: this.nfe_json.transporte.entrega.taxSubstitute,
        cpfCNPJ: this.nfe_json.transporte.entrega.cnpj ? this.nfe_json.transporte.entrega.cnpj : this.nfe_json.transporte.entrega.cpf,
        name: this.nfe_json.transporte.entrega.razao_social ? this.nfe_json.transporte.entrega.razao_social : this.nfe_json.transporte.entrega.nome_completo,
        ie: this.nfe_json.transporte.entrega.ie ? this.nfe_json.transporte.entrega.ie : "",
        ieType: "",
      };

      if (this.nfe_json.transporte.entrega.ieType == 1) {
        deliveryAddress.ieType = "ICMS_TAXPAYER";
      } else if (this.nfe_json.transporte.entrega.ieType == 2) {
        deliveryAddress.ieType = "EXEMPTED_TAXPAYER";
      }

      this.deliveryAddress = deliveryAddress;
      resolve(true);
    });
  }

  setTransportadora() {
    return new Promise((resolve, reject) => {
      let freteInfo = {
        name: this.nfe_json.transporte.razao_social ? this.nfe_json.transporte.razao_social : this.nfe_json.transporte.nome_completo,
        cpfCNPJ: this.nfe_json.transporte.cnpj ? this.nfe_json.transporte.cnpj : this.nfe_json.transporte.cpf,
        ie: this.nfe_json.transporte.ie ? this.nfe_json.transporte.ie : "",
        properties: [
          {
            cityName: this.nfe_json.transporte.cidade,
            cep: this.nfe_json.transporte.cep,
            stateAbr: this.nfe_json.transporte.uf,
            street: this.nfe_json.transporte.endereco,
          }
        ],
        frete: [{
          placaVeiculo: this.nfe_json.transporte.placa ? this.nfe_json.transporte.placa : "",
          registroNacional: this.nfe_json.transporte.rntc ? this.nfe_json.transporte.rntc : "",
          valorSeguro: this.nfe_json.transporte.seguro ? this.nfe_json.transporte.seguro : "",
          ufVeiculoAbr: this.nfe_json.transporte.uf_veiculo ? this.nfe_json.transporte.uf_veiculo : "",
          reboque: []
        }]
      };
      if (this.nfe_json.transporte.reboque) {
        this.reboque = true;
        this.nfe_json.transporte.reboque.forEach((element) => {
          freteInfo.frete[0].reboque.push({
            placaVeiculo: element.placa ? element.placa : "",
            registroNacional: element.rntc ? element.rntc : "",
            identificacaoVagao: element.vagao ? element.vagao : "",
            identificacaoBalsa: element.balsa ? element.balsa : "",
            uf_veiculoAbr: element.uf_veiculo ? element.uf_veiculo : "",
          })
        });
      }
      this.freteInfo = freteInfo;
      resolve(true);
    });
  }

  setFormaEnvio(){
    return new Promise ((resolve, reject) => {
      this.seventhFormGroup.get('formaEnvio').setValue("1");
      resolve(true);
    });
  }

  setNfeReferenciada(){
    return new Promise ((resolve, reject) => {
      if (typeof this.nfe_json.nfe_referenciada != "string"){
        while(this.chavesNfeForm.controls.length != this.nfe_json.nfe_referenciada.length){
          this.addReferenciada();
        }

        let i = 0;
        this.chavesNfeForm.controls.forEach((element) => {
          element.get('chave').setValue(this.nfe_json.nfe_referenciada[i]);
          i++;
        });
      } else {
        this.removeAllReferenciadas();
        this.chaveReferenciada = this.nfe_json.nfe_referenciada;
      }
      resolve(true);
    });
  }

  setCteReferenciada(){
    return new Promise ((resolve, reject) => {
      while(this.chavesNfeForm.controls.length < this.nfe_json.cte_referenciada.length){
        this.addReferenciada();
      }
      let i = 0;
      this.chavesNfeForm.controls.forEach((element) => {
        element.get('chave').setValue(this.nfe_json.cte_referenciada[i]);
        i++;
      });
      resolve(true);
    });
  }

  setEcfReferenciada(){
    return new Promise ((resolve, reject) => {
      while(this.chavesCupomForm.controls.length < this.nfe_json.ecf_referenciada.length){
        this.addReferenciada();
      }
      let i = 0;
      this.chavesCupomForm.controls.forEach((element) => {
        element.get('modelo').setValue(this.nfe_json.ecf_referenciada[i].modelo);
        element.get('ecf').setValue(this.nfe_json.ecf_referenciada[i].n_ecf);
        element.get('coo').setValue(this.nfe_json.ecf_referenciada[i].n_coo);
        i++;
      });
      resolve(true);
    });
  }

  setNfRuralReferenciada() {
    return new Promise((resolve, reject) => {
      while (this.chavesProdutorForm.controls.length < this.nfe_json.nf_rural_referenciada.length) {
        this.addReferenciada();
      }
      let i = 0;
      this.chavesProdutorForm.controls.forEach((element) => {
        element.get('estado').setValue(this.nfe_json.nf_rural_referenciada[i].uf);
        element.get('data').setValue(this.nfe_json.nf_rural_referenciada[i].data);
        if (this.nfe_json.nf_rural_referenciada[i].cpf) {
          element.get('cpfCnpj').setValue(this.nfe_json.nf_rural_referenciada[i].cpf);
        } else if (this.nfe_json.nf_rural_referenciada[i].cnpj) {
          element.get('cpfCnpj').setValue(this.nfe_json.nf_rural_referenciada[i].cnpj);
        }
        element.get('ie').setValue(this.nfe_json.nf_rural_referenciada[i].ie);
        element.get('modelo').setValue(this.nfe_json.nf_rural_referenciada[i].modelo);
        element.get('serie').setValue(this.nfe_json.nf_rural_referenciada[i].serie);
        element.get('numero').setValue(this.nfe_json.nf_rural_referenciada[i].numero);
        i++;
      });
      resolve(true);
    });
  }

  setInfoCompl() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.informacoes_complementares) {
        this.sixFormGroup.get("InfoCompl").setValue(this.nfe_json.informacoes_complementares);
      }
      else if (this.nfe_json.pedido && this.nfe_json.pedido.informacoes_complementares) {
        this.sixFormGroup.get("InfoCompl").setValue(this.nfe_json.pedido.informacoes_complementares);
      } else {
        this.sixFormGroup.get("InfoCompl").setValue("");
      }
      resolve(true);
    });
  }

  setInfoFisco() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.informacoes_fisco) {
        this.sixFormGroup.get("InfoAoFisco").setValue(this.nfe_json.informacoes_fisco);
      }
      else if (this.nfe_json.pedido && this.nfe_json.pedido.informacoes_fisco) {
        this.sixFormGroup.get("InfoAoFisco").setValue(this.nfe_json.pedido.informacoes_fisco);
      } else {
        this.sixFormGroup.get("InfoAoFisco").setValue("");
      }
      resolve(true);
    });
  }

  setSendEmail() {
    return new Promise((resolve, reject) => {
      if (this.nfe_json.emissor.sendEmail) {
        this.sendEmail = this.nfe_json.emissor.sendEmail;
      }
      resolve(true);
    });
  }

  setProductTaxes(nfe_json_product, i){
    return new Promise ((resolve, reject) =>{
      this.calcValorTotal(i);
      if (nfe_json_product.impostos.icms && nfe_json_product.impostos.pis && nfe_json_product.impostos.cofins && nfe_json_product.impostos.ipi) {
        this.setProductTaxesIcms(nfe_json_product, i).then((result) => {
          if (result) {
            this.setProductTaxesCofins(nfe_json_product, i).then((result) => {
              if (result) {
                this.setProductTaxesPis(nfe_json_product, i).then((result) => {
                  if(result){
                    this.setProductTaxesIpi(nfe_json_product, i).then((result) => {
                      if (result) {
                        resolve(true);
                      }
                    })
                  }
                });
              }
            });
          }
        });
      } else if (nfe_json_product.impostos.icms  && !nfe_json_product.impostos.pis && !nfe_json_product.impostos.cofins){ // quando for complementar de preco ou quantidade
        this.fifthFormGroup.controls[i].get('cfop').setValue(nfe_json_product.impostos.icms.codigo_cfop);
        this.fifthFormGroup.controls[i].get('SituacaoTributaria').setValue(nfe_json_product.impostos.icms.situacao_tributaria);
        if (nfe_json_product.impostos.icms.situacao_tributaria == '20' || nfe_json_product.impostos.icms.situacao_tributaria == '40'
        || nfe_json_product.impostos.icms.situacao_tributaria == '41' || nfe_json_product.impostos.icms.situacao_tributaria == '50' || nfe_json_product.impostos.icms.situacao_tributaria == '51') {
          this.fifthFormGroup.controls[i].get('BeneficioFiscal').enable();
          this.fifthFormGroup.controls[i].get('BeneficioFiscal').clearValidators();
          this.fifthFormGroup.controls[i].get('BeneficioFiscal').setValue(nfe_json_product.beneficio_fiscal);
        } else {
          this.fifthFormGroup.controls[i].get('BeneficioFiscal').disable();
        }

        this.fifthFormGroup.controls[i].get('Aliquota').disable();
        this.fifthFormGroup.controls[i].get('AliquotaDiferimento').disable();
        this.fifthFormGroup.controls[i].get('AliquotaDiferimentoFCP').disable();
        this.fifthFormGroup.controls[i].get('AliquotaReducao').disable();
        this.fifthFormGroup.controls[i].get('SituacaoTributariaCofins').disable();
        this.fifthFormGroup.controls[i].get('cofAliquotaPercentual').disable();
        this.fifthFormGroup.controls[i].get('SituacaoTributariaPis').disable();
        this.fifthFormGroup.controls[i].get('AliquotaPercentual').disable();
        this.fifthFormGroup.controls[i].get('bc_st_retido').disable();
        this.fifthFormGroup.controls[i].get('aliquota_st_retido').disable();
        this.fifthFormGroup.controls[i].get('valor_st_retido').disable();
        this.fifthFormGroup.controls[i].get('bc_st_dest').disable();
        this.fifthFormGroup.controls[i].get('valor_st_dest').disable();
        this.fifthFormGroup.controls[i].get('valor_fcp_retido').disable();
        this.fifthFormGroup.controls[i].get('aliquota_fcp_retido').disable();
        this.fifthFormGroup.controls[i].get('aliquota_bc_efetivo').disable();
        this.fifthFormGroup.controls[i].get('aliquota_icms_efetivo').disable();
        this.fifthFormGroup.controls[i].get('aliquota_mva').disable();
        this.fifthFormGroup.controls[i].get('industria').disable();
        this.fifthFormGroup.controls[i].get('aliquota_st').disable();
        this.fifthFormGroup.controls[i].get('aliquota_fcp_st').disable();
        this.fifthFormGroup.controls[i].get('aliquota_credito').disable();
        this.fifthFormGroup.controls[i].get('valor_icms_subtituto').disable();
        this.fifthFormGroup.controls[i].get('motivo_desoneracao_st').disable();

      }
    });
  }

  setProductTaxesIpi(nfe_json_product, i){
    console.log("aaa", nfe_json_product)
    return new Promise ((resolve, reject) =>{
      this.fifthFormGroup.controls[i].get('SituacaoTributariaIpi').setValue(nfe_json_product.impostos.ipi.situacao_tributaria);
      this.fifthFormGroup.controls[i].get('codigo_enquadramento').setValue(nfe_json_product.impostos.ipi.codigo_enquadramento);
      this.fifthFormGroup.controls[i].get('AliquotaIpi').setValue(nfe_json_product.impostos.ipi.aliquota);
      resolve(true);
    });
  }

  setProductTaxesPis(nfe_json_product, i){
    return new Promise ((resolve, reject) =>{
      this.fifthFormGroup.controls[i].get('SituacaoTributariaPis').setValue(nfe_json_product.impostos.pis.situacao_tributaria);
      this.fifthFormGroup.controls[i].get('AliquotaPercentual').setValue(nfe_json_product.impostos.pis.aliquota);
      resolve(true);
    });
  }

  setProductTaxesCofins(nfe_json_product, i){
    return new Promise ((resolve, reject) =>{
      this.fifthFormGroup.controls[i].get('SituacaoTributariaCofins').setValue(nfe_json_product.impostos.cofins.situacao_tributaria);
      this.fifthFormGroup.controls[i].get('cofAliquotaPercentual').setValue(nfe_json_product.impostos.cofins.aliquota);
      resolve(true);
    });
  }

  setProductTaxesIcms(nfe_json_product, i) {
    return new Promise((resolve, reject) => {
      this.setProductTaxesIcmsSituacaoTributaria(nfe_json_product, i).then((result) => {
        if (result) {
          this.fifthFormGroup.controls[i].get('cfop').setValue(nfe_json_product.impostos.icms.codigo_cfop);
          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '00') {
            this.fifthFormGroup.controls[i].get('Aliquota').setValue(nfe_json_product.impostos.icms.aliquota);
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '10' || this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '30') {
            this.fifthFormGroup.controls[i].get('AliquotaDiferimento').setValue(nfe_json_product.impostos.icms.aliquota_diferimento);
            this.fifthFormGroup.controls[i].get('AliquotaDiferimentoFCP').setValue(nfe_json_product.impostos.icms.aliquota_diferimento_fcp);
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '20') {
            this.fifthFormGroup.controls[i].get('AliquotaReducao').setValue(nfe_json_product.impostos.icms.aliquota_reducao);
          }

          if (this.checkIfBeneficioFiscal(i)) {
            this.fifthFormGroup.controls[i].get('BeneficioFiscal').setValue(nfe_json_product.beneficio_fiscal);
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '51') {
            this.fifthFormGroup.controls[i].get('AliquotaDiferimento').setValue(nfe_json_product.impostos.icms.aliquota_diferimento);
            this.fifthFormGroup.controls[i].get('AliquotaDiferimentoFCP').setValue(nfe_json_product.impostos.icms.aliquota_diferimento_fcp);
          }
          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '60') {
            this.fifthFormGroup.controls[i].get('bc_st_retido').setValue(nfe_json_product.impostos.icms.bc_st_retido);
            this.fifthFormGroup.controls[i].get('aliquota_st_retido').setValue(nfe_json_product.impostos.icms.aliquota_st_retido);
            this.fifthFormGroup.controls[i].get('valor_st_retido').setValue(nfe_json_product.impostos.icms.valor_st_retido);
            this.fifthFormGroup.controls[i].get('bc_st_dest').setValue(nfe_json_product.impostos.icms.bc_st_dest);
            this.fifthFormGroup.controls[i].get('valor_st_dest').setValue(nfe_json_product.impostos.icms.valor_st_dest);
            this.fifthFormGroup.controls[i].get('valor_fcp_retido').setValue(nfe_json_product.impostos.icms.valor_fcp_retido);
            this.fifthFormGroup.controls[i].get('aliquota_fcp_retido').setValue(nfe_json_product.impostos.icms.aliquota_fcp_retido);
            this.fifthFormGroup.controls[i].get('aliquota_bc_efetivo').setValue(nfe_json_product.impostos.icms.aliquota_bc_efetivo);
            this.fifthFormGroup.controls[i].get('aliquota_icms_efetivo').setValue(nfe_json_product.impostos.icms.aliquota_icms_efetivo);
          }
          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '90') {
            this.fifthFormGroup.controls[i].get('aliquota_reducao_st').setValue(nfe_json_product.impostos.icms.aliquota_reducao_st);
            this.fifthFormGroup.controls[i].get('aliquota_mva').setValue(nfe_json_product.impostos.icms.aliquota_mva);
            this.fifthFormGroup.controls[i].get('industria').setValue(nfe_json_product.impostos.icms.industria);
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '101') {
            this.fifthFormGroup.controls[i].get('aliquota_credito').setValue(nfe_json_product.impostos.icms.aliquota_credito);
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '201' || this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '202' || this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '203') {
            this.fifthFormGroup.controls[i].get('aliquota_st').setValue(nfe_json_product.impostos.icms.aliquota_st);
            this.fifthFormGroup.controls[i].get('aliquota_fcp_st').setValue(nfe_json_product.impostos.icms.aliquota_fcp_st);
            this.fifthFormGroup.controls[i].get('aliquota_mva').setValue(nfe_json_product.impostos.icms.aliquota_mva);
            this.fifthFormGroup.controls[i].get('AliquotaReducao').setValue(nfe_json_product.impostos.icms.aliquota_reducao);
            this.fifthFormGroup.controls[i].get('aliquota_reducao_st').setValue(nfe_json_product.impostos.icms.aliquota_reducao_st);
            if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '201') {
              this.fifthFormGroup.controls[i].get('aliquota_credito').setValue(nfe_json_product.impostos.icms.aliquota_credito);
            }
          }

          if (this.fifthFormGroup.controls[i].get('SituacaoTributaria').value == '500') {
            this.fifthFormGroup.controls[i].get('bc_st_retido').setValue(nfe_json_product.impostos.icms.bc_st_retido);
            this.fifthFormGroup.controls[i].get('aliquota_st_retido').setValue(nfe_json_product.impostos.icms.aliquota_st_retido);
            this.fifthFormGroup.controls[i].get('valor_st_retido').setValue(nfe_json_product.impostos.icms.valor_st_retido);
            this.fifthFormGroup.controls[i].get('valor_icms_substituto').setValue(nfe_json_product.impostos.icms.valor_icms_substituto);
            this.fifthFormGroup.controls[i].get('valor_fcp_retido').setValue(nfe_json_product.impostos.icms.valor_fcp_retido);
            this.fifthFormGroup.controls[i].get('aliquota_fcp_retido').setValue(nfe_json_product.impostos.icms.aliquota_fcp_retido);
            this.fifthFormGroup.controls[i].get('aliquota_bc_efetivo').setValue(nfe_json_product.impostos.icms.aliquota_bc_efetivo);
            this.fifthFormGroup.controls[i].get('aliquota_icms_efetivo').setValue(nfe_json_product.impostos.icms.aliquota_icms_efetivo);
          }

          resolve(true);
        }
      });
    });
  }

  setProductTaxesIcmsSituacaoTributaria(nfe_json_product, i){
    this.fifthFormGroup.controls[i].get('SituacaoTributaria').setValue(nfe_json_product.impostos.icms.situacao_tributaria);
    return new Promise((resolve, reject) => {
      this.setImpostoBySitTrib(nfe_json_product.impostos.icms.situacao_tributaria, i);
      resolve(true);
    });
  }

  calcValorTotal(i) {
    var valor = Number(this.fifthFormGroup.controls[i].value.totalProdValue);
    this.valorTotal[i] =  valor;
    this.valorTotalDisplay[i] = new Intl.NumberFormat('pt-BR', {style:'currency', currency:'BRL'}).format(this.valorTotal[i]);
  }
  // public sugestao
  calculaValorDoPagamento(){
    if (this.pagamentoFormGroup.controls[0].get('ValorPagamento').value) return;
    let sugestao = 0.0;

    if(this.fourthFormGroup.value.valorTotalDesconto){
        sugestao -= +this.fourthFormGroup.value.valorTotalDesconto;
    }
    this.fifthFormGroup.controls.forEach(element =>{
      if(element.value.totalProdValue != ""){
        sugestao += +element.value.totalProdValue;
      }
    })
    if(this.seventhFormGroup.value.totalFrete){
      sugestao += +this.seventhFormGroup.value.totalFrete;
    }

    if (sugestao != 0){
      this.pagamentoFormGroup.controls[0].get('ValorPagamento').setValue(sugestao);
    }
  }

  calculaValoresProduto(produto, type, i) {
    if(this.complementarType == null){
    if(type == 'totalProdValue'){
      produto.get('unCommerceValue').setValue(Number(produto.value.totalProdValue) / Number(produto.value.comercialQt));
    }
    if(type == 'unCommerceValue'){
      produto.get('totalProdValue').setValue(Number((Number(produto.value.unCommerceValue) * Number(produto.value.comercialQt)).toFixed(2)));
    }
    if(type == 'comercialQt'){
      if(produto.value.unCommerceValue != '' && produto.value.unCommerceValue != Infinity){
        produto.get('totalProdValue').setValue(Number((Number(produto.value.unCommerceValue) * Number(produto.value.comercialQt)).toFixed(2)));
      }
    }
    this.calcValorTotal(i);
   }
  }

  // Cria um novo formulário de produto
  novoFormProd() {
    this.fifthFormGroup.push(
      new FormGroup({
        // PRODUTOS
        products: new FormControl("", Validators.required),
        comercialQt: new FormControl(0.0000, Validators.required),
        unCommerceValue: new FormControl(0.0000, Validators.required),
        totalProdValue: new FormControl(0.00, Validators.required),
        comercialUn: new FormControl("", Validators.required),
        gtin: new FormControl("", Validators.required),
        ncm: new FormControl("", Validators.required),
        slideTributario: new FormControl(false),
        tributacaoUn: new FormControl(""),
        tributacaoQt: new FormControl(""),
        unTribValue: new FormControl(""),
        gtin_tributavel: new FormControl(""),
        selectImposto: new FormControl(null),
        // ICMS
        SituacaoTributaria: new FormControl(null, Validators.required),
        cfop: new FormControl(null, Validators.required),
        Aliquota: new FormControl(null, Validators.required), // CST 00 - TRIBUTADA INTEGRALMENTE
        AliquotaReducao: new FormControl(null, Validators.required), // CST 20 - COM REDUÇÃO DE BASE DE CÁLCULO
        BeneficioFiscal: new FormControl(null, Validators.required), // CST 20 - COM REDUCAO DE BASE DE CÁLCULO CST 40 - ISENTA e CST 41 - NÃO TRIBUTADA e CST 51 - DIFERIMENTO
        AliquotaDiferimento: new FormControl(null, Validators.required), // CST 50 - DIFERIMENTO
        AliquotaDiferimentoFCP: new FormControl(null, Validators.required),// CST 50 - DIFERIMENTO
        bc_st_retido: new FormControl(null), // CST 60, 500
        aliquota_st_retido: new FormControl(null), // CST 60 , 500
        valor_st_retido: new FormControl(null), // CST 60, 500
        bc_st_dest: new FormControl(null), // CST 60 - ICMS COBRADO ANTERIORMENTE POR SUBSTITUIÇÃO TRIBUTÁRIA
        valor_st_dest: new FormControl(null), // CST 60 - ICMS COBRADO ANTERIORMENTE POR SUBSTITUIÇÃO TRIBUTÁRIA
        valor_fcp_retido: new FormControl(null), // CST 60, 500
        aliquota_fcp_retido: new FormControl(null), // CST 60, 500
        aliquota_bc_efetivo: new FormControl(null), // CST 60, 500
        aliquota_icms_efetivo: new FormControl(null), // CST 60, 500
        aliquota_reducao_st: new FormControl(null), // CST 90 - OUTRAS
        aliquota_mva: new FormControl(null), // CST 90, 201, 202, 203 - OUTRAS
        industria: new FormControl(null), // CST 90 - OUTRAS
        aliquota_st: new FormControl(null), // CST 201, 202, 203
        aliquota_fcp_st: new FormControl(null), // CST 201, 202, 203
        aliquota_credito: new FormControl(null), // CST 101, 201
        valor_icms_substituto: new FormControl(null), // CST 500
        motivo_desoneracao_st: new FormControl(null), // CST 10, 30

        // PIS
        SituacaoTributariaPis: new FormControl(null, Validators.required),
        AliquotaPercentual: new FormControl(null, Validators.required),
        // COFINS
        SituacaoTributariaCofins: new FormControl(null, Validators.required),
        cofAliquotaPercentual: new FormControl(null, Validators.required),
        // IPI
        SituacaoTributariaIpi: new FormControl("53", Validators.required),
        codigo_enquadramento: new FormControl("999", Validators.required),
        AliquotaIpi: new FormControl(0.00, Validators.required),
        percentual_devolvido: new FormControl(null),
        ipi_devolvido: new FormControl(null),
      })
    );
  }

  //seta os valores no formluario de produto ao escolher um produto
  selectProduct(event ,i) {
    let produto = event
    if (produto == 'option-new-product') this.newProduto();
    else if (produto != undefined) {
      let ncm = produto.ncm.toString()
      let unit=  produto.unit.description.toString()
      let unitTributary = produto.unitTributary ? produto.unitTributary.description.toString() : ''
      let gtin = produto.gtin.toString()
      this.fifthFormGroup.controls[i].get('products').setValue(event);
      this.fifthFormGroup.controls[i].get('comercialUn').setValue(unit);
      this.fifthFormGroup.controls[i].get('gtin').setValue(gtin);
      this.fifthFormGroup.controls[i].get('ncm').setValue(ncm);
      if(this.fifthFormGroup.controls[i].get('tributacaoUn').value != ''){
        this.fifthFormGroup.controls[i].get('tributacaoUn').setValue(unitTributary);
      }
    }
  }


  changeTributacao(value, index) {
    this.produtosTributacao[index] = value;
    if (value) {
      this.fifthFormGroup.controls[index].get("tributacaoUn").enable();
      this.fifthFormGroup.controls[index].get("tributacaoQt").enable();
      this.fifthFormGroup.controls[index].get("tributacaoUn").setValue(this.fifthFormGroup.controls[index].get("products").value.unitTributary.description);
    } else {
      this.fifthFormGroup.controls[index].get("tributacaoUn").setValue("");
      this.fifthFormGroup.controls[index].get("tributacaoQt").setValue("");
      this.fifthFormGroup.controls[index].get("tributacaoUn").disable();
      this.fifthFormGroup.controls[index].get("tributacaoQt").disable();
    }
  }

  //adiciona um field de pagamento
  novoPagamento() {
    this.pagamentoFormGroup.push(
      new FormGroup({
        IndicadorFormaPagamento: new FormControl(""),
        MeioPagamento: new FormControl("", Validators.required),
        ValorPagamento: new FormControl("0", Validators.required),
        desc_pagamento: new FormControl(null),
      })
    );
  }
  toogleProdutoFunc(i){
    this.produtosToggle[i] = this.produtosToggle[i] != true;
  }

  selectFaturamento(event) {
    return new Promise((resolve) => {
      const valor = event;
      const controls = this.faturamentoFormGroup.controls;
      const parcelas = this.parcelasArray.controls;

      if (valor == 0) {
        Object.keys(controls).forEach(key => controls[key].clearValidators());
        parcelas.forEach(parcela => Object.keys(parcela['controls']).forEach(key => parcela['controls'][key].clearValidators()));
        this.faturamento = false;
      } else {
        Object.keys(controls).forEach(key => controls[key].setValidators([Validators.required]));
        parcelas.forEach(parcela => Object.keys(parcela['controls']).forEach(key => parcela['controls'][key].setValidators([Validators.required])));
        this.faturamento = true;
      }

      Object.keys(controls).forEach(key => controls[key].updateValueAndValidity());
      parcelas.forEach(parcela => Object.keys(parcela['controls']).forEach(key => parcela['controls'][key].updateValueAndValidity()));
      this.selectFatura.setValue(valor);
      resolve(true);
    });
  }
  //adiciona um field de fatura
  novaFatura() {
    this.parcelasArray.push(
      new FormGroup({
        dVenc: new FormControl(""),
        vDup: new FormControl(""),
      })
    );
  }

  removerProduto(i) {
    if (this.fifthFormGroup.length > 1) {
      this.fifthFormGroup.removeAt(i);
    }
  }

  //remove um field de pagamento
  removerPagamento(i) {
    if (this.pagamentoFormGroup.length > 1) {
      this.pagamentoFormGroup.removeAt(i);
    }
  }

  //remove um field de fatura
  removerFatura(i) {
    this.parcelasArray.removeAt(i);
  }

    setUnnecessaryFieldsToNull(sitTrib: string, i: number) {
    const controls = this.fifthFormGroup.controls[i];

    const fieldsToEnable = {
      '00': ['Aliquota'],
      '10': ['aliquota_st', 'aliquota_fcp_st',  'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '20': ['AliquotaReducao', 'BeneficioFiscal'],
      '30': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '40': ['BeneficioFiscal'],
      '41': ['BeneficioFiscal'],
      '50': ['BeneficioFiscal'],
      '51': ['AliquotaDiferimento', 'AliquotaDiferimentoFCP', 'BeneficioFiscal'],
      '60': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'bc_st_dest', 'valor_st_dest', 'valor_fcp_retido',
        'aliquota_fcp_retido', 'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ],
      '90': ['AliquotaReducao', 'aliquota_reducao_st', 'aliquota_mva', 'industria'],
      '101': ['aliquota_credito'],
      '201': [
        'aliquota_credito', 'aliquota_st', 'aliquota_fcp_st',
        'aliquota_mva', 'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '202': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '203': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '500': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'valor_icms_substituto', 'valor_fcp_retido', 'aliquota_fcp_retido',
        'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ]
    };
    const allEnabledFields = new Set<string>();
    Object.values(fieldsToEnable).forEach(fields => {
      fields.forEach(field => allEnabledFields.add(field));
    });

    const fieldsToKeep = new Set(fieldsToEnable[sitTrib] || []);
    Object.keys(controls.controls).forEach(field => {
      if (!fieldsToKeep.has(field) && allEnabledFields.has(field)) {
        controls.get(field).setValue(null);
      }
    });
  }

  submitForms(preview: boolean) {
    this.enviando = true;
    this.chaves = [];

    this.chaves.push(this.nfReferenciadaForm.value)
    this.chaves.push(this.chavesNfeForm.value)

    this.chaves.push(this.nfReferenciadaForm.value);
    this.chaves.push(this.chavesNfeForm.value);

    this.chaves.push(this.chavesCupomForm.value);
    this.chaves.push(this.chavesProdutorForm.value);

      this.fifthFormGroup.value.forEach((element, index) => {
        this.setUnnecessaryFieldsToNull(element.SituacaoTributaria, index);
      })

    if (this.naturezaSelecionada.finalidade == 'NF-e complementar') {
      this.nfeJsonProvider.setJsonComplementar(this.emissores, this.enderecoEmissorSelecionado, this.enderecoDestinatarioSelecionado, this.destinatarioSelecionado, this.fifthFormGroup.value,
        this.naturezaSelecionada, this.complementarImpostosFormGroup.value, this.complementarType, this.chaveReferenciada, this.idEnderecoEmissorSelecionado,
        this.indFinal, preview, this.sixFormGroup, this.sendEmail, this.sendEmailClient).then((result) => {
        this.enviando = false;
      }).catch((err) =>{
        this.enviando = false;
        this.snackBar.openLong(err, "erro");
      })
    } else if (this.naturezaSelecionada.finalidade == 'NF-e ajuste') {
      this.nfeJsonProvider.setJsonAjuste(this.enderecoEmissorSelecionado, this.enderecoDestinatarioSelecionado, this.destinatarioSelecionado, this.naturezaSelecionada, this.ajusteForm, preview, this.sixFormGroup, this.sendEmail, this.sendEmailClient).then((result) => {
        this.enviando = false;
      }).catch((err) => {
        this.enviando = false;
        this.snackBar.openLong(err, 'erro');
      });
    }
    else {
      this.nfeJsonProvider.setJson(this.emissores, this.naturezaSelecionada, this.enderecoEmissorSelecionado, this.destinatarioSelecionado,
        this.enderecoDestinatarioSelecionado, this.seventhFormGroup.value, this.chaves, this.faturamento, this.faturamentoFormGroup.value, this.parcelasArray.value, this.fifthFormGroup.value,
        this.fourthFormGroup.value, this.pagamentoFormGroup.value, this.freteInfo, this.indFinal, this.deliveryAddress, this.receivingAddress, this.idEnderecoEmissorSelecionado,
        preview, this.sixFormGroup, this.sendEmail, this.sendEmailClient).then((result) => {
        this.enviando = false;
      }).catch((err) =>{
        this.enviando = false;
        this.snackBar.openLong(err, "erro");
      })
    }

  }

  saveDraft(){
    this.enviando = true;
    this.chaves = [];

    this.chaves.push(this.nfReferenciadaForm.value);
    this.chaves.push(this.chavesNfeForm.value);
    this.chaves.push(this.chavesCupomForm.value);
    this.chaves.push(this.chavesProdutorForm.value);

    this.fifthFormGroup.value.forEach((element, index) => {
      this.setUnnecessaryFieldsToNull(element.SituacaoTributaria, index);
    })

    if(this.naturezaSelecionada.finalidade == 'NF-e complementar'){
      let json = {
        emissor: this.emissores,
        enderecoEmissor: this.enderecoEmissorSelecionado,
        enderecoDestinatario: this.enderecoDestinatarioSelecionado,
        destinatario: this.destinatarioSelecionado,
        produtos: this.fifthFormGroup.value,
        natureza: this.naturezaSelecionada,
        impostos: this.complementarImpostosFormGroup.value,
        tipo: this.complementarType,
        tipoImposto: this.complementarType == 'imposto' ? this.complementarTax : null,
        chaveReferenciada: this.chaveReferenciada,
        idEnderecoEmissor: this.idEnderecoEmissorSelecionado,
        indFinal: this.indFinal,
        informacoesComplementares: this.sixFormGroup.value,
        sendEmail: this.sendEmail
      };
      this.nfeJsonProvider.setJsonComplementarDraft(json).then((result) => {
        this.openModalRascunho(result);
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        console.log(err);
      });
    } else if(this.naturezaSelecionada.finalidade == 'NF-e ajuste'){
      let json = {
        enderecoEmissor: this.enderecoEmissorSelecionado,
        enderecoDestinatario: this.enderecoDestinatarioSelecionado,
        destinatario: this.destinatarioSelecionado,
        natureza: this.naturezaSelecionada,
        ajuste: this.ajusteForm.getRawValue(),
        informacoesComplementares: this.sixFormGroup.value,
        sendEmail: this.sendEmail
      };
      this.nfeJsonProvider.setJsonAjusteDraft(json).then((result) => {
        this.openModalRascunho(result);
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        console.log(err);
      });
    }
    else{
      let json = {
        emissor: this.emissores,
        natureza: this.naturezaSelecionada,
        enderecoEmissor: this.enderecoEmissorSelecionado,
        destinatario: this.destinatarioSelecionado,
        enderecoDestinatario: this.enderecoDestinatarioSelecionado,
        transporte: this.seventhFormGroup.value,
        chaves: this.chaves,
        faturamento: this.faturamento,
        fatura: this.faturamentoFormGroup.value,
        parcelas: this.parcelasArray.value,
        produtos: this.fifthFormGroup.value,
        geral: this.fourthFormGroup.value,
        pagamentoFormGroup: this.pagamentoFormGroup.value,
        freteInfo: this.freteInfo,
        indFinal: this.indFinal,
        enderecoRecebimento: this.deliveryAddress,
        enderecoRetirada: this.receivingAddress,
        idEnderecoEmissor: this.idEnderecoEmissorSelecionado,
        informacoesComplementares: this.sixFormGroup.value,
        sendEmail: this.sendEmail
      };
      this.nfeJsonProvider.setJsonDraft(json).then((result) => {
        this.openModalRascunho(result);
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        console.log(err);
      });
    }
  }

  openModalRascunho(json) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroRascunhosComponent, modalOptions);
    modalRef.componentInstance.nfeJson = json;
    if (this.editDraft) {
      modalRef.componentInstance.editar = this.editDraft;
      modalRef.componentInstance.id = this.draftObj.id;
      modalRef.componentInstance.description = this.draftObj.description;
      modalRef.componentInstance.isShared = this.draftObj.isShared;
    }
    modalRef.result.then((result) => {
        if (result) {
          // this.getDrafts();
          let txt = this.editDraft ? "Rascunho editado com sucesso!" : "Rascunho adicionado com sucesso!";
          Swal.fire({
            title: "Sucesso!",
            text: txt,
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
          if (!this.fromDraft) this.router.navigateByUrl('/notafiscal/cadastros/rascunhos');
        }
        this.enviando = false;
      }, () => {
        this.enviando = false;
      }
    );
  }

  submitNotaDevolucao(){
    return new Promise((resolve, reject) =>{
      let json = {
        chave: this.devolucaoFormGroup.get('chaveDevolucao').value,
        natureza_operacao: this.naturezaSelecionada.finalidade,
        codigo_cfop: this.devolucaoFormGroup.get('cfopDevolucao').value,
        volume: this.devolucaoFormGroup.get('volumesDevolucao').value,
        produtos: null,
        quantidade: null,
        informacoes_complementares: this.sixFormGroup.get('InfoCompl').value,
        informacoes_fisco: this.sixFormGroup.get('InfoAoFisco').value,
      };
      if (this.devolucaoFormGroup.get('devolucaoParcial').value) {
        let produtos = [];
        let quantidade = [];
        this.produtosDevolucaoForm.value.forEach((value) =>{
          produtos.push(value.produto);
          quantidade.push(value.quantidade);
        });
        json.produtos = produtos;
        json.quantidade = quantidade;
      }
      this.nfeJsonProvider.postDevolutionNfe(json).then((result: any) => {
        if(result.status == "aprovado"){
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal foi emitida!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if(result.isConfirmed){
              resolve(true);
            }
          });
        } else if (result.status == "processamento") {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal está em processamento!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if(result.isConfirmed){
              resolve(true);
            }
          });
        } else {
          Swal.fire({
            title: 'Falha!',
            text: result.description,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if(result.isConfirmed){
              resolve(true);
            }
          });
        }
      });
    });

  }

  // realiza um get e seta emissores
  getDrafts() {
    this.rascunhos = [];
    return new Promise((resolve, reject) => {
      this.rascunhosProvider.getDrafts().then((resp: any) => {
        this.rascunhos = resp;
        this.initializeSearchDraft();
        resolve("this.rascunhos");
      })
        .catch((result: HttpErrorResponse) => {
          reject("erro");
        });
    });
  }

  // realiza um get e seta emissores
  getEmitters() {
    this.emissores = [];
    return new Promise((resolve, reject) => {
      this.userProvider
        .getEmitente(localStorage.getItem("userId"))
        .then((emissor: any) => {
          let emissorValue;
          let enderecos = [];

          if (emissor.propertys.length <= 0) {
            enderecos.push({
              id: "",
              descricao: "",
              inscricaoEstadual: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              cep: "",
              telefone: "",
              uf: "",
              codigoMunicipio: "",
              pais: "",
              serialNumber: "",
              dfeNumber: "",
            });
          }
          Object.keys(emissor.propertys).forEach((item) => {
            enderecos.push({
              id: emissor.propertys[item].id,
              descricao:
                emissor.propertys[item].neighborhood +
                ", " +
                emissor.propertys[item].street +
                ", " +
                emissor.propertys[item].houseNumber,
              inscricaoEstadual: emissor.propertys[item].ie,
              logradouro: emissor.propertys[item].street,
              numero: emissor.propertys[item].houseNumber,
              complemento: emissor.propertys[item].complement,
              bairro: emissor.propertys[item].neighborhood,
              cep: emissor.propertys[item].cep,
              telefone: emissor.propertys[item].telephone,
              uf: emissor.propertys[item].ufId,
              codigoMunicipio: emissor.propertys[item].cityId,
              pais: emissor.propertys[item].countryId,
              serialNumber: emissor.propertys[item].serialNumber,
              dfeNumber: emissor.propertys[item].dfeNumber,
            });
          });
          emissorValue = {
            id: 1,
            arrayKey: 0,
            nome: emissor.name,
            companyName: emissor.companyName,
            cpfCnpj: emissor.cpfCnpj,
            regimeTributario: emissor.taxRegime,
            enderecos: enderecos,
            email: emissor.issuerEmail
          };
          this.emissores.push(emissorValue);
          this.selectEmitter(0);
          resolve("this.emissores");
        })
        .catch((result: HttpErrorResponse) => {
          reject("erro");
        });
    });
  }

  // realiza um get e seta destinatarios
  getDestinataries() {
    return new Promise((resolve, reject) => {
      this.destinatarios2 = [];
      this.destinatarios = [];
      this.userProvider.getAllDestinat().then((farmerUsers: any) => {
        this.clientes = farmerUsers;
        if (farmerUsers.length > 0) {
          let destValue;
          let enderecos = [];
          Object.keys(farmerUsers).forEach((item) => {
            if (farmerUsers[item].properties.length <= 0) {
              enderecos.push({
                id: "",
                descricao: "",
                inscricaoEstadual: "",
                logradouro: "",
                numero: "",
                complemento: "",
                bairro: "",
                cep: "",
                telefone: "",
                uf: "",
                codigoMunicipio: "",
                pais: "",
                serialNumber: "",
                dfeNumber: "",
              });
            }
            Object.keys(farmerUsers[item].properties).forEach((item2) => {
              if (farmerUsers[item].properties[item2].ie == "") {
              }
              enderecos.push({
                id: farmerUsers[item].properties[item2].id,
                descricao:
                  farmerUsers[item].properties[item2].neighborhood +
                  ", " +
                  farmerUsers[item].properties[item2].street +
                  ", " +
                  farmerUsers[item].properties[item2].houseNumber,
                inscricaoEstadual: farmerUsers[item].properties[item2].ie,
                logradouro: farmerUsers[item].properties[item2].street,
                numero: farmerUsers[item].properties[item2].houseNumber,
                complemento: farmerUsers[item].properties[item2].complement,
                bairro: farmerUsers[item].properties[item2].neighborhood,
                cep: farmerUsers[item].properties[item2].cep,
                telefone: farmerUsers[item].telephone,
                uf: farmerUsers[item].properties[item2].ufId,
                codigoMunicipio: farmerUsers[item].properties[item2].cityId,
                pais: farmerUsers[item].properties[item2].countryId,
                ie: farmerUsers[item].properties[item2].ie,
                ieType: farmerUsers[item].properties[item2].ieType,
              });
            });
            destValue = {
              arrayKey: item,
              id: farmerUsers[item].id,
              foreignId: farmerUsers[item].foreignId,
              nome: farmerUsers[item].name,
              cpfCnpj: farmerUsers[item].cpfCNPJ,
              enderecos: enderecos,
              email: farmerUsers[item].email,
              telephone: farmerUsers[item].telephone,
              taxSubstitute: farmerUsers[item].taxSubstitute,
              isShared: farmerUsers[item].isShared,
            };
            this.destinatarios2.push(farmerUsers[item]);
            this.destinatarios.push(destValue);
            resolve(true);
            enderecos = [];
          });
        }
        if (this.destinatarioSelecionado.arrayKey) {
          this.selectDestinatary(this.destinatarioSelecionado.id, true);
        }
        this.initializeSearchDestinatary();
        resolve(true);
      }).catch((result) => {
        console.log(result);
        reject("erro");
      });
    })
  }

  editCliente(info, selected?) {
    let cliente;
    this.clientes.forEach((client) =>{if(client.cpfCNPJ == info.cpfCnpj) cliente=client});
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.destExistente = cliente;
    modalRef.componentInstance.editar = true;
    modalRef.result.then((resp) => {
     // this.novaConsultaPessoaFiscal();
     Swal.fire({
      title: 'Sucesso!',
      text: "Destinatario editado com sucesso",
      icon: 'success',
      confirmButtonColor: '#3085d6',
    }).then((result)=>{
      if(result.isConfirmed){
        this.getDestinataries();
      }
    })
    }, (res) => {
      this.sendEmailClient = false;
    });
  }

  // realiza um get e seta naturezas da operação
  getNatures() {
    this.naturezas = [];
    return new Promise((resolve, reject) => {
      this.opnatureProvider.getAllOPNatures().then((result) => {
        Object.keys(result).forEach((item) => {
          this.naturezas.push({
            id: item,
            descricao: result[item].description,
            tipo: result[item].operationType,
            tipoNum: result[item].operationTypeNumber,
            finalidade: result[item].purposeIssue,
            finalidade_id: result[item],
            isShared: result[item].isShared,
          });
        });
        this.initializeSearchNature();
        resolve(true);
      }).catch((result) => {
        console.log(result);
        reject("erro");
      });
    });
  }

  // realiza um get e seta produtos
  getProdutos() {
    this.products = [];
    return new Promise((resolve, reject) => {
      this.productProvider
        .getAllProducts()
        .then((result) => {
          Object.keys(result).forEach((item) => {
            result[item].arrayKey = item;
            this.products.push(result[item]);
          });
          this.initializeSearchProducts();
          resolve(this.products);
        })
        .catch((result) => {
          console.log(result);
          reject("erro");
        });
    });
  }

  getImpostos(){
    this.impostos = [];
    return new Promise((resolve, reject) => {
      this.impostoProvider.getImposto().then((result) => {
        this.impostos = result;
        this.initializeSearchTaxes();
        resolve(true);
      }).catch((result) => {
        console.log(result);
        reject("erro");
      });
    });
  }

  getInfosAdicionais(){
    this.infosAdicionais = [];
    return new Promise((resolve, reject) => {
      this.infosAdicionaisProvider.getInfosAdicionais().then((result) => {
        this.infosAdicionais = result;
        this.initializeSearchInfoAdicionals();
        resolve(true);
      }).catch((result) => {
        console.log(result);
        reject("erro");
      });
    });
  }

  getDataSourceMain() {
    for (const produto of this.products) {
      this.dataSourceMain.push({
        id: produto.id,
        produto: `${produto.id} - ${produto.description}`,
        ncm: produto.ncm,
      });
    }
  }


  //chama modal de cadastro de destinatario
  newDestinatario() {
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.result.then(
      (result) => {
        this.getDestinataries();
        Swal.fire({
          title: "Sucesso!",
          text: "Destinatario adicionado com sucesso",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      },
      () => {}
    );
  }

  //chama modal de cadastro de natureza
  newNatureza() {
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroNaturezaopComponent, modalOptions);
    modalRef.result.then(
      (result) => {
        this.getNatures();
        Swal.fire({
          title: "Sucesso!",
          text: "Adicionado",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
      },
      () => {}
    );
  }

  //chama modal de cadastro de produto
  newProduto() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroProdutoComponent, modalOptions);
    modalRef.result.then((result) => {
        this.getProdutos();
      }, () => {}
    );
  }

  newImposto(){
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroImpostosComponent, modalOptions);
    modalRef.result.then((result) => {
      this.impostoProvider.getImposto().then((result) => {
        this.impostos = result;
        Swal.fire({
          title: 'Sucesso!',
          text: "A classe de imposto foi adicionada com sucesso!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      });
    }, () => {});
  }

  newInfosAdicionais(){
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroInfosAdicionaisComponent, modalOptions);
    modalRef.result.then((result) => {
      this.infosAdicionaisProvider.getInfosAdicionais().then((result) => {
        this.infosAdicionais = result;
        Swal.fire({
          title: 'Sucesso!',
          text: "A classe de imposto foi adicionada com sucesso!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      });
    }, () => {});
  }


  setImpostoBySitTrib(sitTrib, i) {
    const controls = this.fifthFormGroup.controls[i].controls;

    const fieldsToDisable = [
      'Aliquota', 'AliquotaDiferimento', 'AliquotaDiferimentoFCP',
      'AliquotaReducao', 'BeneficioFiscal', 'bc_st_retido',
      'aliquota_st_retido', 'valor_st_retido', 'bc_st_dest',
      'valor_st_dest', 'valor_fcp_retido', 'aliquota_fcp_retido',
      'aliquota_bc_efetivo', 'aliquota_icms_efetivo', 'aliquota_reducao_st',
      'aliquota_mva', 'industria', 'aliquota_credito', 'aliquota_st',
      'aliquota_fcp_st', 'valor_icms_substituto', 'motivo_desoneracao_st'
    ];
    fieldsToDisable.forEach(field => controls[field].disable());

    const fieldsToEnable = {
      '00': ['Aliquota'],
      '10': ['aliquota_st', 'aliquota_fcp_st',  'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '20': ['AliquotaReducao', 'BeneficioFiscal'],
      '30': ['aliquota_st', 'aliquota_fcp_st', 'aliquota_mva', 'aliquota_reducao_st', 'motivo_desoneracao_st'],
      '40': ['BeneficioFiscal'],
      '41': ['BeneficioFiscal'],
      '50': ['BeneficioFiscal'],
      '51': ['AliquotaDiferimento', 'AliquotaDiferimentoFCP', 'BeneficioFiscal'],
      '60': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'bc_st_dest', 'valor_st_dest', 'valor_fcp_retido',
        'aliquota_fcp_retido', 'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ],
      '90': ['aliquota_reducao_st', 'aliquota_mva', 'industria'],
      '101': ['aliquota_credito'],
      '201': [
        'aliquota_credito', 'aliquota_st', 'aliquota_fcp_st',
        'aliquota_mva', 'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '202': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '203': [
        'aliquota_st', 'aliquota_fcp_st', 'aliquota_mva',
        'AliquotaReducao', 'aliquota_reducao_st'
      ],
      '500': [
        'bc_st_retido', 'aliquota_st_retido', 'valor_st_retido',
        'valor_icms_substituto', 'valor_fcp_retido', 'aliquota_fcp_retido',
        'aliquota_bc_efetivo', 'aliquota_icms_efetivo'
      ]
    };
    if (fieldsToEnable[sitTrib]) {
      fieldsToEnable[sitTrib].forEach(field => controls[field].enable());
      if (sitTrib === '20' || sitTrib === '51') {
        controls.BeneficioFiscal.clearValidators();
      }
    }
  }

  selectImposto(value, i) {
    if (value === 'option-new-tax') {
      this.newImposto();
      return;
    }

    const situacaoTributaria = value.icms.situacao_tributaria;
    this.setImpostoBySitTrib(situacaoTributaria, i);
    const controls = this.fifthFormGroup.controls[i];
    controls.get('SituacaoTributaria').setValue(situacaoTributaria);
    controls.get('cfop').setValue(value.icms.codigo_cfop);
    controls.get('SituacaoTributariaCofins').setValue(value.cofins.situacao_tributaria);
    controls.get('cofAliquotaPercentual').setValue(value.cofins.aliquota);
    controls.get('SituacaoTributariaPis').setValue(value.pis.situacao_tributaria);
    controls.get('AliquotaPercentual').setValue(value.pis.aliquota);

    const icms = value.icms;
    if (situacaoTributaria === '00') {
      controls.get('Aliquota').setValue(icms.aliquota);
    } else if (situacaoTributaria === '51') {
      controls.get('AliquotaDiferimento').setValue(icms.aliquota_diferimento);
      controls.get('AliquotaDiferimentoFCP').setValue(icms.aliquota_diferimento_fcp);
    } else if(situacaoTributaria === '10' || situacaoTributaria === '30'){
      controls.get('aliquota_st').setValue(icms.aliquota_st);
      controls.get('aliquota_fcp_st').setValue(icms.aliquota_fcp_st);
      controls.get('aliquota_mva').setValue(icms.aliquota_mva);
      controls.get('AliquotaReducao').setValue(icms.AliquotaReducao);
      controls.get('aliquota_reducao_st').setValue(icms.aliquota_reducao_st);
      controls.get('motivo_desoneracao_st').setValue(icms.motivo_desoneracao_st);
    }
    else if (situacaoTributaria === '20') {
      controls.get('AliquotaReducao').setValue(icms.aliquota_reducao);
    } else if (situacaoTributaria === '60') {
      controls.get('bc_st_retido').setValue(icms.bc_st_retido);
      controls.get('aliquota_st_retido').setValue(icms.aliquota_st_retido);
      controls.get('valor_st_retido').setValue(icms.valor_st_retido);
      controls.get('bc_st_dest').setValue(icms.bc_st_dest);
      controls.get('valor_st_dest').setValue(icms.valor_st_dest);
      controls.get('valor_fcp_retido').setValue(icms.valor_fcp_retido);
      controls.get('aliquota_fcp_retido').setValue(icms.aliquota_fcp_retido);
      controls.get('aliquota_bc_efetivo').setValue(icms.aliquota_bc_efetivo);
      controls.get('aliquota_icms_efetivo').setValue(icms.aliquota_icms_efetivo);
    } else if (situacaoTributaria === '90') {
      controls.get('aliquota_reducao_st').setValue(icms.aliquota_reducao_st);
      controls.get('aliquota_mva').setValue(icms.aliquota_mva);
      controls.get('industria').setValue(icms.industria);
    } else if (situacaoTributaria === '101') {
      controls.get('aliquota_credito').setValue(icms.aliquota_credito);
    } else if (situacaoTributaria === '201') {
      controls.get('aliquota_credito').setValue(icms.aliquota_credito);
      controls.get('aliquota_st').setValue(icms.aliquota_st);
      controls.get('aliquota_fcp_st').setValue(icms.aliquota_fcp_st);
      controls.get('aliquota_mva').setValue(icms.aliquota_mva);
      controls.get('AliquotaReducao').setValue(icms.AliquotaReducao);
      controls.get('aliquota_reducao_st').setValue(icms.aliquota_reducao_st);
    } else if (situacaoTributaria === '202' || situacaoTributaria === '203') {
      controls.get('aliquota_st').setValue(icms.aliquota_st);
      controls.get('aliquota_fcp_st').setValue(icms.aliquota_fcp_st);
      controls.get('aliquota_mva').setValue(icms.aliquota_mva);
      controls.get('AliquotaReducao').setValue(icms.AliquotaReducao);
      controls.get('aliquota_reducao_st').setValue(icms.aliquota_reducao_st);
    } else if (situacaoTributaria === '500') {
      controls.get('bc_st_retido').setValue(icms.bc_st_retido);
      controls.get('aliquota_st_retido').setValue(icms.aliquota_st_retido);
      controls.get('valor_st_retido').setValue(icms.valor_st_retido);
      controls.get('valor_icms_substituto').setValue(icms.valor_icms_substituto);
      controls.get('valor_fcp_retido').setValue(icms.valor_fcp_retido);
      controls.get('aliquota_fcp_retido').setValue(icms.aliquota_fcp_retido);
      controls.get('aliquota_bc_efetivo').setValue(icms.aliquota_bc_efetivo);
      controls.get('aliquota_icms_efetivo').setValue(icms.aliquota_icms_efetivo);
    }

    if (this.checkIfBeneficioFiscal(i)) {
      controls.get('BeneficioFiscal').setValue(icms.beneficio_fiscal);
    }
  }

  selectInfosAdicionais(value){
    this.sixFormGroup.get('InfoCompl').setValue(value.additionalInfo);
    this.sixFormGroup.get('InfoAoFisco').setValue(value.infoFisco);
  }

//recebe data atual e formata para funcionar com a mascara
  setDataEmissao() {
    var now_timezone = new Date((new Date().toISOString()).slice(0,-1)+"+03:00").toISOString().slice(0, 19);
    this.fourthFormGroup.get('dtEmi').setValue(now_timezone);
  }

//controla o selec que seleciona o emissor e seta os campos
  selectEmitter(value) {
    const valor = value;
    if (valor != undefined) {
      this.emissorSelecionado = this.emissores.filter((x) => x.arrayKey === valor)[0];
      this.statesProvider.getStatesById(this.emissorSelecionado.enderecos[0].uf).then((result) => {
          this.emissorSelecionado.enderecos[0].ufName = result["abbreviation"];
        });
      this.citiesProvider.getCityById(this.emissorSelecionado.enderecos[0].codigoMunicipio).then((result) => {
          this.emissorSelecionado.enderecos[0].municipioName = result["name"];
      });
      if (this.emissorSelecionado.regimeTributario.hasOwnProperty('description')) {
        this.emissorSelecionado.regimeTributario = this.emissorSelecionado.regimeTributario.description;
      }
    }
  }

//controla o selec que seleciona o destinatario e seta os campos
  selectDestinatary(event, edit?) {
    return new Promise((resolve, reject) => {
      let valor = event;
      if (valor != undefined) {
        if (edit) {
          this.destinatarioSelecionado = this.destinatarios.filter(x => x.id === valor)[0];
        } else {
          this.destinatarioSelecionado = this.destinatarios.filter((x) => x.arrayKey === valor)[0];
        }

        this.enderecoDestinatarioSelecionado = this.destinatarioSelecionado.enderecos[0];
        this.countriesProvider.getCountryById(this.destinatarioSelecionado.enderecos[0].pais).then((result) => {
          this.destinatarioSelecionado.enderecos[0].paisName = result["name"];
        });
        this.statesProvider.getStatesById(this.destinatarioSelecionado.enderecos[0].uf).then((result) => {
          this.destinatarioSelecionado.enderecos[0].ufName = result["abbreviation"];
        });
        this.citiesProvider.getCityById(this.destinatarioSelecionado.enderecos[0].codigoMunicipio).then((result) => {
          this.destinatarioSelecionado.enderecos[0].municipioName = result["name"];
        });
        this.idEnderecoDestinatarioSelecionado = this.enderecoDestinatarioSelecionado.id;
        this.descricoesSelect.cliente = true;
        resolve(true);
      }
    });
  }

//controla o selec que seleciona o endereço do emissor e seta os campos
  selectEmitterAddress(event) {
    const valor = event;
    if (valor != undefined) {
      this.enderecoEmissorSelecionado = this.emissorSelecionado.enderecos.filter((x) => x.id === valor)[0];

      if (this.enderecoEmissorSelecionado != undefined){
        this.descricoesSelect.emissor = true;
        this.statesProvider.getStatesById(this.enderecoEmissorSelecionado.uf).then((result) => {
          this.enderecoEmissorSelecionado.ufName = result["name"];
          this.citiesProvider.getCityById(this.enderecoEmissorSelecionado.codigoMunicipio).then((result) => {
            this.enderecoEmissorSelecionado.municipioName = result["name"];
          });
        }).catch((result) => {
          console.log(result);
        });
      } else {
        this.enderecoEmissorSelecionado = {
          ie: undefined, ieType: undefined, municipioName: undefined, paisName: "", ufName: "",
          id: null,
          descricao: "",
          inscricaoEstadual: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cep: "",
          telefone: "",
          uf: "",
          codigoMunicipio: "",
          pais: "",
          serialNumber: "",
          dfeNumber: ""
        };
      }

    }
  }

//controla o selec que seleciona o endereço do destinatario e seta os campos
  selectDestinataryAddress($event: MatSelectChange) {
    const valor = $event.value;
    if (valor != undefined) {
      if (valor > 0) {
        this.enderecoDestinatarioSelecionado = this.destinatarioSelecionado.enderecos.filter((x) => x.id === valor)[0];
      }
      this.countriesProvider.getCountryById(this.enderecoDestinatarioSelecionado.pais).then((result) => {
        this.enderecoDestinatarioSelecionado.paisName = result["name"];
      });
      this.statesProvider.getStatesById(this.enderecoDestinatarioSelecionado.uf).then((result) => {
        this.enderecoDestinatarioSelecionado.ufName = result["name"];
      });
      this.citiesProvider.getCityById(this.enderecoDestinatarioSelecionado.codigoMunicipio).then((result) => {
        this.enderecoDestinatarioSelecionado.municipioName = result["name"];
      });
    }
  }

  selectReferenciada(event){
    return new Promise((resolve, reject) => {
      this.nfReferenciadaForm.get('referenciada').setValue(parseInt(event));
      resolve(true);
    });
  }

  removeReferenciada(index) {
    if (this.nfReferenciadaForm.value.referenciada == 1 || this.nfReferenciadaForm.value.referenciada == 2) {
      this.chavesNfeForm.removeAt(index, 1);
    }
    if (this.nfReferenciadaForm.value.referenciada == 3) {
      this.chavesCupomForm.removeAt(index, 1);
    }
    if (this.nfReferenciadaForm.value.referenciada == 4) {
      this.chavesProdutorForm.removeAt(index, 1);
    }
  }

  removeAllReferenciadas(){
    while (this.chavesNfeForm.length !== 0) {
      this.chavesNfeForm.removeAt(0);
    }
    while (this.chavesCupomForm.length !== 0) {
      this.chavesCupomForm.removeAt(0);
    }
    while (this.chavesProdutorForm.length !== 0) {
      this.chavesProdutorForm.removeAt(0);
    }
  }

  removeProdutoDevolucao(index){
    this.produtosDevolucaoForm.removeAt(index, 1);
  }

  addProdutoDevolucao(){
    this.produtosDevolucaoForm.push(
      new FormGroup({
        produto: new FormControl("", Validators.required),
        quantidade: new FormControl("", Validators.required)
      })
    );
  }

  addReferenciada() {
    if (this.nfReferenciadaForm.value.referenciada == 1 || this.nfReferenciadaForm.value.referenciada == 2) {
      this.chavesNfeForm.push(
        new FormGroup({
          chave: new FormControl (""),
        })
      );
    }
    if (this.nfReferenciadaForm.value.referenciada == 3) {
      this.chavesCupomForm.push(
        new FormGroup({
          modelo: new FormControl(""),
          ecf: new FormControl(""),
          coo: new FormControl(""),
        })
      );
    }
    if (this.nfReferenciadaForm.value.referenciada == 4) {
      this.chavesProdutorForm.push(
        new FormGroup({
          estado: new FormControl(""),
          data: new FormControl(""),
          modelo: new FormControl(""),
          cpfCnpj: new FormControl(""),
          ie: new FormControl(""),
          serie: new FormControl(""),
          numero: new FormControl(""),
        })
      );
    }
  }

  selectComplementary(event) {
    if (this.naturezaSelecionada.finalidade != 'NF-e complementar') {
      this.complementarType = null;
    } else {
      this.complementarType = event;
    }
  }

  selectTax(event){
    this.complementarTax = event;
  }

//controla o selec que seleciona a natureza da operação e seta os campos
  selectNature(event) {
    const valor = event;
    if (valor != undefined) {
      this.naturezaSelecionada = this.naturezas.find((x) => x.id === valor);
      if(this.naturezaSelecionada.finalidade == "Devolução/retorno"){
        this.devolucaoFormGroup.controls['chaveDevolucao'].setValidators([Validators.required]);
      }
      else{
        this.devolucaoFormGroup.controls['chaveDevolucao'].clearValidators();
      }
      this.devolucaoFormGroup.get('devolucaoParcial').setValue(false);
      if (this.naturezaSelecionada.finalidade != "NF-e complementar") {
        this.complementarType = null;
        this.complementarTax = null;
        if(this.naturezaSelecionada.finalidade == "NF-e ajuste"){
          this.fillNfeFormsAdjust();
        }
      } else {
        let type = "";
        if (this.nfe_json.produtos) {
          type = "quantidade";
        } else if (this.nfe_json.impostos) {
          this.fillNfeFormsComplementary();
          type = "imposto";
        }
        this.tipoComplementar.setValue(type);
        this.selectComplementary(type);
        if (this.nfe_json.tipoImposto){
          this.tipoImposto.setValue(this.nfe_json.tipoImposto);
          this.selectTax(this.nfe_json.tipoImposto);
        }
      }
      this.descricoesSelect.natureza = true;
    }
  }

  selectFrete(event) {
    return new Promise((resolve, reject) => {
      const valor = event.toString();
      if (valor == "9") {
        Object.keys(this.seventhFormGroup.controls).forEach((item) => {
          if (item != 'modalidadeFrete') {
            this.seventhFormGroup.controls[item].disable();
          }
        });
        this.frete = false;
        this.freteInfoAux = false;
        this.freteInfo = null;
      } else {
        Object.keys(this.seventhFormGroup.controls).forEach((item) => {
          if (item != 'modalidadeFrete') {
            this.seventhFormGroup.controls[item].enable();
          }
        });
        this.frete = true;
      }
      resolve(true);
    });
  }

//abre modal de cadastro de endereço para o emissor
  openEmitterAddressModal(novo: boolean) {
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroImoveisComponent, modalOptions);
    modalRef.componentInstance.emitente = true;
    modalRef.result.then(
      (result) => {
        this.getEmitters();
      },
      () => {}
    );
  }

// abre o modal de cadastro de destinatario
  openDestinataryAddressModal() {
    const value = this.destinatarioSelecionado.arrayKey;
    let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.destExistente =
      this.destinatarios2[this.destinatarioSelecionado.arrayKey];
    modalRef.componentInstance.editar = true;
    modalRef.result.then(
      (result) => {
        this.getDestinataries();
        Swal.fire({
          title: "Sucesso!",
          text: "Destinatario adicionado com sucesso",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        //this.receiver.push(result);
      },
      () => {
        // this.getPessoaFiscal();
      }
    );
  }

  checkDevolucaoParcial(){
    this.devolucaoFormGroup.get('devolucaoParcial').setValue(!this.devolucaoFormGroup.get('devolucaoParcial').value);
  };

//seta a variavel de endereco retirada
  newEnderecoRetirada(result) {
    this.enderecoRetirada = result;
  }

//seta a variavel de endereco recebimento
  newEnderecoRecebimento(result, cep) {
    this.enderecoRecebimento = result;
  }

  //seta a variavel de endereco frete
  newEnderecoFrete(result, cep) {
    this.enderecoFrete = result;
    this.seventhFormGroup.get('endereco').setValue(this.enderecoFrete);
  }

//remove a variavel de endereco retirada
  removeEnderecoFrete() {
    this.enderecoFrete = null;
    this.seventhFormGroup.get('endereco').setValue(null);
  }

  changeFrete() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.persistInformation = false;
    modalRef.componentInstance.frete = true;
    modalRef.componentInstance.destinatarios = this.destinatarios;
    modalRef.result.then((result) => {
      this.freteInfoAux = true;
      this.freteInfo = result;
      this.statesProvider.getStatesById(this.freteInfo.properties[0].ufId).then((result) => {
        this.freteInfo.properties[0].ufAbr = result["abbreviation"];
      });
      this.statesProvider.getStatesById(this.freteInfo.frete[0].ufVeiculoId).then((result) => {
        this.freteInfo.frete[0].ufVeiculoAbr = result["abbreviation"];
      });
      if(this.freteInfo.frete[0].reboque.length > 0){
        this.reboque = true;
        this.freteInfo.frete[0].reboque.forEach((element) =>{
          this.statesProvider.getStatesById(element.estadoVeiculoId).then((result) => {
            element.uf_veiculoAbr = result["abbreviation"];
          });
        })
      }
    }, () => {});
  }

  editFrete(dados){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.persistInformation = false;
    modalRef.componentInstance.frete = true;
    modalRef.componentInstance.destExistente = dados;
    modalRef.componentInstance.destinatarios = this.destinatarios;
    modalRef.componentInstance.editar = true;
    modalRef.result.then((result) => {
      this.freteInfoAux = true;
      this.freteInfo = result;
      this.statesProvider.getStatesById(this.freteInfo.properties[0].ufId).then((result) => {
        this.freteInfo.properties[0].ufAbr = result["abbreviation"];
      });
      this.statesProvider.getStatesById(this.freteInfo.frete[0].ufVeiculoId).then((result) => {
        this.freteInfo.frete[0].ufVeiculoAbr = result["abbreviation"];
      });
      if(this.freteInfo.frete[0].reboque.length > 0){
        this.reboque = true;
        this.freteInfo.frete[0].reboque.forEach((element) =>{
          this.statesProvider.getStatesById(element.estadoVeiculoId).then((result) => {
            element.uf_veiculoAbr = result["abbreviation"];
          });
        })
      }
    }, () => {});
  }

  changeEndereco(control){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.persistInformation = false;
    modalRef.componentInstance.destinatarios = this.destinatarios;
    if (control == "retirada") {
      modalRef.componentInstance.retirada = true;
    }
    if (control == "recebimento") {
      modalRef.componentInstance.recebimento = true;
    }
    modalRef.result.then((result) => {
      if (control == "retirada") {
        this.receivingAddressAux = true;
        this.receivingAddress = result;
      }
      if (control == "recebimento") {
        this.deliveryAddressAux = true;
        this.deliveryAddress = result;
      }
    }, () => {
    });
  }

  editEndereco(dados, control){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
    };
    const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.persistInformation = false;
    modalRef.componentInstance.destinatarios = this.destinatarios;
    modalRef.componentInstance.destExistente = dados;
    modalRef.componentInstance.editar = true;
    if(control == "retirada"){modalRef.componentInstance.retirada = true;}
    if(control == "recebimento"){modalRef.componentInstance.recebimento = true;}
    modalRef.result.then((resp) => {
        if(control == 'retirada'){
          this.receivingAddressAux = true;
          this.receivingAddress = resp;
        }
        if(control == 'recebimento'){
          this.deliveryAddressAux = true;
          this.deliveryAddress = resp;
        }
    }, () => {
    });
  }

  deleteEndereco(control){
    if(control == 'retirada'){
      this.receivingAddressAux = false;
      this.receivingAddress = null;
    }
    if(control == 'recebimento'){
      this.deliveryAddressAux = false;
      this.deliveryAddress = null;
    }
  }

  deleteTransportadora(){
    this.freteInfoAux = false;
    this.freteInfo = null;
  }

//seta os endereços para as funções acima
  checkBoxRetirada(a) {
    this.returnedArray = [];
    this.contentArray = [];
    this.destinatarios[a].enderecos.forEach((element) => {
      this.contentArray.push({
        endereco: element,
        cpfCnpj: this.destinatarios[a].cpfCnpj,
        nome: this.destinatarios[a].nome,
      });
    });
    this.returnedArray = this.contentArray.slice(0, 5);
  }

  //paginação da função acima
  pageChanged(event): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.contentArray.slice(startItem, endItem);
  }

  setNFeDraft(id: any){
    this.descricoesSelect.emissor = false;
    this.descricoesSelect.cliente = false;
    this.descricoesSelect.natureza = false;
    this.enviandoRequisicao = true;
    this.rascunhosProvider.getDraftById(id).then((result: any) => {
      if (!this.editDraft) this.fromDraft = true;
      this.nfe_json = JSON.parse(result.json);
      console.log(this.nfe_json);
      this.fillNfeForms();
      this.enviandoRequisicao = false;
    }).catch((result) => {
      this.enviandoRequisicao = false;
      console.log(result);
    });
  }

  handleStepChange(event){
    if (this.naturezaSelecionada.finalidade != 'NF-e complementar'){
      if ((event.selectedIndex == 6 && this.draft) || (event.selectedIndex == 7 && !this.draft)){
        this.calculaValorDoPagamento();
      }
    }
  }

  checkIfBeneficioFiscal(i){
    let csts = ['20', '40', '41', '50', '51', '90'];
    return csts.includes(this.fifthFormGroup.controls[i].controls.SituacaoTributaria.value);
  }

  changeForms(checked) {
    if (!checked){
      this.draftForm.get('draft').setValue('');
    }
    this.setForms();
    this.selectEmitter(0);
  }
}

class Pessoa {
  id: number;
  cpfCnpj: string;
  arrayKey: any;
  nome: string;
  email: string;
  regimeTributario: any;
  enderecos?: Endereco[];
  serie?: string;
  nNF?: string;
  isShared?: boolean;
}

class Pessoa2 {
  id: any;
  cpfCNPJ: any;
  companyId: any;
  name: any;
  addressDTORespComp: {
    id: any;
    telephone: any;
    email: any;
    street: any;
    houseNumber: any;
    neighborhood: any;
    complement: any;
    cep: any;
    countryDTORespComp: {
      id: any;
      name: any;
      abbreviation: any;
      stateDTORespComp: {
        id: any;
        name: any;
        abbreviation: any;
        cityDTORespComp: {
          id: any;
          name: any;
        };
      };
    };
  };
  certificateDTORespComp: null;
}

class Endereco {
  id: number;
  serialNumber: any;
  dfeNumber: any;
  descricao: string;
  inscricaoEstadual?: any;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  ie: any;
  ieType: any;
  bairro?: string;
  cep?: string;
  telefone?: string;
  uf?: string;
  codigoMunicipio?: string;
  municipio?: any;
  pais: string;
  municipioName: any;
  paisName: string;
  ufName: string;
}

class Natureza {
  id: any;
  descricao: string;
  finalidade_id: string;
  tipoNum: number;
  tipo?: string;
  finalidade?: string;
  isShared?: boolean;
}

class Produto {
  id: number;
  codigo: string;
  description: string;
  ncm: number;
  unit: string;
  barcode: null;
  isShared?: boolean;
}

class DataSourceMain {
  id: number;
  produto: string;
  ncm: number;
}

class jsonNfe {
  description: string;
  fromDate: string;
  ide: {
    Uf: any,
    NaturezaOperacao: any,
    Serie: any,
    NumeroNfe: any,
    DataHoraEmissao: any,
    DataHoraSaidaEntrada: any,
    TipoOperacao: any,
    LocalDestinoOperacao: any,
    CodigoMunicipioFatoGerador: any,
    FormatoImpressaoDanfe: any,
    TipoEmissao: any,
    FinalidadeEmissao: any,
    VersaoProcesso: any
  };
  nFref: string;
  Emitente: {
    Documento: any,
    NomeRazaoSocial: any,
    NomeFantasia: any,
    Logradouro: any,
    Numero: any,
    Complemento: any,
    Bairro: any,
    CodigoIbgeMunicipio: any,
    NomeMunicipio: any,
    CodigoPais: any,
    NomePais: any,
    Uf: any,
    Cep: any,
    Telefone: any,
    InscricaoEstadual: any,
    CodigoRegimeTributario: any,
    Cnae: any,
    InscricaoMunicipal: any,
    InscricaoEstadualSubstitutoTributario: any
  };
  Destinatario: {
    Documento: any,
    NomeRazaoSocial: any,
    Logradouro: any,
    Numero: any,
    Bairro: any,
    CodigoIbgeMunicipio: any,
    IndicadorInscricaoEstadual: any,
    NomeMunicipio: any,
    Uf: any,
    Cep: any,
    Telefone: any,
    InscricaoEstadual: any,
    Email: any
  };
  retirada: {
    cNPJcPF: any;
    xLgr: any;
    nro: any;
    xCpl: any;
    xBairro: any;
    cMun: any;
    xMun: any;
    uF: any;
  };
  entrega: {
    cNPJcPF: any;
    xLgr: any;
    nro: any;
    xCpl: any;
    xBairro: any;
    cMun: any;
    xMun: any;
    uF: any;
  };
  transp: {
    modFrete: any;
    transporta: {
      cNPJcPF: any;
      xNome: any;
      iE: any;
      xEnder: any;
      xMun: any;
      uF: any;
    };
  };
  veicTransp: {
    placa: any;
    uF: any;
  };
  fat: {
    nFat: any;
    vOrig: any;
    vDesc: any;
    vLiq: any;
  };
  dup: any;

  DetalhamentoPagamento: any;

  infAdic: any;
}
