import {DuplicatesDTOResponse} from "../dfe/DuplicatesDTOResponse";
import {Duplicatas} from "../../Duplicatas";
import * as moment from "moment";

export class DuplicatesMapper {

  public arrayToClientModel(apiModel: DuplicatesDTOResponse[]): Duplicatas[] {

    let duplicatas: Duplicatas[] = [];
    apiModel.forEach((resultBack) => {
      duplicatas.push(this.serverModelToClientModel(resultBack));
    });

    return duplicatas;
  }

  public arrayToServerModel(apiModel): DuplicatesDTOResponse[] {
    let duplicatas: DuplicatesDTOResponse[] = [];
    apiModel.forEach((resultBack) => {
      duplicatas.push(this.clientModelToServerModel(resultBack));
    });

    return duplicatas;
  }

  public serverModelToClientModel(apiModel: DuplicatesDTOResponse): Duplicatas {

    const duplicata = new Duplicatas(); // your Person constructor

    if (apiModel != null) {
      // map each property
      duplicata.id = apiModel.id;
      duplicata.vencimento = this.retornaData(apiModel.expiration);
      duplicata.valor = ((+apiModel.value).toFixed(2)).toString();
      duplicata.numero = apiModel.number;
      if(apiModel.isPaid == undefined){
        duplicata.escriturada = apiModel.paid;
      }else{
        duplicata.escriturada = apiModel.isPaid;
      }
    }

    return duplicata; // and return YOUR model
  }


  public clientModelToServerModel(apiModel: Duplicatas): DuplicatesDTOResponse {

    const duplicata = new DuplicatesDTOResponse(); // your Person constructor

    if (apiModel != null) {
      // map each property
      duplicata.id = apiModel.id;
      duplicata.expiration = this.retornaData(apiModel.vencimento);
      duplicata.value = apiModel.valor;
      duplicata.number = apiModel.numero;
      // duplicata.isPaid = apiModel.escriturada;
      duplicata.paid = apiModel.escriturada;
    }

    return duplicata; // and return YOUR model
  }

  retornaData(data: string) {
    let dataFormated = data.split("T");
    return (dataFormated[0]);
  }

}
