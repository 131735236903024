import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";

@Injectable()
export class UnitProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }
  apiBase: string = "";

  setApiBase() {
    this.apiBase = `/invoice-issuer/v1/unit/shared/${localStorage.getItem('userId')}`;
  }

  postUnit(unit) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase, unit, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }

  getUnit() {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  putUnit(unit) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase, unit, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }

  deleteUnit(id) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      //console.logrow.id);
      this.http.delete(environment.apiLogin + this.apiBase + `/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }
}
