import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DestinatariosComponent} from './destinatarios/destinatarios.component';
import {EmitentesComponent} from './emitentes/emitentes.component';
import {ProdutosComponent} from './produtos/produtos.component';
import {UnidadesComponent} from './unidades/unidades.component';
import {ImpostosComponent} from './impostos/impostos.component';
import {InfosAdicionaisComponent} from './infos-adicionais/infos-adicionais.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import {AggregateService, ColumnChooserService, GridAllModule, GridModule, GroupService, ResizeService} from '@syncfusion/ej2-angular-grids';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {NaturezaopComponent} from './naturezaop/naturezaop.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CadastroUnidadeComponent} from './unidades/cadastro-unidade/cadastro-unidade.component';
import {CadastroProdutoComponent} from './produtos/cadastro-produto/cadastro-produto.component';
import {CadastroEmitenteComponent} from './emitentes/cadastro-emitente/cadastro-emitente.component';
import {CadastroImoveisComponent} from './cadastro-imoveis/cadastro-imoveis.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CadastroNaturezaopComponent} from './naturezaop/cadastro-naturezaop/cadastro-naturezaop.component';
import {CadastroDestinatarioComponent} from './destinatarios/cadastro-destinatario/cadastro-destinatario.component';
import {IMaskModule} from 'angular-imask';
import {CepService} from '../../../cep.service';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxCurrencyModule} from 'ngx-currency';
import {CadastroImpostosComponent} from './impostos/cadastro-impostos/cadastro-impostos.component';
import {CadastroInfosAdicionaisComponent} from './infos-adicionais/cadastro-infos-adicionais/cadastro-infos-adicionais.component';
import {RascunhosComponent} from './rascunhos/rascunhos.component';
import {CadastroRascunhosComponent} from './rascunhos/cadastro-rascunhos/cadastro-rascunhos.component';

const routes: Routes = [
  {path: 'cadastros/', redirectTo: 'cadastros/emitentes', pathMatch: 'full'},
  {path: 'cadastros/emitentes', component: EmitentesComponent},
  {path: 'cadastros/destinatarios', component: DestinatariosComponent},
  {path: 'cadastros/produtos', component: ProdutosComponent},
  {path: 'cadastros/unidades', component: UnidadesComponent},
  {path: 'cadastros/naturezaop', component: NaturezaopComponent},
  {path: 'cadastros/impostos', component: ImpostosComponent},
  {path: 'cadastros/infosAdicionais', component: InfosAdicionaisComponent},
  {path: 'cadastros/rascunhos', component: RascunhosComponent},
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        GridAllModule,
        GridModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        NgxMatSelectSearchModule,
        IMaskModule,
        NgbCollapseModule,
        NgxCurrencyModule,
    ],
  providers: [
    AggregateService,
    ColumnChooserService,
    GroupService,
    ResizeService,
    CepService
  ],
  declarations: [
    EmitentesComponent,
    DestinatariosComponent,
    ProdutosComponent,
    UnidadesComponent,
    ImpostosComponent,
    InfosAdicionaisComponent,
    NaturezaopComponent,
    CadastroUnidadeComponent,
    CadastroProdutoComponent,
    CadastroEmitenteComponent,
    CadastroImoveisComponent,
    CadastroNaturezaopComponent,
    CadastroDestinatarioComponent,
    CadastroImpostosComponent,
    CadastroInfosAdicionaisComponent,
    RascunhosComponent,
    CadastroRascunhosComponent,
  ],
  exports: [
  ],
  entryComponents:[
    CadastroUnidadeComponent,
    CadastroProdutoComponent,
    CadastroEmitenteComponent,
    CadastroImoveisComponent,
    CadastroDestinatarioComponent,
    CadastroNaturezaopComponent,
    CadastroImpostosComponent,
    CadastroInfosAdicionaisComponent,
    CadastroRascunhosComponent,
  ]
})

export class CadastrosModule {}
