import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Snackbar } from "../../util/snackbar";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerComponent } from "src/app/routes/notafiscal/pdf-viewer/pdf-viewer.component";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class ConsultaPdfXml {

  constructor(public http: HttpClient, public snackbar: Snackbar, public modalService: NgbModal, public oauthService: OAuthService) {
  }

  getXml(key) {
    return new Promise((resolve, reject) => {
      this.http.post('https://apinfe.farmcont.com.br/downloadXML-emissor.php', key).subscribe((result: any) => {
        resolve(result);
      }, (error) => {
        reject(error);
        this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
      });
    });
  }

  getPdf(xml) {
    return new Promise((resolve, reject) => {
      this.http.post('https://apinfe.farmcont.com.br/viewpdfapp.php', xml)
        .subscribe((result: any) => {
          let url = "https://apinfe.farmcont.com.br/pdf/" + result.pdf;
          resolve(url);
          window.open(url);
        },
          (error) => {
            reject(error);
            this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
          });
    });
  }

  getPdfModal(xml) {
    return new Promise((resolve, reject) => {
      this.http.post('https://apinfe.farmcont.com.br/viewpdfapp.php', xml)
        .subscribe((result: any) => {
          let url = "https://apinfe.farmcont.com.br/pdf/" + result.pdf;
          resolve(url);
          const modalOptions: NgbModalOptions = {};
          modalOptions.windowClass = 'custom-css-modal'
          const modalRef = this.modalService.open(PdfViewerComponent, modalOptions);
          modalRef.componentInstance.pdfUrl = url;
        },
          (error) => {
            reject(error);
            this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
          });
    });
  }

}