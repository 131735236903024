import {LoginComponent} from './pages/login/login.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {LayoutNotaFiscalComponent} from '../layoutNotaFiscal/layout.component';
import {AuthGuardNotaFiscalService as AuthGuardNotaFiscal} from '../../service/auth-guard-notafiscal';
import {AuthGuardService as AuthGuard} from '../../service/auth-guard';
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";

export const routes = [
 {

    path: '',
    canActivate: [AuthGuardNotaFiscal],
    component: LayoutNotaFiscalComponent,
    children: [
      {path: '', redirectTo: 'notafiscal', pathMatch: 'full'},
      {path: 'notafiscal', loadChildren: () => import('./notafiscal/notafiscal.module').then(m => m.NotaFiscalModule)},
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutNotaFiscalComponent,
    children: [
      {path: '', redirectTo: 'notafiscal', pathMatch: 'full'},
      {path: 'notafiscal', loadChildren: () => import('./notafiscal/notafiscal.module').then(m => m.NotaFiscalModule)},
    ]
  },

  // Not lazy-loaded routes
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'reset-password-new/:token', component: ResetPasswordComponent},
  {path: 'login', component: LoginComponent},
  // {path: 'register', component: RegisterComponent},
  // {path: 'recover', component: RecoverComponent},
  // {path: 'lock', component: LockComponent},
  {path: 'manutencao', component: MaintenanceComponent},
  // {path: '404', component: Error404Component},
  // {path: '500', component: Error500Component},

  // Not found
  {path: '**', redirectTo: 'login'}

];

