import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'src/app/shared/shared.module';
import {CadastrosModule} from './cadastros/cadastros.module';
import {NovaNotaFiscalComponent} from './emitirNovaNotaFiscal/emitirNovaNotaFiscal';
import {NotasFiscaisComponent} from './notasFiscais/notasFiscais.component';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {BrMaskerModule} from 'brmasker-ionic-3';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
//import {ReceiverProvider} from ''
import {AggregateService, ColumnChooserService, GridAllModule, GridModule, GroupService, ResizeService} from '@syncfusion/ej2-angular-grids';

import {OverlayModule} from '@angular/cdk/overlay';
import {IMaskModule} from 'angular-imask';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {DropDownListModule} from '@syncfusion/ej2-angular-dropdowns';
import {EscolhaDePropComponent} from './emitirNovaNotaFiscal/escolha-de-prop/escolha-de-prop.component';
import {ListProdutorComponent} from '../contador/list-produtor/list-produtor.component';
import {CancelarNotaFiscalComponent} from './notasFiscais/cancelar-nota-fiscal/cancelar-nota-fiscal.component';
import {EditarNotaFiscalComponent} from './notasFiscais/editar-nota-fiscal/editar-nota-fiscal.component';
import {SelectProdutorComponent} from './select-produtor/select-produtor.component';
import {ModalEventsComponent} from './notasFiscais/modal-events/modal-events.component';
import {PipesModule} from '../../../util/pipes/pipes.module';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

const routes: Routes = [
    {path: '', redirectTo: 'cadastros/emitentes', pathMatch: 'full'},
    {path: 'notasFiscais', component: NotasFiscaisComponent},
    {path: 'contador', component: ListProdutorComponent},
    {path: 'emitir-nova-nota-fiscal', component: NovaNotaFiscalComponent},
    {path: 'cadastros', loadChildren: () => import('./cadastros/cadastros.module').then(m => m.CadastrosModule)},

];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
		CadastrosModule,
		BrMaskerModule,
		SharedModule,
		A11yModule,
		CdkStepperModule,
		NgxCurrencyModule,
		IMaskModule,
		GridAllModule,
		CdkTableModule,
		CdkTreeModule,
		DragDropModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatStepperModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,
		OverlayModule,
		PortalModule,
		ScrollingModule,
		GridModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		DropDownListModule,
		PipesModule,
		NgxMatSelectSearchModule
	],
    providers: [
        AggregateService,
        ColumnChooserService,
        GroupService,
        ResizeService,
    ],
    declarations: [
        NotasFiscaisComponent,
        NovaNotaFiscalComponent,
        EscolhaDePropComponent,
        ListProdutorComponent,
        CancelarNotaFiscalComponent,
        EditarNotaFiscalComponent,
        SelectProdutorComponent,
        ModalEventsComponent,
    ],
    exports: [],
    entryComponents:[
        CancelarNotaFiscalComponent,
        EditarNotaFiscalComponent,
        SelectProdutorComponent,
        ModalEventsComponent,
      ]
})

export class NotaFiscalModule {}
