import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import Swal from "sweetalert2";
import { ReceiverProvider } from "src/providers/nfe/receiver";

@Component({
  selector: "app-editar-nota-fiscal",
  templateUrl: "./editar-nota-fiscal.component.html",
  styleUrls: ["./editar-nota-fiscal.component.scss"],
})
export class EditarNotaFiscalComponent implements OnInit {
  editar = false;
  @ViewChild("trigger", { static: false }) trigger: MatAutocompleteTrigger;
  editarForm;
  chave;
  enviando : boolean = false;
  enviado : boolean = false;
  constructor(public activeModal: NgbActiveModal, public fb: FormBuilder, public receiverProvider: ReceiverProvider) {}

  ngOnInit() {
    this.editarForm = this.fb.group({
      chave: ["", Validators.required],
      motivo: ["", Validators.required],
    });
    if (this.chave) {
      this.editarForm.controls.chave.setValue(this.chave);
    }
  }
  cancelarSubmit(){
    this.enviado = true;
    if(this.editarForm.invalid){
      return;
    }
    this.enviando = true;
    let value = {
      key: this.editarForm.controls.chave.value,
      correction: this.editarForm.controls.motivo.value
    }
    this.receiverProvider.editNfe(value).then((result : any) => {
      this.enviando = false;
      if(result.status == "aprovado"){
        Swal.fire({
          title: 'Sucesso!',
          text: "Carta de Correção emitida com sucesso",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        }).then(result => {
          if(result.isConfirmed){
            this.activeModal.close();
          }
        })
      }
      else{
        Swal.fire({
          title: 'Erro!',
          text: "Carta de Correção não pôde ser emitida",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
        }).then(result => {
          if(result.isConfirmed){
            this.activeModal.close();
          }
        })
      }
      
    }).catch(err => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }
}
