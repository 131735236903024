import {Component, OnInit} from '@angular/core';
import {Maintenance} from "../../../../model/dto/status/Maintenance";
import {Incident} from "../../../../model/dto/status/Incident";
import {MaintenanceProvider} from "../../../../providers/status/maintenanceProvider";
import {DomSanitizer} from '@angular/platform-browser';
import Swal from "sweetalert2";

@Component({
  selector: 'app-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.scss']
})
export class ServiceStatusComponent implements OnInit {

  maintenance: Maintenance[] = [];
  newsObj = null;

  constructor(private maintenanceProvider: MaintenanceProvider, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getStatus();
    this.getIncidents();
  }

  warningStatus() {
    return JSON.parse(localStorage.getItem('warnings'));
  }

  toggleWarnings() {
    const warnings = localStorage.getItem('warnings') === 'true';
    localStorage.setItem('warnings', warnings ? 'false' : 'true');
  }

  getOverallData(){
    this.maintenanceProvider.getOverallData().then((value: any) => {
    });
  }

  getIncidents(){
    this.maintenanceProvider.getAllIncident().then((value: Incident[]) => {
      let incident = value.filter(x => x.name.includes("[NEWS_EMISSOR_WEB]"));
      let version = value.filter(x => x.name.includes("[VERSION_EMISSOR_WEB]"));


      if(incident.length == 0) return;
      this.newsObj = {
        title: incident[0].name.split("[NEWS_EMISSOR_WEB]")[1].replace(/_/g, " "),
        description: incident[0].updates[0].message.split("§")[0],
        link: incident[0].updates[0].message.split("§")[1]
      }
      this.newsObj.link != undefined ? this.newsObj.link = this.domSanitizer.bypassSecurityTrustUrl(this.newsObj.link) : this.newsObj.link = null;
      if(version&&version[0].updates[0].message != require('../../../../../package.json').version){
        this.fireUpdateSwal();
      }
    });
  }

  fireUpdateSwal() {
    Swal.fire({
      title: "Atualização Disponível!",
      text: "Recomendamos atualizar para garantir o melhor desempenho e evitar possíveis problemas no aplicativo.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      cancelButtonText: 'Ignorar',
      confirmButtonText: "Atualizar"
    }).then(result => {
      if (result.isConfirmed) {
        if ('caches' in window) {
          caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => caches.delete(cacheName));
          });
        }
        window.location.reload();
      }
    });
  }

  getStatus() {
    this.maintenanceProvider.getAllMaintenance().then((value: Maintenance[]) => {
      this.maintenance = value.filter(x => x.name.includes("[MAINTENANCE_EMISSOR_WEB]")).map((x) => {
        x.name = x.name.split("[MAINTENANCE_EMISSOR_WEB]")[1].replace(/_/g, " ");
        return x;
      })
    });
  }
}
