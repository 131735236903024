import {Doc} from "./Doc";
import {Payment} from "./Payment";
import {BankDTOResponse} from "../BankDTOResponse";
import {GrouperDTOResponse} from "../GrouperDTOResponse";
import {Account} from "./Account";


export class Bookkeeping {
  id: number;
  companyId: number;
  amountPaid: number;
  amountPaidCalc: number;
  amountPaidFormated: string;
  balance: number;
  planPaymentId: number;
  historic: string;
  account: Account;
  grouper: GrouperDTOResponse[];
  paymentDate: string;
  paymentDateFormated: string;
  postingReleaseType: string;
  bankAccount: BankDTOResponse;
  doc: Doc;
  payment: Payment;
  digital: boolean;
  incoming: string;
  outlay: string;
  postingType: string;
  balanceFormated: string;
  month: string;
  incomingNumber: number;
  outlayNumber: number;

}
