import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages/pages.module';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTabsModule
} from '@angular/material';


import {menuNotaFiscal} from './menu';
import {routes} from './routes';
import {TranslatorService} from '../core/translator/translator.service';
import {GridModule} from '@syncfusion/ej2-angular-grids';


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    PagesModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatIconModule,
    GridModule,
  ],
  exports: [
    RouterModule
  ],

})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    this.menuService.menuItems = [];
    this.menuService.menuItemsContador = [];
    this.menuService.menuItemsNotaFiscal = [];
    this.menuService.menuItemsConsultor = [];
    menuService.addMenu(menuNotaFiscal, 3);
  }

}
