import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
  transform(value: any, groupByKey: string) {
    const events: any[] = [];
    const groupedElements: any = {};

    value.forEach((obj: any) => {
      if (!(obj[groupByKey] in groupedElements)) {
        groupedElements[obj[groupByKey]] = [];
      }
      groupedElements[obj[groupByKey]].push(obj);
    });

    let balance = 0;
    for (let prop in groupedElements) {
      balance = balance + groupedElements[prop].reduce((total, valor) => total + valor.amountPaid, 0);
      if (groupedElements.hasOwnProperty(prop)) {
        events.push({
          key: prop,
          balance: balance,
          month: moment.tz(groupedElements[prop][0].paymentDate, 'America/Sao_Paulo').format('MMYYYY'),
          nature: this.returnBalanceNature(balance),
          incoming: this.returnAmount(groupedElements[prop], 'REVENUE'),
          outlay: this.returnAmount(groupedElements[prop], 'EXPENSE'),
          list: groupedElements[prop]
        });
      }
    }

    return events;
  }

  returnAmount(elements, type) {
    return elements.filter(obj => obj.account.featureType === type).reduce((total, valor) => total + valor.amountPaid, 0);
  }

  returnBalanceNature(saldo: number) {
    if (saldo >= 0) {
      return 'P';
    } else {
      return 'N';
    }
  }

}
