import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {Bank} from "../../../model/basicos/Bank";
import {ContaBancaria} from "../../../model/ContaBancaria";
import {BankDTOResponse} from "../../../model/dto/BankDTOResponse";

@Injectable()
export class BankAccountProvider {

  apiBank: string = "/main-records/v1/bank_account/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllBanksAccounts() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBank  + 'by_company/' + localStorage.getItem('idEmpresa'),
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: BankDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getBanksAccountsById(idAccount: number) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBank + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: BankDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postBankAccount(account: ContaBancaria) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBank, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: BankDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putBankAccount(account: ContaBancaria) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBank, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: BankDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteBankAccount(idAccount: number) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBank + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: BankDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
