import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Snackbar} from '../../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../../util/error';
import Swal from 'sweetalert2';
import {RascunhosProvider} from '../../../../../../providers/nfe/rascunhos';
import {CheckPermission} from "../../../../../../service/checkPermission";

@Component({
  selector: 'app-cadastro-rascunhos',
  templateUrl: './cadastro-rascunhos.component.html',
  styleUrls: ['./cadastro-rascunhos.component.scss']
})
export class CadastroRascunhosComponent implements OnInit {

  @Input() enableClearOnEscapePressed = true;

  public form: FormGroup;

  public nfeJson: any;
  public description: any;
  public id: any;
  public isShared: boolean = false;

  public enviando: boolean = false;
  public editar: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackbar: Snackbar,
    public erroUtil: ErrorUtil,
    public checkPermission: CheckPermission,
    public rascunhosProvider: RascunhosProvider
  ) { }

  ngOnInit() {
    try {
      this.form = this.fb.group({
        description: ['', Validators.required],
        property: ['']
      });

    } catch (error) {
      console.error(error)
    }
    if (this.editar) {
      this.fillRascunhos();
    }
  }


  newRascunhos() {
    this.enviando = true;
    if (!this.form.get('description').value) {
      Swal.fire({
        title: 'Falha!',
        text: 'A descrição é obrigatória',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      this.enviando = false;
      return;
    }
    let obj = {
      description: this.form.get('description').value.trim(),
      json: this.nfeJson,
      isShared: this.isShared
    }

    if (this.form.valid) {
      this.enviando = true;
      this.rascunhosProvider.postDraft(obj).then((result) => {
        this.activeModal.close('success');
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        this.enviando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  editRascunhos() {
    let obj = {
      description: this.form.get('description').value.trim(),
      json: this.nfeJson,
      isShared: this.isShared,
      id: this.id
    }

    this.enviando = true;
    if (this.form.valid) {
      this.enviando = true;
      this.rascunhosProvider.putDraft(obj).then((result) => {
        this.activeModal.close('success');
        this.enviando = false;
      }).catch(err => {
        this.enviando = false;
        this.enviando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  fillRascunhos() {
    this.form.get('description').setValue(this.description);
  }
}
