import {Injectable} from "@angular/core";
import {Bank} from "../model/basicos/Bank";
import {BanksProvider} from "../providers/basicos/banks";

@Injectable()
export class Banks {

  banks: Bank[];

  constructor(public banksProvider: BanksProvider) {
  }

  setBanks() {
    if (localStorage.getItem('bancos') != null) {
      this.banks = JSON.parse(localStorage.getItem("bancos"));
    } else {
      this.banksProvider.getAllBanks().then((bank: Bank[]) => {
        this.banks = bank;
        localStorage.setItem('bancos', JSON.stringify(this.banks));
      });
    }
  }

}
