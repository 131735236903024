import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {PessoaFiscalDTO} from "../../../model/dto/PessoaFiscalDTO";

@Injectable()
export class ExplorationProvider {

  apiExploration: string = "/main-records/v1/exploration/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getByCompany() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiExploration  + 'by_company/' + localStorage.getItem('idEmpresa'),
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getByProperty(id) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiExploration  + 'by_property/' + localStorage.getItem('idEmpresa') + '/' + id,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getByMember(id) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiExploration  + 'by_member/' + localStorage.getItem('idEmpresa') + '/' + id,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }


  postExploration(exploration) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiExploration + 'in_batch', exploration, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


  putExploration(exploration: any) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiExploration + 'in_batch', exploration, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delExploration(exploration: any) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiExploration + localStorage.getItem('idEmpresa') + '/' + exploration.id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

}
