import {Pipe, PipeTransform} from "@angular/core";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
@Pipe({ name: 'valueMaskFormat' })
export class ValueFormat implements PipeTransform {

  constructor(private brMasker: BrMaskerIonicServices3) {
  }

  transform(valorTotal: any): any {
    if (valorTotal != undefined) {
      return this.brMasker.writeValueMoney(valorTotal.toString(), {
        decimal: 2,
        decimalCaracter: ",",
        len: 0,
        mask: "",
        numberAndTousand: false,
        percent: false,
        person: false,
        phone: false,
        thousand: ".",
        type: undefined,
        userCaracters: false,
        money: true
      });
    }
  }
}
