export class Imovel {
  id: number;
  ie: number;
  ieType: string;
  propertyCode:  string;
  newerPropertyCode: string;
  name:  string;
  stateRegistration: string;
  explorationType: string;
  serialNumber: number;
  dfeNumber: number;
  currency:  string;
  itrCode:  string;
  caepf:  string;
  stateId;
  companyId: number;
  street: string;
  houseNumber:string;
  neighborhood: string;
  complement: string;
  cep: string;
  countryId: number;
  ufId:  number;
  cityId: number;
  default: boolean;
}
