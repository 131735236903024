import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {PdfViewerComponent} from '../../pdf-viewer/pdf-viewer.component';

@Component({
  selector: 'app-modal-events',
  templateUrl: './modal-events.component.html',
  styleUrls: ['./modal-events.component.scss']
})
export class ModalEventsComponent implements OnInit {

  public events: any;
  public documento: any;
  public carregando: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public fb: FormBuilder
  ) { }

  ngOnInit() { }

  getPdf(url, type){
    return new Promise ((resolve, reject) => {
      switch (type) {
        case 'xml': {
          this.getXml({url: url});
          break;
        }
        case 'pdf': {
          resolve(url);
          window.open(url);
          break;
        }
      }
    })
  }

  getXml(obj) {
    this.carregando = true;
    fetch(obj.url).then(response => response.blob()).then(blob => {
      var blobUrl = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = blobUrl;
      a.download = `${this.documento.key}.xml`;
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
      this.carregando = false;
    }).catch(error => {
        this.carregando = false;
        console.error('Erro ao baixar aquivo.', error);
      });
  }

  getPdfModal(url){
    return new Promise ((resolve, reject) => {
      resolve(url);
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme custom-css-modal' : 'custom-css-modal'
      };
      const modalRef = this.modalService.open(PdfViewerComponent, modalOptions);
      modalRef.componentInstance.pdfUrl = url;
    })
  }

}
