import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from 'rxjs/operators';
import {CEP} from "../model/basicos/CEP";

@Injectable()
export class CepService {

  constructor(private http:HttpClient) { }

  buscar(cep:string){
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`)
      .pipe(
        map(response => this.converterRespostaParaCep(response.data))
      );
  }

  private converterRespostaParaCep(cepNaResposta): CEP{
    let cep = new CEP();
    cep.cep = cepNaResposta.cep;
    cep.street = cepNaResposta.logradouro;
    cep.neighborhood = cepNaResposta.bairro;
    cep.city = cepNaResposta.localidade;
    cep.state = cepNaResposta.uf;
    return cep;
  }
}
