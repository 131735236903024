import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatAutocompleteTrigger, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {InfosAdicionaisProvider} from 'src/providers/nfe/infosAdicionais';
import Swal from 'sweetalert2';
import {CadastroInfosAdicionaisComponent} from './cadastro-infos-adicionais/cadastro-infos-adicionais.component';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-infos-adicionais',
  templateUrl: './infos-adicionais.component.html',
  styleUrls: ['./infos-adicionais.component.scss']
})
export class InfosAdicionaisComponent implements OnInit {

  displayedColumns = ['editar', 'excluir', 'isShared','description'];
  public carregando: Boolean = false;
  public infosAdicionais: any = [];
  dataSource: MatTableDataSource<any>;
  formGroup: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('trigger', { static: false }) trigger: MatAutocompleteTrigger;

  constructor(
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public infosAdicionaisProvider: InfosAdicionaisProvider,
    public checkPermission: CheckPermission
  ) { }

  ngOnInit() {
    try {
      this.generateTable();
      this.formGroup = this.formBuilder.group({
        description: ['', Validators.required],
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  generateTable() {
    this.carregando = true;
    this.infosAdicionais = [];
    this.getInfosAdicionais().then(() => {
      this.loadTable();
      this.carregando = false;
    });
  }

  getInfosAdicionais() {
    return new Promise((resolve, reject) => {
      this.infosAdicionaisProvider.getInfosAdicionais().then((result : any[]) => {
        this.infosAdicionais = result.sort((a, b) => {
          if (a.isShared === b.isShared) {
            return a.description.localeCompare(b.description);
          }
          return a.isShared ? -1 : 1;
        });
        resolve(true);
      }).catch(err => {
        this.carregando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
     });
    });
  }

  loadTable() {
    this.dataSource = new MatTableDataSource<any>(this.infosAdicionais);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  newInfosAdicionais() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      const modalRef = this.modalService.open(CadastroInfosAdicionaisComponent, modalOptions);
      modalRef.result.then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          text: "As informações adicionais foram adicionadas!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        })
        this.generateTable();
      }, () => {
        this.generateTable();
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: "Não foi possivel adicionar as informações adicionais",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
    }
  }

  editInfosAdicionais(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try{
      const modalRef = this.modalService.open(CadastroInfosAdicionaisComponent, modalOptions);
      modalRef.componentInstance.editar = true;
      modalRef.componentInstance.infosAdicionaisExistente = row;
      modalRef.result.then((result) => {
        Swal.fire({
          title: 'Sucesso!',
          text: "As informações adicionais foram foi editadas!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        })
        this.generateTable();
      }, () => {
      });
    }
    catch (error){
      Swal.fire({
        title: 'Erro!',
        text: "Não foi possivel editar as informações adicionais",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
    }
  }

  deleteInfosAdicionais(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível deletar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar?',
      text: "Não há como reverter isso!",
      reverseButtons: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#f05050',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando = true;
        this.infosAdicionaisProvider.deleteInfosAdicionais(row.id).then((result) => {
          this.generateTable();
          Swal.fire(
            'Sucesso!',
            'As informações adicionais foram deletadas!',
            'success'
          )
        }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });
      }
    });
  }

}
