import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {PessoaFiscalDTO} from "../../../../../model/dto/PessoaFiscalDTO";
import {ImovelDTO} from "../../../../../model/dto/ImovelDTO";
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {CertificadoProvider} from "../../../../../providers/certificado/certificadoProvider";
import {PropertyProvider} from "../../../../../providers/company/property/property";
import {MemberProvider} from "../../../../../providers/company/member/member";
import {UserProvider} from "../../../../../providers/user/user";
import {ErrorUtil} from "../../../../../util/error";
import {FormBuilder} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {Snackbar} from "../../../../../util/snackbar";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {CadastroDestinatarioComponent} from "./cadastro-destinatario/cadastro-destinatario.component";
//import {CadastroEnderecoComponent} from "./cadastro-endereco/cadastro-endereco.component";
import * as moment from "moment";
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
    selector: 'app-destinatarios',
    templateUrl: './destinatarios.component.html',
    styleUrls: ['./destinatarios.component.scss']
})

export class DestinatariosComponent implements OnInit {
  @ViewChild("paginatorImoveis", {static: false}) paginatorImoveis: MatPaginator;
  @ViewChild("paginator", {static: false}) paginator: MatPaginator;
  @ViewChild('firstTableSort', {static: false}) public firstTableSort: MatSort;
  @ViewChild('secondTableSort', {static: false}) public secondTableSort: MatSort;
  @ViewChild('thirdTableSort', {static: false}) public thirdTableSort: MatSort;
  @ViewChild('certificadoInput', {static: false}) public certificadoButton;

  public displayedColumns = ['editar', 'excluir', 'isShared', 'name', 'cpfCNPJ'];
  public pessoaFiscal;
  public imoveis: ImovelDTO[];
  public dataSource: MatTableDataSource<PessoaFiscalDTO>;
  public dataSourceImovel: MatTableDataSource<ImovelDTO>;
  public erroDestinatario: boolean = false;
  public erroImovel: boolean = false;
  public erro: string;
  public editarCertificado: boolean = false;
  public enviando: boolean[];
  public enviandoRequisicao: boolean = true;
  public farmerUsers: any[];
  public receiver: any[];
  public property: any[];
  public carregando: boolean = false;

  constructor(public http: HttpClient,
              private certificadoProvider: CertificadoProvider,
              private propertyProvider: PropertyProvider,
              private memberProvider: MemberProvider,
              private userProvider: UserProvider,
              public errorUtil: ErrorUtil,
              public fb: FormBuilder,
              public dialog: MatDialog,
              public snackbar: Snackbar,
              public modalService: NgbModal,
              public checkPermission: CheckPermission) {
   // this.getDestinatarios();
    this.getPessoaFiscal();
  }

  ngOnInit() {
  }

//faz um get para receber o destinario
  getPessoaFiscal() {
    this.enviandoRequisicao = true;
    this.userProvider.getAllDestinat().then((pessoas : any[]) => {
      this.pessoaFiscal = pessoas.sort((a, b) => {
        if (a.isShared === b.isShared) {
          return a.name.localeCompare(b.name);
        }
        return a.isShared ? -1 : 1;
      });;
      this.enviando = [];
      //console.logthis.pessoaFiscal)
      this.erroDestinatario = false;
       this.enviandoRequisicao = false;
      this.novaConsultaPessoaFiscal();
    }).catch(err => {
      this.enviandoRequisicao = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }
// renderiza a tabele com as informações do this.pessoaFiscal
  novaConsultaPessoaFiscal() {
    this.dataSource = new MatTableDataSource(this.pessoaFiscal);
    setTimeout(() => {
      this.configOrdenacao();
    });

  }

  configOrdenacao() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id.cnpjCPF':
          return +item.cpfCNPJ;
        default:
          return item[property];
      }
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.firstTableSort;
  }

// chama o componente de cadastro de novo destinatario (CadastroDestinatarioComponent) e exibe mensagem de sucesso
  newDestinatario() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
      const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
      modalRef.result.then((result) => {
        this.getPessoaFiscal();
        Swal.fire({
          title: 'Sucesso!',
          text: "Destinatario adicionado com sucesso!",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        })
        //this.receiver.push(result);
      }, () => {
       // this.getPessoaFiscal();
      });
  }
/* chama componente de cadastro de novo destinatario (CadastroDestinatarioComponent)
    e envia informações de edição
    modalRef.componentInstance.destExistente = row;
    modalRef.componentInstance.editar = true;
    exibe mensagem de sucesso
*/
  editDestinatario(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
   const modalRef = this.modalService.open(CadastroDestinatarioComponent, modalOptions);
    modalRef.componentInstance.destExistente = row;
    modalRef.componentInstance.editar = true;
    modalRef.result.then(() => {
     // this.novaConsultaPessoaFiscal();
     Swal.fire({
      title: 'Sucesso!',
      text: "Destinatario editado com sucesso",
      icon: 'success',
      confirmButtonColor: '#3085d6',
    })
     this.getPessoaFiscal();
    }, () => {
    });
  }
// Exibe swal de confirmação caso o usuario confirme realiza requisição para deletar o destinatario
  deleteDestinatario(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar este destinatario?',
      text: "Não há como reverter isso!",
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.enviandoRequisicao = true;
        this.carregando= true;
        this.userProvider.delDestinat(row).then((result) => {
         //this.generateTable();
         this.getPessoaFiscal();
         Swal.fire(
          'Sucesso!',
         'O destinatario foi deletado com sucesso!',
         'success'
      )
         }).catch(err => {
          this.enviandoRequisicao = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });

      }
    });
  }
//Realiza download do PDF contento informações da tabela (não sei como funciona)
  downloadPdf() {
    var prepare = [];
    this.pessoaFiscal.forEach(e => {
      var tempObj = [];
      tempObj.push(e.id);
      tempObj.push(e.name);
      tempObj.push(e.cpfCNPJ);
      prepare.push(tempObj);
    });
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
          align: 'center'
        })
      }
    }
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(10);
    doc.text(14, 10, 'DESTINATÁRIOS');
    doc.text(175, 10, moment().format('DD/MM/YY HH:mm').toString());
    doc.setLineWidth(0.3);
    doc.line(14, 12, 200, 12);
    doc.autoTable({
      head: [['ID', 'Nome', 'CPF/CNPJ']],
      body: prepare,
      headStyles: {
        fillColor: "#adacac",
        textColor: "#000000"
      },
    });
    addFooters(doc)

    doc.save('destinatarios' + '.pdf');
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}
