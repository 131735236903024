import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {OpnatureDTOResponse} from "../../model/dto/OpnatureDTOResponse";

@Injectable()
export class OpnatureProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }
  apiBase: string = "";

  setApiBase(){
    this.apiBase = `/invoice-issuer/v1/nat-op/shared/${localStorage.getItem('userId')}`;
  }

  postOPNature(opnature) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase, opnature, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  getAllOPNatures() {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }
  
  putOPNature(opnature) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase, opnature, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  delOPNature(id) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + `/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }
}
