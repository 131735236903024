import {Injectable} from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateUtil {

  removeTimeZone(date) {
    // //console.logdate);
    // //console.logmoment(date).format().split('T')[0]);
    return moment(date).format().split('T')[0];

  }

}
