import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {UserProvider} from "../providers/user/user";
import {CheckPermission} from "./checkPermission";

@Injectable()
export class AuthGuardContadorFuncionarioService implements CanActivate {
  private auth: boolean = false;
  private contador: boolean = true;
  private route: ActivatedRouteSnapshot;

  constructor(public router: Router,
              private checkPermission: CheckPermission,
              public oauthService: OAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.oauthService.hasValidAccessToken() || !this.verificaPermissao() || !this.checkGuard(route)) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  checkGuard(route) {
    if(Object.keys(route.data).length === 0) {
      return true
    } else {
      return this.checkPermission.isSuperuser();
    }
  }

  private verificaPermissao() {
    return this.checkPermission.isSuperuser() || this.checkPermission.isAccountantAdmin();
  }
}

@Injectable()
export class AuthGuardContadorService implements CanActivate {
  private auth: boolean = false;
  private contador: boolean = true;

  constructor(public router: Router, public oauthService: OAuthService, private userProvider: UserProvider) {
  }

  canActivate(): boolean {
    if (!this.oauthService.hasValidAccessToken() || !this.verificaPermissao()) {
      this.router.navigate(['login']);
      return false;
    }
      return true;
  }


  private givenAuthorities(claim) {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims[claim];

  }

  private verificaPermissao() {
    let regra = this.givenAuthorities('authorities')[0].authority;
    if (regra == "ROLE_ACCOUNTANT_ADMIN" || regra == "ROLE_ACCOUNTANT_COLLABORATOR") {
      return true;
    } else {
      return false;
    }
  }


  private allowCompany(): boolean {
    // //console.logthis.givenAuthorities('details'));
    let companyId = this.givenAuthorities('details').office.id;
    if (companyId == undefined) {
      return true;
    } else {
      let list = JSON.parse(localStorage.getItem('allow'));
      // //console.loglist.findIndex(val => val == companyId));
      return list.findIndex(val => val == companyId) >= 0;

    }
  }
}
