import 'hammerjs';
/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import './vendor.ts';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const gerensia = window.location.href.includes('gerensia');

let p = platformBrowserDynamic().bootstrapModule(AppModule);
//
// p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
// .catch(err => console.error(err));

p.then(() => {
  (<any>window).appBootstrap && (<any>window).appBootstrap();
  if (environment.production && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then(active =>
      !active && navigator.serviceWorker.register('ngsw-worker.js')).catch(console.error);
  }
}).catch(err => console.error(err));
