import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'dateFormat',
})
export class DatePipe implements PipeTransform {

  transform(value): any {
    return moment(value).format('DD/M/YY');
  }
}

@Pipe({
  name: 'timeFormat',
})
export class TimePipe implements PipeTransform {

  transform(value: any): any {
    return moment(value).format('HH:mm');
  }
}
