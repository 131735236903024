import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment.prod';


@Injectable()
export class RascunhosProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }
  apiBase: string = "";

  setApiBase() {
    this.apiBase = "/invoice-issuer/v1/nfe-draft/shared/" + localStorage.getItem('userId');
  }

  postDraft( rascunhos) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}`, rascunhos, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    })
  }

  getDrafts() {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getDraftById(id) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}/by-id/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putDraft(rascunho) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}`, rascunho, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteDraft(id) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiLogin}${this.apiBase}/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    })
  }



}
