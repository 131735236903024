import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import {MatDialog} from '@angular/material/dialog';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CadastroUnidadeComponent} from './cadastro-unidade/cadastro-unidade.component';
import {UnitProvider} from '../../../../../providers/produto/unidade';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
    selector: 'app-unidades',
    templateUrl: './unidades.component.html',
    styleUrls: ['./unidades.component.scss']
})

export class UnidadesComponent implements OnInit {
  displayedColumns = ['editar', 'excluir', 'isShared', 'description'];
  public unidade: Unidade[] = [];
  public formGroup: FormGroup;
  public amounts: any[];
  dataSource: MatTableDataSource<Unidade>;
  erro: boolean = false;
  erroDescription: boolean = false;
  consulta: boolean = false;
  public carregando: Boolean = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;

  constructor(private formBuilder: FormBuilder,
              public dialog: MatDialog,
              public modalService: NgbModal,
              public unitProvider: UnitProvider,
              public checkPermission: CheckPermission) {}

  ngOnInit() {
    try {
      this.generateTable();

      this.formGroup = this.formBuilder.group({
        description: ['', Validators.required],
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  generateTable(){
    this.carregando = true;
    this.unidade = [];
        this.getUnidades().then(() =>{
        this.loadTable();
         this.carregando = false;
        });
      }

  getUnidades() {
    return new Promise((resolve, reject) => {
    this.unitProvider.getUnit().then((result: Unidade) => {
     Object.keys(result).forEach((item) => {
     ////console.log"teste2", result[item])
     this.unidade.push(result[item]);
     });
     (this.unidade as any).sort((a, b) => {
      if (a.isShared === b.isShared) {
        return a.description.localeCompare(b.description);
      }
      return a.isShared ? -1 : 1;
    });
     resolve(this.unidade);
    }).catch(err => {
      this.carregando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  });
  }
loadTable(){
  this.dataSource = new MatTableDataSource<Unidade>(this.unidade);
    this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  downloadPdf() {
    var prepare = [];
    this.unidade.forEach(e => {
      var tempObj = [];
      tempObj.push(e.id);
      tempObj.push(e.description);
      prepare.push(tempObj);
    });
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
          align: 'center'
        })
      }
    }
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(10);
    doc.text(14, 10, 'UNIDADES');
    doc.text(175, 10, moment().format('DD/MM/YY HH:mm').toString());
    doc.setLineWidth(0.3);
    doc.line(14, 12, 200, 12);
    doc.autoTable({
      head: [['ID', 'Unidade']],
      body: prepare,
      headStyles: {
        fillColor: "#adacac",
        textColor: "#000000"
      },
    });
    addFooters(doc)

    doc.save('unidades' + '.pdf');
  }

  deleteUnidade(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar?',
      text: "Não há como reverter isso!",
      reverseButtons: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#f05050',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando= true;
        this.unitProvider.deleteUnit(row.id).then((result) => {
         this.generateTable();
          Swal.fire(
            'Sucesso!',
          'a unidade foi deletada!',
          'success'
        )
         }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });

      }
    });

      }

  editUnidade(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroUnidadeComponent, modalOptions);
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.unidadeExistente = row;
    modalRef.componentInstance.row = row;
    modalRef.result.then((result) => {
      Swal.fire({
        title: 'Sucesso!',
        text: "A unidade foi editada com sucesso!",
        icon: 'success',
        confirmButtonColor: '#3085d6',
      })
      this.generateTable();

    }, () => {
      //this.generateTable();
  });

  }

  newUnidade() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
    const modalRef = this.modalService.open(CadastroUnidadeComponent, modalOptions);
    modalRef.result.then((result) => {
      //this.amounts.push(result);
    // //console.log"aaaaaaaaaaaa",result)
   // this.generateTable();
      Swal.fire({
        title: 'Sucesso!',
        text: "A unidade foi adicionada com sucesso!",
        icon: 'success',
        confirmButtonColor: '#3085d6',
      })
      this.generateTable();
    }, () => {
      //this.generateTable();
    });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: "Não foi possivel adicionar a unidade",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
     // console.error(error)
    }
  }
}


