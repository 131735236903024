import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'rmEspecial',
})
export class RemoveEspecialCaracters implements PipeTransform {

  transform(text): any {
    text = text.toLowerCase().trim();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    text = text.replace(new RegExp('[“]', 'gi'), '');
    text = text.replace(new RegExp('[,]', 'gi'), '');
    text = text.replace(new RegExp('[:]', 'gi'), '');
    text = text.replace(new RegExp('[”]', 'gi'), '');
    return text.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
  }

}
