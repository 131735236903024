import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../core/settings/settings.service';
import {Snackbar} from '../../../../util/snackbar';
import {MenuService} from '../../../core/menu/menu.service';
import {UserProvider} from '../../../../providers/user/user';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from '../../../../service/service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  valForm: FormGroup;
  private password: string;
  private loginFailed: boolean;
  logando: boolean;
  currentRouter = this.routerAngular.url;

  constructor(public settings: SettingsService,
              fb: FormBuilder,
              public activeModal: NgbActiveModal,
              public snackBar: Snackbar,
              public routerAngular: Router,
              public menuService: MenuService,
              private userProvider: UserProvider,
              public router: Router,
              private oauthService: OAuthService) {

    this.valForm = fb.group({
      'email': [this.givenAuthorities(), Validators.compose([Validators.required])],
      'password': [null, Validators.required]
    });
  }

  ngOnInit() {
    // this.alreadyAuthenticated();
  }

  openSnackBar(mensagem: string, tipo: string) {
    this.snackBar.open(mensagem, tipo);
  }

  private loginWithPassword(user: string, password: string) {
    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService
      .fetchTokenUsingPasswordFlowAndLoadUserProfile(user, password).then((resp) => {
      this.checkPermission();
      this.openSnackBar("Usuário logado com sucesso!", "success");
      this.router.navigate([this.currentRouter])
    }).catch((err: HttpErrorResponse) => {
      if (err.status == 400) {
        this.openSnackBar("Usuário ou senha incorretos", "erro");
        this.logando = false;
      } else {
        this.openSnackBar("Não foi possível conectar", "erro");
        this.logando = false;
      }
      this.loginFailed = true;
    });
  }

  private alreadyAuthenticated() {
    if (this.oauthService.hasValidAccessToken()) {
      this.checkPermission();
    }
  }

  private givenAuthorities() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['name'];
  }

  private givenCompanyId() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['userAuthentication'].principal.companyIds[0];
  }

  private checkPermission() {
    this.activeModal.dismiss();
  }

  submitForm($ev, value: any) {
    this.logando = true;
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.loginWithPassword(value.email, value.password);
    } else {
      this.logando = false;
    }
  }

}
