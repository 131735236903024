import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImpostoProvider } from 'src/providers/nfe/imposto';
import { UnitProvider } from 'src/providers/produto/unidade';
import { ErrorUtil } from 'src/util/error';
import { Snackbar } from 'src/util/snackbar';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastro-impostos',
  templateUrl: './cadastro-impostos.component.html',
  styleUrls: ['./cadastro-impostos.component.scss']
})
export class CadastroImpostosComponent implements OnInit {

  @Input() enableClearOnEscapePressed = true;

  impostoForm: FormGroup
  impostoExistente: any;
  enviado: boolean = false;
  enviando: boolean = false;
  editar: boolean = false;
  public isShared: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackbar: Snackbar,
    public erroUtil: ErrorUtil,
    public impostoProvider: ImpostoProvider,
    public checkPermission: CheckPermission
  ) { }

  ngOnInit() {
    try {
      this.impostoForm = this.fb.group({
        description: [null, Validators.required],
        SituacaoTributaria: [null, Validators.required],
        Aliquota: [null, Validators.required],
        cfop: [null, Validators.required],
        AliquotaDiferimento: [null, Validators.required],
        AliquotaDiferimentoFCP: [null, Validators.required],
        SituacaoTributariaPis: [null, Validators.required],
        AliquotaPercentualPis: [null, Validators.required],
        SituacaoTributariaCofins: [null, Validators.required],
        AliquotaPercentualCofins: [null, Validators.required],
        AliquotaReducao: [null, Validators.required],
        aliquota_reducao_st: [null],
        aliquota_mva: [null],
        industria: [null],
        BeneficioFiscal: [null, Validators.required],
        bc_st_retido: [null],
        aliquota_st_retido: [null],
        valor_st_retido: [null],
        bc_st_dest: [null],
        valor_st_dest: [null],
        valor_fcp_retido: [null],
        aliquota_fcp_retido: [null],
        aliquota_bc_efetivo: [null],
        aliquota_icms_efetivo: [null],
        SituacaoTributariaIpi: ["53", Validators.required],
        codigo_enquadramento: ["999", Validators.required],
        AliquotaIpi: [0.00, Validators.required]
      });
    } catch (error) {
      console.error(error)
    }
    if (this.editar) {
      this.fillImpostoForm();
    }
  }

  // setando formulario c classe de imposto ja existente (edição)
  fillImpostoForm() {
    this.setControlsBySitTrib(this.impostoExistente.icms.situacao_tributaria);
    this.impostoForm.controls.description.setValue(this.impostoExistente.description);
    this.impostoForm.controls.SituacaoTributaria.setValue(this.impostoExistente.icms.situacao_tributaria);
    this.impostoForm.controls.Aliquota.setValue(this.impostoExistente.icms.aliquota);
    this.impostoForm.controls.cfop.setValue(this.impostoExistente.icms.codigo_cfop);
    this.impostoForm.controls.AliquotaDiferimento.setValue(this.impostoExistente.icms.aliquota_diferimento);
    this.impostoForm.controls.AliquotaDiferimentoFCP.setValue(this.impostoExistente.icms.aliquota_diferimento_fcp);
    this.impostoForm.controls.SituacaoTributariaPis.setValue(this.impostoExistente.pis.situacao_tributaria);
    this.impostoForm.controls.AliquotaPercentualPis.setValue(this.impostoExistente.pis.aliquota);
    this.impostoForm.controls.SituacaoTributariaCofins.setValue(this.impostoExistente.cofins.situacao_tributaria);
    this.impostoForm.controls.AliquotaPercentualCofins.setValue(this.impostoExistente.cofins.aliquota);
    this.impostoForm.controls.AliquotaReducao.setValue(this.impostoExistente.icms.aliquota_reducao);
    this.impostoForm.controls.BeneficioFiscal.setValue(this.impostoExistente.icms.beneficio_fiscal);
    this.impostoForm.controls.bc_st_retido.setValue(this.impostoExistente.icms.bc_st_retido);
    this.impostoForm.controls.aliquota_st_retido.setValue(this.impostoExistente.icms.aliquota_st_retido);
    this.impostoForm.controls.valor_st_retido.setValue(this.impostoExistente.icms.valor_st_retido);
    this.impostoForm.controls.bc_st_dest.setValue(this.impostoExistente.icms.bc_st_dest);
    this.impostoForm.controls.valor_st_dest.setValue(this.impostoExistente.icms.valor_st_dest);
    this.impostoForm.controls.valor_fcp_retido.setValue(this.impostoExistente.icms.valor_fcp_retido);
    this.impostoForm.controls.aliquota_fcp_retido.setValue(this.impostoExistente.icms.aliquota_fcp_retido);
    this.impostoForm.controls.aliquota_bc_efetivo.setValue(this.impostoExistente.icms.aliquota_bc_efetivo);
    this.impostoForm.controls.aliquota_icms_efetivo.setValue(this.impostoExistente.icms.aliquota_icms_efetivo);
    this.impostoForm.controls.aliquota_reducao_st.setValue(this.impostoExistente.icms.aliquota_reducao_st);
    this.impostoForm.controls.industria.setValue(this.impostoExistente.icms.industria);
    this.impostoForm.controls.aliquota_mva.setValue(this.impostoExistente.icms.aliquota_mva);
    this.impostoForm.controls.SituacaoTributariaIpi.setValue(this.impostoExistente.ipi.situacao_tributaria);
    this.impostoForm.controls.codigo_enquadramento.setValue(this.impostoExistente.ipi.codigo_enquadramento);
    this.impostoForm.controls.AliquotaIpi.setValue(this.impostoExistente.ipi.aliquota);
    this.isShared = this.impostoExistente.isShared;
  }

  setControlsBySitTrib(sitTrib){
   this.impostoForm.controls.Aliquota.disable();
   this.impostoForm.controls.AliquotaDiferimento.disable();
   this.impostoForm.controls.AliquotaDiferimentoFCP.disable();
   this.impostoForm.controls.AliquotaReducao.disable();
   this.impostoForm.controls.BeneficioFiscal.disable();

   switch (sitTrib) {
    case "00":
      this.impostoForm.controls.Aliquota.enable();
      break;
    case "20":
      this.impostoForm.controls.AliquotaReducao.enable();
      this.impostoForm.controls.BeneficioFiscal.enable();
      this.impostoForm.controls.BeneficioFiscal.clearValidators();
      break;
    case "40":
        this.impostoForm.controls.BeneficioFiscal.enable();
        break;
    case "41":
      this.impostoForm.controls.BeneficioFiscal.enable();
    break;
   case "50":
     this.impostoForm.controls.BeneficioFiscal.enable();
     break;
    case "51":
      this.impostoForm.controls.AliquotaDiferimento.enable();
      this.impostoForm.controls.AliquotaDiferimentoFCP.enable();
      this.impostoForm.controls.BeneficioFiscal.enable();
      this.impostoForm.controls.BeneficioFiscal.clearValidators();
      break;
    case "90":{
      this.impostoForm.controls.AliquotaReducao.enable();
      this.impostoForm.controls.AliquotaReducao.clearValidators();
      this.impostoForm.controls.aliquota_reducao_st.enable();
      this.impostoForm.controls.aliquota_mva.enable();
      this.impostoForm.controls.industria.enable();
      this.impostoForm.controls.BeneficioFiscal.enable();
      break;
    }
    }
  }

  setImpostoObj() {
    let obj = {
      description: this.impostoForm.get('description').value,
      icms: {
        codigo_cfop: this.impostoForm.get('cfop').value,
        situacao_tributaria: this.impostoForm.get('SituacaoTributaria').value,
        aliquota: this.impostoForm.get('Aliquota').value,
        aliquota_diferimento: this.impostoForm.get('AliquotaDiferimento').value,
        aliquota_diferimento_fcp: this.impostoForm.get('AliquotaDiferimentoFCP').value,
        aliquota_reducao: this.impostoForm.get('AliquotaReducao').value,
        beneficio_fiscal: this.impostoForm.get('BeneficioFiscal').value,
        bc_st_retido: this.impostoForm.get('bc_st_retido').value,
        aliquota_st_retido: this.impostoForm.get('aliquota_st_retido').value,
        valor_st_retido: this.impostoForm.get('valor_st_retido').value,
        bc_st_dest: this.impostoForm.get('bc_st_dest').value,
        valor_st_dest: this.impostoForm.get('valor_st_dest').value,
        valor_fcp_retido: this.impostoForm.get('valor_fcp_retido').value,
        aliquota_fcp_retido: this.impostoForm.get('aliquota_fcp_retido').value,
        aliquota_bc_efetivo: this.impostoForm.get('aliquota_bc_efetivo').value,
        aliquota_icms_efetivo: this.impostoForm.get('aliquota_icms_efetivo').value,
        aliquota_reducao_st: this.impostoForm.get('aliquota_reducao_st').value,
        aliquota_mva: this.impostoForm.get('aliquota_mva').value,
        ibndustria: this.impostoForm.get('industria').value,
      },
      pis: {
        situacao_tributaria: this.impostoForm.get('SituacaoTributariaPis').value,
        aliquota: this.impostoForm.get('AliquotaPercentualPis').value,
      },
      cofins: {
        situacao_tributaria: this.impostoForm.get('SituacaoTributariaCofins').value,
        aliquota: this.impostoForm.get('AliquotaPercentualCofins').value,
      },
      ipi: {
        situacao_tributaria: this.impostoForm.get('SituacaoTributariaIpi').value,
        codigo_enquadramento: this.impostoForm.get('codigo_enquadramento').value,
        aliquota: this.impostoForm.get('AliquotaIpi').value,
      },
      isShared: this.isShared
    }
    if (this.editar) {
      obj['id'] = this.impostoExistente.id;
    }
    return (obj);
  }

  newImposto() {
    this.enviado = true;
    if(this.impostoForm.invalid){
      return;
    }
    this.enviando = true;
    this.impostoProvider.postImposto(this.setImpostoObj()).then((result) => {
      this.activeModal.close('success');
      this.enviando = false;
    }).catch((err) => {
      this.enviando = false;
      Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
    })
  }

  editImposto() {
    this.enviado = true;

    if(this.impostoForm.invalid){
      return;
    }
    this.enviando = true;
    this.impostoProvider.putImposto(this.setImpostoObj()).then((result) => {
      this.activeModal.close('success');
      this.enviando = false;
    }).catch((err) => {
      this.enviando = false;
      Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
    });
  }

  checkIfBeneficioFiscal(){
    let csts = ['20', '40', '41', '50', '51', '90'];
    return csts.includes(this.impostoForm.controls.SituacaoTributaria.value);
  }

}
