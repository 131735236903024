import {Component, OnInit, ViewChild} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserProvider} from 'src/providers/user/user';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {MenuService} from '../../../core/menu/menu.service';
import {menuNotaFiscalContador} from '../../menu';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {SelectProdutorComponent} from '../../notafiscal/select-produtor/select-produtor.component';
import { CheckPermission } from 'src/service/checkPermission';


@Component({
  selector: 'app-list-produtor',
  templateUrl: './list-produtor.component.html',
  styleUrls: ['./list-produtor.component.scss']
})
export class ListProdutorComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns = [];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(  private oauthService: OAuthService,public userProvider: UserProvider,
    public router: Router, public menuService: MenuService, public modalService: NgbModal, private checkPermissionUser: CheckPermission,

    ) { }

    public data = []
  ngOnInit() {
    //this.selectEmissor()
    this.setDisplayTable()

    if (this.oauthService.hasValidAccessToken()) {
      if (this.checkPermissionUser.isSuperuser() || this.checkPermissionUser.isAccountantAdmin()) {
        this.userProvider.getIssuers().then((clientes: any) => {
          let data = clientes.details.specialMembers;
          data.forEach(element => {
            if (element.issuerId != null) {
              this.data.push(element)
            }
          });
          this.configDataTable()
        }).catch(() => {
        })
      } else if (this.checkPermissionUser.isAccountantCollaborator()) {
        this.userProvider.getCollaboratorIssuers().then((clientes: any) => {
          clientes.forEach((cliente) => {
            this.data.push({
              issuerId: cliente[0],
              companyId: cliente[1],
              cpfCnpj: cliente[2],
              name: cliente[3],
            })
          })
          this.configDataTable();
        }).catch((error) => {
        });
      }
    }
  }

  selectEmissor(){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(SelectProdutorComponent, modalOptions);
    modalRef.componentInstance.data = this.data;
  }


  setDisplayTable(){
      this.displayedColumns = ['issuerId', 'name', 'acesso'];

  }

  configDataTable(){
    this.dataSource = new MatTableDataSource(this.data);
    //console.log(this.dataSource)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  accessCompany(row){
    if (localStorage.getItem("userIdOriginal") == localStorage.getItem("userId")){
      this.menuService.addMenu(menuNotaFiscalContador, 3);
    }
    localStorage.setItem("userId", row.issuerId.toString());
    this.router.navigate(["notafiscal/notasFiscais"]);
  }
}
