/* tslint:disable */
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {OAuthService} from 'angular-oauth2-oidc';
import {MenuService} from 'src/app/core/menu/menu.service';
import {SettingsService} from 'src/app/core/settings/settings.service';
import {SupportModalComponent} from 'src/app/layout/header/support-modal/support-modal.component';
import {Snackbar} from 'src/util/snackbar';
import {UserblockService} from '../sidebar/userblock/userblock.service';
import {EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-dropdowns';
import {Predicate, Query} from '@syncfusion/ej2-data';
import {UserProvider} from 'src/providers/user/user';
import {CheckPermission} from '../../../service/checkPermission';
import {menuNotaFiscalContador} from 'src/app/routes/menu';
import {DarkThemePickerService} from '../../core/themes/dark-theme-picker.service';


const screenfull = require('screenfull');


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderNotaFiscalComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  selectedClient: any;

  darkTheme: boolean;

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', {static: true}) fsbutton;  // the fullscreen button
  @ViewChild('dropdown', {static: false}) dropdownValue;  // the fullscreen button

  currentRouter = this.routerAngular.url;

  screenfull: any;
  public data = [];

  constructor(public menu: MenuService,
              private checkPermissionUser: CheckPermission,
              public pickThemeService: DarkThemePickerService,
              public checkPermission: CheckPermission,
              public userblockService: UserblockService,
              private oauthService: OAuthService,
              public userProvider: UserProvider,
              public routerAngular?: Router,
              public snackBar?: Snackbar,
              public settings?: SettingsService,
              public injector?: Injector,
              public supportModalService?: NgbModal
  ) {

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.oauthService.logoutUrl = 'login';

    if (this.oauthService.hasValidAccessToken()) {
      if (this.checkPermissionUser.isSuperuser() || this.checkPermissionUser.isAccountantAdmin()) {
        this.userProvider.getIssuers().then((clientes: any) => {
          let data = clientes.details.specialMembers;
          data.forEach(element => {
            if (element.issuerId != null) {
              this.data.push({
                name: element.name.toString(),
                id: element.issuerId.toString()
              });
              if (localStorage.getItem('userId').toString() == element.issuerId.toString()) {
                this.selectedClient = element;
                this.text = this.selectedClient.issuerId + ' - ' + this.selectedClient.name;
              }
            }
          });
        }).catch(() => {
        });
      } else if (this.checkPermissionUser.isAccountantCollaborator()) {
        this.userProvider.getCollaboratorIssuers().then((clientes: any) => {
          clientes.forEach((cliente) => {
            this.data.push({
              name: cliente[3],
              id: cliente[0]
            });
            if (localStorage.getItem('userId').toString() == cliente[0]) {
              this.selectedClient = cliente;
              this.text = this.selectedClient[0] + ' - ' + this.selectedClient[3];
            }
          });
        }).catch((error) => {
        });
      }
    }
    if (localStorage.getItem('access_token') == null) {
      console.log('??1');
      this.oauthService.logOut(true);
      this.routerAngular.navigate(['login']);
    }

    this.darkTheme = localStorage.getItem('theme') == 'dark';
  }

  public fields: Object = {text: 'name', value: 'id'};
  public text: string = 'Selecione o produtor';
  filterType: string = 'contains';

  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    //console.log(e)
    let query = new Query();
    let predicateQuery = query.where(new Predicate('name', 'contains', e.text, true).or('id', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    //pass the filter data source, filter query to updateData method.
    //console.log("query",query)
    e.updateData(this.data, query);
  };


  ngOnInit() {


    this.isNavSearchVisible = false;

    // tslint:disable-next-line: prefer-const
    let ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el) {
        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        this.screenfull = screenfull.isFullscreen;
      }
    });

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });
  }

  changeClient() {
    if (this.selectedClient != undefined) {
      let userSelected = this.data.find(value => this.selectedClient == value.id);
      //console.log(userSelected)
      this.selectedClient = userSelected;
      console.log('SELECTED CLIENT', this.selectedClient.id);
      console.log('USER SELECTED', userSelected.id);

      if (localStorage.getItem('userIdOriginal') == localStorage.getItem('userId')) {
        this.menu.addMenu(menuNotaFiscalContador, 3);
      }
      localStorage.setItem('userId', userSelected.id);
      localStorage.setItem('empresa', userSelected.name.toString());
      localStorage.setItem('idEmpresa', this.selectedClient.toString());


      console.log('CHANGE CLIENT LOCAL STORAGE', localStorage.getItem('userId'));

      this.reLoad();
    }
  }

  reLoad() {
    this.router.navigate([this.currentRouter]);
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // //console.logstat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  logout() {
    document.cookie = `access_token=;path=/;domain=.farmcont.com.br`;
    this.oauthService.logOut(true);
    this.routerAngular.navigate(['login']);
    this.snackBar.open('Usuário deslogado com sucesso!', 'success');
  }

  openSupportModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      this.supportModalService.open(SupportModalComponent, modalOptions);
    } catch (error) {
      console.error('', error);
    }
  }

  changeTheme() {
    this.darkTheme = !this.darkTheme;
    this.pickThemeService.selectTheme(this.darkTheme);
  }
}
