import { Component, OnInit } from '@angular/core';
import {Maintenance} from "../../../../model/dto/status/Maintenance";
import { Incident } from "../../../../model/dto/status/Incident";
import {MaintenanceProvider} from "../../../../providers/status/maintenanceProvider";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.scss']
})
export class ServiceStatusComponent implements OnInit {

  maintenance: Maintenance[] = [];
  newsObj = null;

  constructor(private maintenanceProvider: MaintenanceProvider, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getStatus();
    this.getIncidents();
  }

  getOverallData(){
    this.maintenanceProvider.getOverallData().then((value: any) => {
    });
  }

  getIncidents(){
    this.maintenanceProvider.getAllIncident().then((value: Incident[]) => {
      let incident = value.filter(x => x.name.includes("[NEWS_EMISSOR_WEB]"));
      if(incident.length == 0) return;
      this.newsObj = {
        title: incident[0].name.split("[NEWS_EMISSOR_WEB]")[1].replace(/_/g, " "),
        description: incident[0].updates[0].message.split("§")[0],
        link: incident[0].updates[0].message.split("§")[1]
      }
      this.newsObj.link != undefined ? this.newsObj.link = this.domSanitizer.bypassSecurityTrustUrl(this.newsObj.link) : this.newsObj.link = null;
    });
  }

  getStatus() {
    this.maintenanceProvider.getAllMaintenance().then((value: Maintenance[]) => {
      this.maintenance = value.filter(x => x.name.includes("[MAINTENANCE_EMISSOR_WEB]")).map((x) => {
        x.name = x.name.split("[MAINTENANCE_EMISSOR_WEB]")[1].replace(/_/g, " ");
        return x;
      })
    });
  }
}
