import {Component, OnInit} from "@angular/core";
import Swal from "sweetalert2";
import {UserProvider} from "src/providers/user/user";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SettingsService} from "../../../core/settings/settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../util/snackbar";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  private token: any;
  public form: FormGroup;
  public loading = false;
  public captchaSolved = false;
  public siteKey: string;
  public passwordsDifferent: boolean = false;


  constructor(public modalService: NgbModal,
              public settings: SettingsService,
              fb: FormBuilder,
              private snackBar: Snackbar,
              private router: Router,
              public userProvider: UserProvider,
              private route: ActivatedRoute
  ) {
    this.siteKey = "6LfHZ24aAAAAAEIwVK8cNzX2MEV7Zy19LvtQAV3p";
    this.form = fb.group({
      password: [null, Validators.required],
      passwordConfirm: [null, Validators.compose([Validators.required])],
      recaptcha: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token']
    });
    // this.resetPassword();
  }

  resetPassword() {
    //console.log(this.token)
    this.userProvider.resetPassword(this.token).then((result) => {
      Swal.fire({
        title: "<strong>Sucesso</strong>",
        icon: "success",
        text: "Sua senha foi redefinida! \n Cheque seu email para a nova senha.",
      });
    }).catch((result) => {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "warning",
        text: "Token inválido",
      });
    });
  }

  submitForm($ev, value: any) {
    if (this.captchaSolved) {
      this.loading = true;
      $ev.preventDefault();
      for (let c in this.form.controls) {
        this.form.controls[c].markAsTouched();
      }
      if (this.form.valid) {
        let obj = {
          token: this.token,
          newerPassword: this.form.get('password').value.trim(),
        }
        console.log(obj)
        this.userProvider.newResetPassword(obj).then((result) => {
          Swal.fire({
            title: "<strong>Sucesso</strong>",
            icon: "success",
            text: "Sua senha foi redefinida!.",
          }).then(() => {
            this.loading = false;
            this.router.navigate(["/login"]);
          });
        }).catch((result) => {
          console.log(result)
          Swal.fire({
            title: "<strong>Erro</strong>",
            icon: "warning",
            text: "Token inválido",
          });
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.snackBar.open("Resolva o captcha antes de fazer login", "erro");
    }
  }

  handleSuccess() {
    this.captchaSolved = true;
  }

  verifyPasswords() {
    this.passwordsDifferent = this.form.get('password').value.trim() !== this.form.get('passwordConfirm').value.trim();
    this.form.get('passwordConfirm').setErrors(this.passwordsDifferent ? {incorrect: true} : null);
  }
}
