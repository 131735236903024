
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import { ReceiverProvider } from 'src/providers/nfe/receiver';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cancelar-nota-fiscal',
  templateUrl: './cancelar-nota-fiscal.component.html',
  styleUrls: ['./cancelar-nota-fiscal.component.scss']
})

export class CancelarNotaFiscalComponent implements OnInit {
  editar = false
  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;
  cancelForm
  chave
  enviando : boolean = false;
  enviado : boolean = false;
  constructor(public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public receiverProvider: ReceiverProvider) { }

  ngOnInit() {
    this.cancelForm = this.fb.group({
      chave: ['', Validators.required],
      motivo: ['', Validators.required],
  
    });
    if(this.chave){
this.cancelForm.controls.chave.setValue(this.chave)
    }
  }
  cancelarSubmit(){
    this.enviado = true;
    if(this.cancelForm.invalid){
      return;
    }
    this.enviando = true;
    let value = {
      key: this.cancelForm.controls.chave.value,
      reason: this.cancelForm.controls.motivo.value
    }
    this.receiverProvider.cancelNfe(value).then((result : any) => {
      this.enviando = false;
      if(result.status == "cancelado"){
        Swal.fire({
          title: 'Sucesso!',
          text: "Nota Fiscal cancelada com sucesso",
          icon: 'success',
          confirmButtonColor: '#3085d6',
        }).then(result => {
          if(result.isConfirmed){
            this.activeModal.close();
          }
        })
      }
      else{
        Swal.fire({
          title: 'Erro!',
          text: "Nota Fiscal não pôde ser cancelada",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
        }).then(result => {
          if(result.isConfirmed){
            this.activeModal.close();
          }
        })
      }
      
    }).catch(err => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }
}
