import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Imovel} from '../../../../../model/Imovel';
import {EstadosMunicipiosUtil} from '../../../../../util/estados-municipios';
import {PropertyProvider} from '../../../../../providers/company/property/property';
import {Snackbar} from '../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../util/error';
import {HttpClient} from '@angular/common/http';
import {ImovelDTO} from '../../../../../model/dto/ImovelDTO';
import {ReplaySubject, Subject} from 'rxjs';
import {City} from '../../../../../model/basicos/City';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {Country} from '../../../../../model/basicos/Country';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Estate} from '../../../../../model/basicos/Estate';
import {MemberProvider} from '../../../../../providers/company/member/member';
import {MatSelect} from '@angular/material/select';
import {MatDialog} from '@angular/material/dialog';
import {UserProvider} from 'src/providers/user/user';
import Swal from 'sweetalert2';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-cadastro-imoveis',
  templateUrl: './cadastro-imoveis.component.html'
})
export class CadastroImoveisComponent implements OnInit {

  @ViewChild('triggerCountry', {static: false}) triggerCountry: MatAutocompleteTrigger;
  @ViewChild('triggerCity', {static: false}) triggerCity: MatAutocompleteTrigger;
  @ViewChild('triggerState', {static: false}) triggerState: MatAutocompleteTrigger;
  @ViewChild('citySelect', {static: true}) citySelect: MatSelect;
  @ViewChild('stateSelect', {static: true}) stateSelect: MatSelect;
  @ViewChild('countrySelect', {static: true}) countrySelect: MatSelect;
  @Input() enableClearOnEscapePressed = true;
  @Input() public imovelExistente;
  public novoDest = false;
  public imovel;
  public retEntrega = false;
  public emitente = false;
  public imovelAux: Imovel = new Imovel();
  public codImovel: string;
  public municipioId: number;
  public editar = false;
  public imovelForm: FormGroup;
  public enviado = false;
  public properties: ImovelDTO[];
  public members: any[];
  public membersClone: any[];
  public i: number;
  public enviando = false;
  public buttonDisable = true;
  public countrys: Country[] = this.estadosMunicipiosUtil.paises;
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public states: Estate[] = this.estadosMunicipiosUtil.estados;
  public stateFilterCtrl: FormControl = new FormControl();
  public filteredStates: ReplaySubject<Estate[]> = new ReplaySubject<Estate[]>(1);
  public cities: City[] = this.estadosMunicipiosUtil.cidades;
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
  protected _onDestroyCountry = new Subject<void>();
  protected _onDestroyState = new Subject<void>();
  protected _onDestroyCity = new Subject<void>();

  public errado = false;
  public regexNumeroCasa: '^(?:[1-9]\\d*|s\\/n|S\\/N)$';


  constructor(public activeModal: NgbActiveModal,
              private propertyProvider: PropertyProvider,
              private socioProvider: MemberProvider,
              public snackbar: Snackbar,
              public modalService: NgbModal,
              public fb: FormBuilder,
              public dialog: MatDialog,
              public errorUtil: ErrorUtil,
              public estadosMunicipiosUtil: EstadosMunicipiosUtil,
              public userProvider: UserProvider,
              public http: HttpClient,
              public oauthService: OAuthService) {
  }

  get terceiros(): FormArray {
    return this.imovelForm.get('terceiros') as FormArray;
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroyCountry.next();
    this._onDestroyCountry.complete();
    this._onDestroyState.next();
    this._onDestroyState.complete();
    this._onDestroyCity.next();
    this._onDestroyCity.complete();
  }
// configura o pais
  configCountry() {
    this.estadosMunicipiosUtil.setCountry();
    this.countrys = this.estadosMunicipiosUtil.paises;
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.filterCountrys();
      });
  }
// configura o estado
  configState() {
    this.estadosMunicipiosUtil.setState();
    this.states = this.estadosMunicipiosUtil.estados;
    this.filteredStates.next(this.states.slice());
    this.stateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyState))
      .subscribe(() => {
        this.filterStates();

      });
  }
// configura a cidade
  configCity() {
    this.cities = this.estadosMunicipiosUtil.cidades;
    this.filteredCities.next(this.cities.slice());
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCity))
      .subscribe(() => {
        this.filterCities();
      });
  }

// filtra cidades
  filterCities() {
    if (!this.cities) {
      return;
    }
    let search = this.cityFilterCtrl.value;
    if (!search) {
      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCities.next(
      this.cities.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  // filtra estados
  filterStates() {
    if (!this.states) {
      return;
    }
    let search = this.stateFilterCtrl.value;

    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStates.next(
      this.states.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );

  }
// filtra paises
  filterCountrys() {
    if (!this.countrys) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountrys.next(
      this.countrys.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


  ngOnInit() {
    // faz a distinção dos formularios para se o intuito é que seja para emitente ou destinatario
    if (!this.emitente) {
   this.imovelForm = this.fb.group({
      ie: ['', Validators.required],
      ieType: ['', Validators.required],
      pais: ['', Validators.required],
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      neighborhood: ['', Validators.required],
      cep: ['', Validators.required],
      street: ['', Validators.required],
    });
    } else {
      this.imovelForm = this.fb.group({

        ie: ['', Validators.required],
        dfeNumber: ['', Validators.required],
        serialNumber: ['', Validators.required],
        pais: ['', Validators.required],
        estado: ['', Validators.required],
        municipio: [{value: '', disabled: true}, Validators.required],
        neighborhood: [{value: '', disabled: true}, Validators.required],
        cep: ['', Validators.required],
        street: [{value: '', disabled: true}, Validators.required],
        houseNumber: [{value: '', disabled: true}, Validators.required, Validators.pattern(this.regexNumeroCasa)],
        complement: [{value: '', disabled: true}]

      });
    }


    this.configCountry();
    this.configState();
    // faz a distinção se o imovel está sendo criado ou editado
    if (this.imovelExistente != undefined) {
      this.parseDTO();
      this.editar = true;

    } else {
      this.imovelForm.controls.pais.setValue(32);
      this.getCountryId();
    }

  }
// controla se a inscrição estadual é necessaria ou nao
  buttonControl(type) {
    if (type == true) {
        this.imovelForm.controls.ie.disable();
    } else {
      this.imovelForm.controls.ie.enable();
    }
  }
// seta os valores no formulario caso o endereço ja exista
  parseDTO() {
    this.imovelForm.controls.street.enable();
    this.imovelForm.controls.houseNumber.enable();
    this.imovelForm.controls.complement.enable();
    this.imovelForm.controls.neighborhood.enable();
    this.imovelForm.controls.municipio.enable();
    if (this.editar && this.emitente) {
      this.imovelExistente.countryId = 32;
    }
    this.imovelForm.controls.pais.setValue(this.imovelExistente.countryId);
    this.imovelForm.controls.neighborhood.setValue(this.imovelExistente.neighborhood);
    this.imovelForm.controls.cep.setValue(this.imovelExistente.cep);
    this.imovelForm.controls.street.setValue(this.imovelExistente.street);
    this.imovelAux.houseNumber = this.imovelExistente.houseNumber;
    this.imovelAux.complement = this.imovelExistente.complement;
    this.imovelAux.countryId = this.imovelExistente.countryId;
    this.imovelAux.ie = this.imovelExistente.ie;
    this.imovelAux.ieType = this.imovelExistente.ieType;
    if (!this.emitente) {
          this.imovelForm.controls.ieType.setValue(this.imovelExistente.ieType);

    }
    this.imovelForm.controls.ie.setValue(this.imovelExistente.ie);
    if (this.emitente) {
    this.imovelForm.controls.serialNumber.setValue(this.imovelExistente.serialNumber);
    this.imovelForm.controls.dfeNumber.setValue(this.imovelExistente.dfeNumber);
    }
    if (this.imovelAux.countryId == 32) {
        this.imovelAux.ufId = this.imovelExistente.ufId;
        this.imovelAux.stateId = this.imovelExistente.ufId;
        this.imovelForm.controls.estado.setValue(this.imovelExistente.ufId);
        this.estadosMunicipiosUtil.setCity({id: this.imovelExistente.cityId });
        this.imovelForm.controls.municipio.setValue(this.imovelExistente.cityId );
        this.getStateId();
    }
  }

  getCountryId() {
    this.imovelAux.countryId = this.imovelForm.controls.pais.value;
  }

  getStateId() {
    this.imovelForm.controls.municipio.enable();
    this.imovelAux.ufId = this.imovelForm.controls.estado.value;
    this.estadosMunicipiosUtil.setCity(this.imovelForm.controls.estado.value);
    this.configCity();
  }

  cepMudanca() {
   // console.log("oiiii")
    this.imovelForm.controls.street.enable();
    this.imovelForm.controls.houseNumber.enable();
    this.imovelForm.controls.complement.enable();
    this.imovelForm.controls.neighborhood.enable();
  }
  // usa a api da viacep para auto preencher os campos usando o cep
  useCepToSetForm() {
    if (this.imovelForm.controls.cep.value.length == 8 && this.imovelForm.controls.pais.value == 32) {

      return new Promise((resolve, reject) => {

        this.http.get('https://viacep.com.br/ws/' + this.imovelForm.controls.cep.value + '/json/')
          .subscribe((result: any) => {
              resolve(result);
              this.imovelForm.controls.neighborhood.setValue(result.bairro);
              this.imovelForm.controls.street.setValue(result.logradouro);
              this.imovelAux.complement = result.complemento;
              this.states.forEach(element => {

                if (element.abbreviation == result.uf) {
                  this.imovelForm.controls.estado.setValue(element.id);
                  this.getStateId();
                  return true;
                }
              });
              this.cities.forEach(element => {
                if (element.name == result.localidade) {
                  this.imovelForm.controls.municipio.setValue(element.id);
                  return true;
                }
              });
            },
            (error) => {
              reject(error);
            });
      });
    }

  }
// recebe os valores do formulario
  setImovelForm() {
    if (this.imovelAux.countryId == 32) {
    } else {
      this.imovelForm.controls.cep.clearValidators();
    }
    this.imovelAux.cep = this.imovelForm.controls.cep.value;
    this.imovelAux.neighborhood = this.imovelForm.controls.neighborhood.value;
    this.imovelAux.houseNumber = this.imovelForm.controls.houseNumber.value;
    this.imovelAux.street = this.imovelForm.controls.street.value;
    this.imovelAux.countryId = this.imovelForm.controls.pais.value;
    // this.imovelAux.companyId = +localStorage.getItem('idEmpresa');
    if (this.emitente) {
        this.imovelAux.serialNumber = this.imovelForm.controls.serialNumber.value;
        this.imovelAux.dfeNumber = this.imovelForm.controls.dfeNumber.value;
        } else {
          this.imovelAux.ieType = this.imovelForm.controls.ieType.value;
        }
    if (this.imovelAux.ieType == 'ICMS_TAXPAYER' || this.emitente) {
               this.imovelAux.ie =  this.imovelForm.controls.ie.value;
       } else {
        this.imovelAux.ie = null;
       }


    if (this.imovelForm.controls.pais.value == 32) {
       this.imovelAux.ufId = this.imovelForm.controls.estado.value;
       this.imovelAux.cityId = this.imovelForm.controls.municipio.value
      ;
    }
  }
// seta o imovel existente
  setEditedObject(imovel: ImovelDTO) {
    this.imovelExistente.propertyCode = imovel.propertyCode;
    this.imovelExistente.name = imovel.name;
    this.imovelExistente.itrCode = imovel.itrCode;
    this.imovelExistente.address = imovel.address;
  }
// arruma o formulario apos o click em enviar do usuario
  novoImovel() {
    this.enviando = true;
    this.setImovelForm();
    if (this.imovelForm.controls.pais.value != 32) {
      this.imovelForm.controls.estado.disable();
      this.imovelForm.controls.ie.disable();
      this.imovelForm.controls.ieType.disable();
      this.imovelForm.controls.municipio.disable();
    }if (this.retEntrega) {
      this.imovelForm.controls.ie.disable();
      this.imovelForm.controls.ieType.disable();
    }
    if (this.imovelForm.valid) {
      // console.log("2")
      this.postProperty(this.imovelAux);
    } else {
      this.snackbar.openLong('Há erros no formulário. Confira antes de tentar enviar novamente!', 'erro');
      // console.log("FON TRAB")
      this.enviando = false;
      this.enviado = true;
    }
  }

  afterPostAndPutImovel(closeParameter, imovel, editMode: boolean) {
    if (editMode) {
      this.snackbar.openLong('Sucesso! ' + imovel.name + ' editado com sucesso!', 'success');
      this.setEditedObject(imovel);
    } else {
      this.snackbar.openLong('Sucesso! ' + imovel.name + ' inserido com sucesso!', 'success');
    }

    this.activeModal.close(closeParameter);
  }

  editarImovel() {
    this.verificacaoHouseNumber({value: this.imovelForm.controls.houseNumber.value})
     this.setImovelForm();
     if (this.novoDest == false) {
    this.enviando = true;
    this.imovelAux.id = this.imovelExistente.id;
    
    if (this.imovelForm.valid) {
      if (this.codImovel != this.imovelAux.propertyCode) {
        this.imovelAux.newerPropertyCode = this.imovelAux.propertyCode;
        this.imovelAux.propertyCode = this.codImovel;
        this.putProperty(this.imovelAux);
      } else {
        this.putProperty(this.imovelAux);
      }
    } else {
      this.enviado = true;
      this.enviando = false;
    }
    } else {
      this.snackbar.openLong('Sucesso! ' + this.imovelAux.name + ' editado com sucesso!', 'success');
      this.activeModal.close(this.imovelAux);
    }
  }

  postProperty(imovel) {
        if (this.novoDest == true) {
          this.activeModal.close(imovel);
          this.snackbar.openLong('Sucesso! ' + this.imovelAux.name + ' editado com sucesso!', 'success');
        } else {
          this.userProvider.postPropriedade(imovel).then((imovelPost) => {
            this.snackbar.openLong('Sucesso! ' + this.imovelAux.name + ' editado com sucesso!', 'success');
            this.activeModal.close('success');
          }).catch(err => {
            this.enviando = false;
            Swal.fire({
              title: 'Falha!',
              text: err.error.message,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            })
         });
        }


  }

  postPropertyAfterCheckNewDest(imovel) {
    this.propertyProvider.postProperty(imovel).then((imovelPost: ImovelDTO) => {
      this.afterPostAndPutImovel(imovelPost, imovelPost, false);
    }).catch((error) => {
      Swal.fire(
        error.error,
        error.error_description_pt_br,
        'warning',
     );
      this.enviando = false;
    });
  }

  putProperty(imovel) {
    delete imovel.countryId;
    delete imovel.companyId;
    imovel.ufId = imovel.stateId;
    delete imovel.stateId;
    this.propertyProvider.putProperty(imovel).then((imovelPost: ImovelDTO) => {
      this.activeModal.close('success');
      this.afterPostAndPutImovel(imovelPost, imovelPost, false);
    }).catch(err => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }


  protected setInitialValue() {

    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.countrySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
      });

    if (this.editar) {

      this.filteredStates
        .pipe(take(1), takeUntil(this._onDestroyState))
        .subscribe(() => {
          this.stateSelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

      this.filteredCities
        .pipe(take(1), takeUntil(this._onDestroyCity))
        .subscribe(() => {
          this.citySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

    }

  }

  verificacaoHouseNumber(target) {
    if (target.value.match('^(?:[1-9]\\d*|s\\/n|S\\/N)$')) {
      this.imovelForm.controls.houseNumber.setErrors(null);
    } else {
        this.imovelForm.controls.houseNumber.setErrors({ pattern: true });
    }
  }
}

class ImovelEmissor {
  cep: any;
  complement: any;
  dfeNumber: any;
  houseNumber: any;
  ie: any;
  neighborhood: any;
  serialNumber: any;
  stateId: any;
  street: any;
}
