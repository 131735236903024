import {DfeDTOResponse} from '../dfe/DfeDTOResponse';
import {ConsultaDfe} from '../../ConsultaDfe';
import {PessoaMapper} from './pessoaMapper';
import {Injectable} from '@angular/core';
import {DuplicatesMapper} from './duplicatesMapper';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import * as moment from 'moment';

@Injectable()
export class DfeMapper {

  constructor(public pessoaMapper: PessoaMapper,
              public duplicatesMapper: DuplicatesMapper,
              public brMasker: BrMaskerIonicServices3) {
  }

  public arrayToClientModel(apiModel: DfeDTOResponse[]): ConsultaDfe[] {
    const dfes: ConsultaDfe[] = [];
    apiModel.forEach((resultBack) => {
      if (this.serverModelToClientModel(resultBack).modelo !== 'mdfe') {
        dfes.push(this.serverModelToClientModel(resultBack));
      }
    });

    return dfes;

  }

  public serverModelToClientModel(apiModel: DfeDTOResponse): ConsultaDfe {

    const dfe = new ConsultaDfe(); // your Dfe constructor
    const cancelado = apiModel.events.filter(x => x.code === '110111').length > 0  && apiModel.modelType === 'nfe' ? true : apiModel.events.filter(x => x.code === '610615').length > 0 && apiModel.modelType === 'cte' ? true : false;
    const correcao = apiModel.events.filter(x => x.code === '110110').length > 0;
    const autorizado = apiModel.events.length > 0 && !cancelado && !correcao ? true : false;

    if (apiModel.modelType !== 'mdfe') {
      // map each property
      dfe.id = apiModel.id;
      dfe.chaveId = apiModel.key;
      dfe.numeroDoc = apiModel.dfeNumber;
      dfe.dataEmissao = apiModel.issueDate;
      dfe.dataDownloadXML = apiModel.downloadXMLDate;
      dfe.dataFormatada = moment(apiModel.issueDate).format('DD/M/YY');
      dfe.valorTotal = +apiModel.amount;
      dfe.valorTotalFormatado = this.formatarValor(apiModel.amount);
      dfe.dfeValido = apiModel.dfeValid;
      dfe.modelo = apiModel.modelType;
      dfe.tipoNFe = apiModel.nfeType;
      dfe.producao = apiModel.production;
      dfe.duplicatas = this.duplicatesMapper.arrayToClientModel(apiModel.duplicates);
      dfe.pagamentoPlanejado = this.duplicatesMapper.arrayToClientModel(apiModel.plannedPayments);
      dfe.valorDesconto = apiModel.discountValue;
      dfe.emitente = this.pessoaMapper.serverModelToClientModel(apiModel.issuer);
      dfe.destinatario = this.pessoaMapper.serverModelToClientModel(apiModel.addressee);
      dfe.natOperacao = apiModel.operationNature.toUpperCase();
      dfe.pasta = apiModel.folder;
      dfe.escrituracao = this.checkEscrituracao(dfe.pagamentoPlanejado);
      dfe.events = apiModel.events;
      if (cancelado) {
        dfe.eventType = 'Cancelado';
      } else if (correcao) {
        dfe.eventType = 'Carta de correção';
      } else if (autorizado) {
        dfe.eventType = 'Autorizado';
      }

    } else {
      dfe.modelo = 'mdfe';
    }

    return dfe; // and return YOUR model

  }

  checkEscrituracao(duplicatas): string {
    let quantidadeEscriturada = 0;
    duplicatas.forEach((duplicata) => {
      if (duplicata !== undefined) {
        if (duplicata.escriturada) {
          quantidadeEscriturada++;
        }
      }
    });
    if (quantidadeEscriturada === duplicatas.length) {
      return 'TOTAL';
    } else if (duplicatas.length > quantidadeEscriturada && quantidadeEscriturada !== 0) {
      return 'PARCIAL';
    } else if (quantidadeEscriturada === 0) {
      return 'NÃO ESCRITURADA';
    }
  }

formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ',',
      len: 0,
      mask: '',
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: '.',
      type: undefined,
      userCaracters: false,
      money: true
    });
  }

}

