import {SwUpdate} from '@angular/service-worker';
import {interval} from 'rxjs';
import {Injectable} from '@angular/core';
import {Dialog} from "../model/Dialog";
import {BasicAlertComponent} from "./shared/alert/basic-alert/basic-alert.component";
import moment from "moment";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class UpdateService {

  constructor(public updates: SwUpdate, public dialog: MatDialog) {
    if (updates.isEnabled) {
      //console.log'Checando atualizações');
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate());
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe((event) => this.promptUser(event));
  }

  private promptUser(event): void {
    //console.log'Updating to new version');
   this.checkAppVersion(event)
  }

  checkAppVersion(event) {
      const dialogText: Dialog = new Dialog();
      dialogText.button = 'Atualizar';
      dialogText.header = 'Nova versão disponível!';
      dialogText.buttonCancel = 'Cancelar';
      dialogText.subHeader = 'Atualize para a versão mais recente. Ela traz correções e melhorias de performance.' +
        ' A atualização leva poucos segundos e a página será recarregada e não interfere nos seus dados já salvos.';
      const dialogRef = this.dialog.open(BasicAlertComponent, {
        data: dialogText,
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result == dialogText.button) {
          this.updates.activateUpdate().then(() => document.location.reload());
        } else {
          localStorage.setItem('notificationCancel', moment().format());
        }
      });
    }
}
