import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Version} from "../../model/Version";

@Injectable()
export class SuperUserProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  private apiBase = environment.apiLogin + "/user/v1/super/office";

  getOffices() {
    return new Promise((resolve, reject) => {

      this.http.get(this.apiBase, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putOffice(officeId: number) {
    return new Promise((resolve, reject) => {

      this.http.put(this.apiBase, officeId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
