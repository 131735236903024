import { NgModule } from '@angular/core';

import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {DatePickerAllModule} from "@syncfusion/ej2-angular-calendars";
import {HeaderNotaFiscalComponent} from './header/header.component';
import {FooterNotaFiscalComponent} from './footer/footer.component';
import {OffsidebarNotaFiscalComponent} from './offsidebar/offsidebar.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { LayoutNotaFiscalComponent } from './layout.component';
import { UserblockNotaFiscalComponent } from './sidebar/userblock/userblock.component';
import { NavsearchNotaFiscalComponent } from './header/navsearch/navsearch.component';
import { SidebarNotaFiscalComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import {MatExpansionModule} from "@angular/material/expansion";
import { ServiceStatusComponent } from '../routes/pages/service-status/service-status.component';
import { ConnectionStatusComponent } from '../routes/pages/connection-status/connection-status.component';

@NgModule({
    imports: [
        TranslateModule,
        MatTooltipModule,
        SharedModule
        ,DropDownListModule,
        DatePickerAllModule,
        MatExpansionModule
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutNotaFiscalComponent,
        SidebarNotaFiscalComponent,
        UserblockNotaFiscalComponent,
        HeaderNotaFiscalComponent,
        NavsearchNotaFiscalComponent,
        OffsidebarNotaFiscalComponent,
        FooterNotaFiscalComponent,
        ServiceStatusComponent,
        ConnectionStatusComponent,
    ],
    exports: [
        LayoutNotaFiscalComponent,
        SidebarNotaFiscalComponent,
        UserblockNotaFiscalComponent,
        HeaderNotaFiscalComponent,
        NavsearchNotaFiscalComponent,
        OffsidebarNotaFiscalComponent,
        FooterNotaFiscalComponent,
        ServiceStatusComponent,
        ConnectionStatusComponent,
    ]
})
export class LayoutNotaFiscalModule { }
