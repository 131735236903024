import {Injectable} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class CheckPermission {

  constructor(private oauthService: OAuthService) {
    //console.log("sheeeeshh",this.givenAuthorities())
  }

  isSuperuser(): boolean {
    let regra = this.givenAuthorities();
    // //console.logregra);
    return regra.find(role => role.authority === 'ROLE_SUPER') !== undefined;
  }

  isAccountant(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_ADMIN') !== undefined ||
           regra.find(role => role.authority === 'ROLE_ACCOUNTANT_COLLABORATOR') !== undefined;
  }

  isFarmer(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_FARMER_COLLABORATOR') !== undefined || 
           regra.find(role => role.authority === 'ROLE_ISSUER') !== undefined;
  }

  isFarmerAdmin(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_FARMER_ADMIN') !== undefined
  }

  isAccountantAdmin(): boolean{
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_ADMIN') !== undefined;
  }

  isConsultant(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_CONSULTANT') !== undefined;
  }

  private givenAuthorities() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['authorities'];
  }

  isAccountantCollaborator(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_COLLABORATOR') !== undefined;
  }

  canSetSharedItem(): boolean {
    return this.isSuperuser() || this.isAccountantAdmin();
  }
}
