import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuService} from 'src/app/core/menu/menu.service';
import {SettingsService} from 'src/app/core/settings/settings.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarNotaFiscalComponent implements OnInit, OnDestroy {
    menuItems: Array<any>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;

    constructor(public menu: MenuService,
                public routers: Router,
                public settings: SettingsService,
                public injector: Injector) {
      // menu.menuItems = [];
      // menu.menuItemsContador = [];
      // menu.menuItemsNotaFiscal = [];
      // menu.menuItemsConsultor = [];
        this.menuItems = menu.getMenuNotaFiscal();
    }

    ngOnInit() {
        try {
            this.router = this.injector.get(Router);

            this.router.events.subscribe((val) => {
                // close any submenu opened when route changes
                this.removeFloatingNav();
                // scroll view to top
                window.scrollTo(0, 0);
                // close sidebar on route change
                this.settings.setLayoutSetting('asideToggled', false);
            });

            // enable sidebar autoclose from extenal clicks
            this.anyClickClose();
        } catch (exception) {
            console.error('', exception);
        }
    }

    anyClickClose() {
        try {
            this.$doc = $(document).on(this.sbclickEvent, (e) => {
                if (!$(e.target).parents('.aside-container').length) {
                    this.settings.setLayoutSetting('asideToggled', false);
                }
            });
        } catch (exception) {
            console.error('', exception);
        }
    }

    ngOnDestroy() {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }
    }

    toggleSubmenuClick(event) {
        try {
            event.preventDefault();

            if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

                // tslint:disable-next-line: prefer-const
                let ul = $(event.currentTarget.nextElementSibling);

                // hide other submenus
                // tslint:disable-next-line: prefer-const
                let parentNav = ul.parents('.sidebar-subnav');
                $('.sidebar-subnav').each((idx, el) => {
                    // tslint:disable-next-line: prefer-const
                    let $el = $(el);
                    // if element is not a parent or self ul
                    if (el !== parentNav[0] && el !== ul[0]) {
                        this.closeMenu($el);
                    }
                });

                // abort if not UL to process
                if (!ul.length) {
                    return;
                }

                // any child menu should start closed
                ul.find('.sidebar-subnav').each((idx, el) => {
                    this.closeMenu($(el));
                });

                // toggle UL height
                const ulHeight = ul.css('height');
                if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                    this.closeMenu(ul);
                } else {
                    // expand menu
                    ul.on('transitionend', () => {
                        ul.css('height', 'auto').off('transitionend');
                    }).css('height', ul[0].scrollHeight);
                    // add class to manage animation
                    ul.addClass('opening');
                }
            }
        } catch (exception) {
            console.error('', exception);
        }
    }

    // Close menu collapsing height
    closeMenu(elem) {
        try {

        } catch (exception) {
            console.error('', exception);
        }
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        try {
            // tslint:disable-next-line: prefer-const
            let self = this;
            if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
                event.preventDefault();

                this.removeFloatingNav();

                // tslint:disable-next-line: prefer-const
                let ul = $(event.currentTarget.nextElementSibling);
                // tslint:disable-next-line: prefer-const
                let anchor = $(event.currentTarget);

                if (!ul.length) {
                    return; // if not submenu return
                }

                // tslint:disable-next-line: prefer-const
                let $aside = $('.aside-container');
                // tslint:disable-next-line: prefer-const
                let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
                // tslint:disable-next-line: prefer-const
                let $sidebar = $asideInner.children('.sidebar');
                // tslint:disable-next-line: prefer-const
                let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
                // tslint:disable-next-line: prefer-const
                let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

                // tslint:disable-next-line: prefer-const
                let floatingNav = ul.clone().appendTo($aside);
                // tslint:disable-next-line: prefer-const
                let vwHeight = document.body.clientHeight;

                // let itemTop = anchor.position().top || anchor.offset().top;

                floatingNav
                    .addClass('nav-floating');

                // each item has ~40px height
                // multiply to force space for at least N items
                // tslint:disable-next-line: prefer-const
                let safeOffsetValue = (40 * 5);
                // tslint:disable-next-line: prefer-const
                let navHeight = floatingNav.outerHeight(true) + 2; // 2px border
                // tslint:disable-next-line: prefer-const
                let safeOffset = navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

                // tslint:disable-next-line: prefer-const
                let displacement = 25; // displacement in px from bottom

                // if not enough space to show N items, use then calculated 'safeOffset'
                // tslint:disable-next-line: prefer-const
                let menuTop = (vwHeight - itemTop > safeOffset) ? itemTop : (vwHeight - safeOffset - displacement);

                floatingNav
                    .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                    .css({
                        position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                        top: menuTop,
                        bottom: (floatingNav.outerHeight(true) + menuTop > vwHeight) ? (displacement + 'px') : 'auto'
                    });

                floatingNav
                    .on('mouseleave', () => { floatingNav.remove(); })
                    .find('a').on('click', function(e) {
                        e.preventDefault(); // prevents page reload on click
                        // get the exact route path to navigate
                        // tslint:disable-next-line: prefer-const
                        let routeTo = $(this).attr('route');
                        if (routeTo) {
                            self.router.navigate([routeTo]);
                        }
                    });

                this.listenForExternalClicks();

            }
        } catch (exception) {
            console.error('', exception);
        }
    }

    listenForExternalClicks() {
        try {
            // tslint:disable-next-line: prefer-const
            let $doc = $(document).on('click.sidebar', (e) => {
                if (!$(e.target).parents('.aside-container').length) {
                    this.removeFloatingNav();
                    $doc.off('click.sidebar');
                }
            });
        } catch (exception) {
            console.error('', exception);
        }
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }
    isSidebarCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
    isEnabledHover() {
        return this.settings.getLayoutSetting('asideHover');
    }
}
