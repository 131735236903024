import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Dialog} from "../../../../model/Dialog";

@Component({
  selector: 'app-basic-alert',
  templateUrl: './basic-alert.component.html',
  styleUrls: ['./basic-alert.component.scss']
})
export class BasicAlertComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog, public dialogRef: MatDialogRef<BasicAlertComponent>,) { }

  ngOnInit() {
  }

  cancelButtonClick(buttonCancel: string) {
    this.dialogRef.close(buttonCancel);
  }

  actionButtonClick(action: string) {
    this.dialogRef.close(action);
  }
}


