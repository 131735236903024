import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";

@Injectable()
export class EventsProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/invoice-issuer/v1/event/"+localStorage.getItem('userId');


  getEventsById(id: number) {
    return new Promise((resolve, reject) => {

      this.http.get(`${environment.apiLogin}${this.apiBase}/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } } )
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
}