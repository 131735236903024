import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import Swal from 'sweetalert2';
import { OpnatureProvider } from 'src/providers/nfe/opnature';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastro-naturezaop',
  templateUrl: './cadastro-naturezaop.component.html',
  styleUrls: ['./cadastro-naturezaop.component.scss']
})
export class CadastroNaturezaopComponent implements OnInit {

  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;
  @Input() public opnatureExistente;


  editar: boolean = false;
  opnatureForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;
  natExistente;
  public isShared: boolean = false;
public selectOp;
public selectPur;
  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public OpnatureProvider: OpnatureProvider, 
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    this.opnatureForm = this.fb.group({
      description: ['', Validators.required],
      operationType: ['', Validators.required],
      purposeIssue: ['', Validators.required],
    });
   // Seta os valores no form caso editar = true 
   if(this.editar){
    this.opnatureForm.controls.description.setValue( this.natExistente.description)
     //Faz a conversão para o back (GAMBIARRA pra poupar trabalho do guilherme)
    if(this.natExistente.operationType == "Entrada"){
 this.selectOp ="ENTRADA";
    }if(this.natExistente.operationType == "Saída"){
      this.selectOp ="SAIDA";
    }

    if(this.natExistente.purposeIssue == "NF-e normal"){
       this.selectPur = "NORMAL";
    }if(this.natExistente.purposeIssue == "NF-e complementar"){
      this.selectPur = "COMPLEMENTAR";
   }if(this.natExistente.purposeIssue == "NF-e ajuste"){
    this.selectPur = "AJUSTE";
 }if(this.natExistente.purposeIssue == "Devolução/retorno"){
  this.selectPur = "RETORNO";
} 
   }
   this.opnatureForm.controls.operationType.setValue(this.selectOp)
   this.opnatureForm.controls.purposeIssue.setValue(this.selectPur)
   this.isShared = this.natExistente.isShared;
  }


  configForm() {
  }

  checkExist(){
  }

  setOpNatureObj(){
    let obj = this.opnatureForm.value;
    obj.isShared = this.isShared;
    if(this.editar){
      obj['id'] = this.natExistente.id;
    }
    return obj;
  }

  newOpnature() {
  this.enviado = true;
  if(!this.opnatureForm.value.description || !this.opnatureForm.value.operationType || !this.opnatureForm.value.purposeIssue){

  }else{
 this.enviando = true;
   this.OpnatureProvider.postOPNature(this.setOpNatureObj()).then((result) => {
     this.activeModal.close('success');
     this.enviando = false;
    }).catch(err => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
   }
  }
//Faz um put para em casos de edição e faz a gambiarra pra poupar trabalho do guilherme
  editOpnature() {
    this.enviando = true;
    if(this.natExistente.operationType == "Entrada"){
      this.opnatureForm.value.operationType ="ENTRADA";
         }if(this.natExistente.operationType == "Saída"){
           this.opnatureForm.value.operationType ="SAIDA";
         }
    if(!this.opnatureForm.value.purposeIssue){
      if(this.natExistente.purposeIssue = "NF-e normal"){
        this.opnatureForm.value.purposeIssue = "1";
      }if(this.natExistente.purposeIssue = "NF-e complementar"){
        this.opnatureForm.value.purposeIssue = "COMPLEMENTAR";
      }if(this.natExistente.purposeIssue = "NF-e de ajuste"){
        this.opnatureForm.value.purposeIssue = "AJUSTE";
      }if(this.natExistente.purposeIssue = "Devolução/Retorno"){
          this.opnatureForm.value.purposeIssue = "4";
      }
    
    }
   this.OpnatureProvider.putOPNature(this.setOpNatureObj()).then((result) => {
     this.activeModal.close('success');
     this.enviando = false;
    }).catch(err => {
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }


}
