export class Pessoa {
  public id: number;
  public lcDigital: boolean;
  public name: string;
  public lastName: string;
  public cpf: string;
  public cnpj: string;
  public email: string;
  public nome: string;
  public telefone: string;
  public ie: string;
  office: {
    id: number;
    name: string;
  };
  public companyIds: number[];
  roles: any[];
}
