import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {Bank} from "../../../model/basicos/Bank";
import {Imovel} from "../../../model/Imovel";

@Injectable()
export class PropertyProvider {

  apiProperty: string = "/invoice-issuer/v1/issuer/"+localStorage.getItem('userId')+"/property/";

  setApiBase(){
    this.apiProperty = "/invoice-issuer/v1/issuer/"+localStorage.getItem('userId')+"/property/";
  }

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllProperty() {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiProperty + 'by_company/' + localStorage.getItem('idEmpresa'),
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getPropertyById(idAccount: number) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiProperty + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postProperty(account: Imovel) {
    this.setApiBase();
    //console.log"account?",account);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiProperty, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putProperty(account) {
    this.setApiBase();
    //console.log"account?",account);
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiProperty, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Bank[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteProperty(idAccount: string) {
    this.setApiBase();
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiProperty+ localStorage.getItem('idEmpresa') + "/" + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
