import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AmountDTOResponse} from '../../../../../../model/dto/AmountDTOResponse';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Snackbar} from '../../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../../util/error';
import {UnitProvider} from '../../../../../../providers/produto/unidade';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastro-unidade',
  templateUrl: './cadastro-unidade.component.html',
  styleUrls: ['./cadastro-unidade.component.scss']
})

export class CadastroUnidadeComponent implements OnInit {

  @Input() public unidadeExistente;
  @Input() enableClearOnEscapePressed = true;


  unidadeForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;
  unidade: AmountDTOResponse = new AmountDTOResponse();
  editar: boolean = false;
  public isShared: boolean = false;
  row;
  enviandoRequisicao: Boolean = false;
  
  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackbar: Snackbar,
              public erroUtil: ErrorUtil,
              public unitProvider: UnitProvider, 
              public checkPermission: CheckPermission) {
    //super();
  }

  ngOnInit() {
    try{
      this.unidadeForm = this.fb.group({
      description: ['', Validators.required],
      });
    } catch (error) {console.error(error)}
    if(this.editar){
      this.unidadeForm.controls.description.setValue(this.unidadeExistente.description)
      this.isShared = this.unidadeExistente.isShared;
    }
  }

  setUnidadeObj(){
    let obj = {
      description: this.unidadeForm.value.description,
      isShared: this.isShared
    }
    if(this.editar){
      obj['id'] = this.unidadeExistente.id;
    }
    return obj;
  }

  newUnidade() {
    this.enviado = true;
    if(this.unidadeForm.valid){
      this.enviandoRequisicao = true;
    this.unitProvider.postUnit(this.setUnidadeObj()).then((result) => {
     this.activeModal.close(result);
     this.enviandoRequisicao = false;
     this.snackbar.open('Unidade cadastrada com sucesso!', 'success');
    }).catch(err => {
      this.enviandoRequisicao = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });}

  }

  editUnidade() {
   if(this.unidadeForm.valid){
    this.enviandoRequisicao = true;
   this.unitProvider.putUnit(this.setUnidadeObj()).then((result) => {
      this.enviandoRequisicao = false;
     this.snackbar.open('Unidade editada com sucesso!', 'success');
     this.activeModal.close('success');
    }).catch(err => {
      this.enviandoRequisicao = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  }
  }
}
