import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MenuService } from "src/app/core/menu/menu.service";
import { menuNotaFiscalContador } from "../../menu";

@Component({
  selector: "app-select-produtor",
  templateUrl: "./select-produtor.component.html",
  styleUrls: ["./select-produtor.component.scss"],
})
export class SelectProdutorComponent implements OnInit {
  data;

  dataSource: MatTableDataSource<any>;
  displayedColumns = [];

  constructor(
    public menuService: MenuService,
    public router: Router,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.displayedColumns = ["issuerId", "name", "acesso"];
    this.dataSource = this.data;
  }

  accessCompany(value) {
    if (
      localStorage.getItem("userIdOriginal") == localStorage.getItem("userId")
    ) {
      this.menuService.addMenu(menuNotaFiscalContador, 3);
    }
    this.activeModal.close();
    localStorage.setItem("userId", this.dataSource[value].issuerId.toString());
    this.router.navigate(["notafiscal/notasFiscais"]);
  }
}
