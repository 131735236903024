import {Component, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockNotaFiscalComponent implements OnInit {
  usuario: any;
  user: any;
  public nome: string;

  constructor(public userblockService: UserblockService,
              private oauthService?: OAuthService) {

    this.usuario = this.givenName();

    this.user = {
      picture: 'assets/img/user/02.png'
    };

  }

  ngOnInit() {
  }

  givenName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
        return null;
    }
    // tslint:disable-next-line: no-string-literal
    return claims['principal'];
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

}
