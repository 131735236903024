import * as moment from "moment";
import {Injectable} from "@angular/core";
@Injectable()
export class DatePicker {
  ranges: any = {
    'Este mês': [moment().startOf('month'), moment().endOf('month')],
    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Próximo mês':  [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
  }
  docranges: any = {
    'Este mês': [moment().startOf('month'), moment().endOf('month')],
    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Ontem':  [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
    'Hoje':  [moment().startOf('day'), moment().endOf('day')]
  }
  lcdprRanges: any = {
    'Ano passado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  }
  locale = { daysOfWeek: moment.localeData('pt-BR').weekdaysMin(),
  monthNames: moment.localeData('pt-BR').monthsShort() };

}
