import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {CheckPermission} from './checkPermission';

@Injectable()
export class AuthGuardNotaFiscalService implements CanActivate {
  constructor(public router: Router, private oauthService: OAuthService, private checkPermision: CheckPermission) {}
  canActivate(): boolean {
    //console.logthis.router);
    if (!this.oauthService.hasValidAccessToken || !this.verificaPermissao()) {
        this.router.navigate(['login']);
        return false;
      }

    return true;
  }

  private givenAuthorities(claim) {
    const claims = this.oauthService.getIdentityClaims();
    return !claims ? null : claims[claim];
  }

  private verificaPermissao() {
    return this.checkPermision.isSuperuser() || this.checkPermision.isFarmer() || this.checkPermision.isAccountant();
  }

  private allowCompany(): boolean {
    if (localStorage.getItem('allow') == null) {
      this.router.navigate(['manutencao']);
    }

    const companyId = this.givenAuthorities('details').office.id;
    if (companyId === undefined) {
      return true;
    } else {
      const list = JSON.parse(localStorage.getItem('allow'));
      return list.findIndex(val => val === companyId) >= 0;
    }
  }
}

