import {Injectable} from '@angular/core';

@Injectable()
export class MenuService {

  menuItems: Array<any>;
  menuItemsContador: any[];
  menuItemsNotaFiscal: any[];
  menuItemsConsultor: any[];

  constructor() {
    this.menuItems = [];
    this.menuItemsContador = [];
    this.menuItemsNotaFiscal = [];
    this.menuItemsConsultor = [];
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    parentAsTarget?: boolean,
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>,     type: number) {
    this.menuItems = [];
    this.menuItemsContador = [];
    this.menuItemsNotaFiscal = [];
    this.menuItemsConsultor = [];
    items.forEach((item) => {
      // tslint:disable-next-line: triple-equals
      // if (type == 1) {
      //   this.menuItems.push(item);
      // // tslint:disable-next-line: triple-equals
      // } else
        if (type == 2) {
        this.menuItemsContador.push(item);
      // tslint:disable-next-line: triple-equals
      } else if (type == 3) {
        this.menuItemsNotaFiscal.push(item);
      }
      else if (type === 4) {
        this.menuItemsConsultor.push(item);
      }
    });
  }

  getMenu() {
    return this.menuItems;
  }

  getMenuContador() {
    return this.menuItemsContador;
  }

  getMenuNotaFiscal() {
    return this.menuItemsNotaFiscal;
  }

  getMenuConsultor() {
    return this.menuItemsConsultor;
  }

}
