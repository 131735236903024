import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import * as moment from "moment";
import jsPDF from 'jspdf';
import {CadastroNaturezaopComponent} from "./cadastro-naturezaop/cadastro-naturezaop.component";
import { OpnatureProvider } from 'src/providers/nfe/opnature';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-opnature',
  templateUrl: './naturezaop.component.html',
  styleUrls: ['./naturezaop.component.scss']
})
export class NaturezaopComponent implements OnInit {
  displayedColumns = ['editar', 'excluir', 'isShared', 'description', 'tpnf', 'finnfe'];
  public formGroup: FormGroup;
  public natOP: OPnature[] = [];
  private nat: any[];
  dataSource: MatTableDataSource<OPnature>;
  erro: boolean = false;
  erroDescription: boolean = false;
  consulta: boolean = false;
  public op: any[];
  public carregando: Boolean = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private formBuilder: FormBuilder,
              public modalService: NgbModal,
              public dialog: MatDialog,
              public opnatureProvider: OpnatureProvider,
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    try {
      this.generateTable()
      //console.logthis.natOP)
      this.formGroup = this.formBuilder.group({
        description: ['', Validators.required],
        tpnf: ['', Validators.required],
        finnfe: ['', Validators.required]
      });
    } catch (exception) {
      console.error(exception);
    }
  }


// Carrega os valores de natOp na tabela
  loadTable() {
    this.dataSource = new MatTableDataSource(this.natOP);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

// Coloca as funções em ordem para renderizar a tabela
  generateTable(){
    this.carregando = true;
    this.natOP = [];
    this.getOpnature().then(() =>{
    this.loadTable();
    this.carregando = false;
    });
  }

// faz um GET para receber as naturezas no banco e as armazena em natOP
  getOpnature() {
    return new Promise((resolve, reject) => {

    this.opnatureProvider.getAllOPNatures().then((result) => {
      //console.log"testeteste", result);
      Object.keys(result).forEach((item) => {

        //console.log"teste2teste2", result[item])
        this.natOP.push(result[item]);
      });
      (this.natOP as any).sort((a, b) => {
        if (a.isShared === b.isShared) {
          return a.description.localeCompare(b.description);
        }
        return a.isShared ? -1 : 1;
      });
      //console.log"teste1000")
      resolve(this.natOP)
    }).catch(err => {
      this.carregando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // Função de downlaod do pdf com informações da tabela
  downloadPdf() {
    var prepare = [];
    this.natOP.forEach(e => {
      var tempObj = [];
      tempObj.push(e.id);
      tempObj.push(e.description);
      tempObj.push(e.operationType);
      tempObj.push(e.purposeIssue);
      prepare.push(tempObj);
    });
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
          align: 'center'
        })
      }
    }
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(10);
    doc.text(14, 10, 'NATUREZA DA OPERAÇÃO');
    doc.text(175, 10, moment().format('DD/MM/YY HH:mm').toString());
    doc.setLineWidth(0.3);
    doc.line(14, 12, 200, 12);
    doc.autoTable({
      head: [['ID', 'Natureza da operação', 'Tipo de operação', 'Finalidade de emissão']],
      body: prepare,
      headStyles: {
        fillColor: "#adacac",
        textColor: "#000000"
      },
    });
    addFooters(doc)

    doc.save('naturezadaoperacao' + '.pdf');
  }
// Exibe swal de confirmação caso o usuario confirme realiza requisição para deletar a operação
  deleteOpnature(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa natureza?',
      text: "Não há como reverter isso!",
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando= true;
        this.opnatureProvider.delOPNature(row.id).then((result) => {
         this.generateTable();
         Swal.fire(
          'Sucesso!',
         'Natureza da operação foi removida com sucesso!',
         'success'
      )
         }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });

      }
    });
  }
// Chama modal de CadastroNaturezaopComponent e passa as informações da natureza selecionada
  editOpnature(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroNaturezaopComponent, modalOptions);
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.natExistente = row;
    ////console.logrow);
    modalRef.result.then((result) => {
      //this.product.push(result);
      this.generateTable()
      Swal.fire({
        title: 'Sucesso!',
        text: "Natureza da operação editada com sucesso",
        icon: 'success',
        confirmButtonColor: '#3085d6',
      })
    }, () => {
    });
  }
// Chama modal de CadastroNaturezaopComponent sem informações adicionais
  newOpnature() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroNaturezaopComponent, modalOptions);
    modalRef.result.then((result) => {
      //this.op.push(result);
      this.generateTable()
      Swal.fire({
        title: 'Sucesso!',
        text: "Natureza da operação foi adicionada",
        icon: 'success',
        confirmButtonColor: '#3085d6',
      })
    }, () => {
      //this.generateTable()
    });
  }
}

class OPnature {
  id: number;
  description: string;
  operationType: string;
  purposeIssue: string;
}
