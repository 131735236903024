import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
  })

export class FooterNotaFiscalComponent implements OnInit {
    constructor(public settings: SettingsService) {}

    ngOnInit() {}

    getVersion() {
        return environment.appVersion;
    }
}
