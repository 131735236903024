export enum DocType {
  INVOICE="NOTA FISCAL", BILL="FATURA", RECEIPT="RECIBO", CONTRACT="CONTRATO", PAYROLL="FOLHA DE PAGAMENTO", OTHERS="OUTROS"
}

export enum DocTypeNumber {
  INVOICE, BILL, RECEIPT, CONTRACT, PAYROLL, OTHERS
}


export enum DocTypeNumberLCDPR {
  INVOICE = 1, BILL, RECEIPT, CONTRACT, PAYROLL, OTHERS
}
