import { Reboque } from "./Reboque";

export class Frete {
    placaVeiculo;
    ufVeiculo;
    ufVeiculoId;
    registroNacional;
    valorSeguro;
    reboque: any[] = [];
  }
  