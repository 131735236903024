import {Component, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import {OAuthService} from "angular-oauth2-oidc";

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  usuario: any;
  user: any;
  public nome: string;

  constructor(public userblockService: UserblockService, private oauthService: OAuthService) {

    this.usuario = this.givenName();

    if(this.usuario.authorities[0].authority == "ROLE_ACCOUNTANT_ADMIN" || this.usuario.authorities[0].authority == "ROLE_ACCOUNTANT_COLLABORATOR"){
      this.user = {
        picture: 'assets/img/user/02.png'
      };
    }else{
      this.user = {
        picture: 'assets/img/user/01.jpg'
      };
    }

  }

  ngOnInit() {
  }

  givenName() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['principal'];
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

}
