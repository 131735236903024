  /* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment.prod';
import {Pessoa} from '../../model/Pessoa';
import {Version} from '../../model/Version';
import {Router} from '@angular/router';


@Injectable()
export class UserProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService, private router: Router,) {
  }

  private apiBaseAddClient: string = "/user/v1/info/";
  private apiBase: string = "/user/v1/user_info/";
  private apiCollaborator: string = "/user/v1/user_info/collaborator/";
  private apiCompany: string = "/user/v1/company/";
  private apiCompanyCollaborator: string = "/user/v1/company_user/";
  private apiVersion: string = "/user/v1/front/version/";
  private apiAutoLog: string = "/auth/info";
  private apiPassword: string = "/auth/v1/user/reset-password";
  private apiParticipant: string = "";
  private  headers= new HttpHeaders()
    .set('Authorization', 'Bearer 18364205-9591-4265-bd9e-5d8118169dd6' )

  setApiParticipant(){
    this.apiParticipant = `/invoice-issuer/v1/participant/shared/${localStorage.getItem('userId')}`;
  }


  getLastVersion() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiVersion + 'last', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getInfoLogin(cookie) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiAutoLog, {headers:{Authorization: `Bearer ${cookie}`} })
        .subscribe((result: any) => {

          localStorage.setItem('access_token', cookie)
          localStorage.setItem('id_token_claims_obj', JSON.stringify(result))
          localStorage.setItem('idEmpresa', `${result.details.companyIds[0]}`);
          localStorage.setItem('userId',`${result.details.userId}`),
          localStorage.setItem('userIdOriginal',`${result.details.userId}`),

            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }


  getAllFarmerMembersByOffice() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + 'member', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


  getAllCompanys() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiCompany, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
          //console.log("ZAPPPPPPPP")
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


  getIssuers() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + '/auth/info', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          //console.log("ZAPPPPPPPP2222")
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putCompanyUser(idCompany: number, arrayCompanies: number[]){
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase + idCompany + "/role", arrayCompanies, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllCollaborators() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiCollaborator, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, colaborador, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  postEmissor(colaborador) {
    colaborador.id = parseInt(localStorage.getItem('userId'));
    //colaborador.taxRegime = "DOIS"
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + '/invoice-issuer/v1/issuer', colaborador, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  putDestinatario(dest) {
    this.setApiParticipant();
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiParticipant, dest, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  delEndereco(a){
    return new Promise((resolve, reject) => {
//console.loga)
      this.http.delete(environment.apiLogin + '/invoice-issuer/v1/issuer/'+localStorage.getItem('userId')+'/property/'+a, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postDestinatario(dest) {
    this.setApiParticipant();
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiParticipant, dest, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllDestinat(){
    this.setApiParticipant();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiParticipant, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getAllTaxRegimes(){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + '/invoice-issuer/v1/issuer/tax-regime-relation', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  delDestinat(dest){
    this.setApiParticipant();
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiParticipant + `/${dest.id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getEmitente(id){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + '/invoice-issuer/v1/issuer/'+id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            console.log("error")
            this.oauthService.logOut(true);
            this.router.navigate(['login']);
           // reject(error);
          });

    });
  }

  getUser(id){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase+id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            console.log("error")
            this.oauthService.logOut(true);
            this.router.navigate(['login']);
           // reject(error);
          });

    });
  }

  postPropriedade(prop){
    //console.log"oi")
    return new Promise((resolve, reject) => {
      //console.log"NOVA PROPRIEDADE PORRAAAAAAAAAAAA",prop)
      this.http.post(environment.apiLogin + '/invoice-issuer/v1/issuer/'+localStorage.getItem('userId')+'/property', prop, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  postNewClient(colaborador) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBaseAddClient, colaborador, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, colaborador, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putUserDetails(userDetails) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase + 'me', userDetails, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putUserPassword(newPassword) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase + 'password/me', newPassword, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + colaborador.id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postCompanyCollaborator(idCollaborator, idCompany: any[]) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiCompanyCollaborator + idCollaborator, idCompany, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delCompanyCollaborator(idCollaborator, idCompany) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiCompanyCollaborator + idCollaborator + '/' + idCompany, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postNewPassword(email) {
    //console.log(email.email);
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiPassword}-new?platform=emissor&email=${email.email}`,{headers: { Authorization: this.oauthService.authorizationHeader() }})
        .subscribe((result) => {
            resolve(result);
          },(error) => {
            reject(error);
          }
        );
    });
  }

  resetPassword(token) {
    //console.log(token)
    //console.log("o endpoint",environment.apiLogin + this.apiPassword + "-after-token?token=" + token )
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiPassword}-after-token?token=${token}`,{headers: { Authorization: this.oauthService.authorizationHeader() }})
        .subscribe((result) => {
            resolve(result);
          },(error) => {
            reject(error);
          }
        );
    });
  }

  newResetPassword(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiPassword}-after-token-new`, obj)
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getCollaboratorIssuers(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + '/invoice-issuer/v1/issuer/list-issuer-by-collaborator', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
}
