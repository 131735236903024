import { AuthConfig } from 'angular-oauth2-oidc';
import {environment} from "../environments/environment";

export const authPasswordFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  tokenEndpoint: environment.apiLogin + '/auth/oauth/token',
  //
  userinfoEndpoint: environment.apiLogin + '/auth/info',

  clientId: 'client',

  dummyClientSecret: 'xu231i[IYDVHr;fEL,R*+#l(B}n+V<nw/_gTBFfOy0[?_f3StL',

  silentRefreshRedirectUri: "/assets/silent-refresh.html",

  silentRefreshTimeout: 5000, // For faster testing

  timeoutFactor: 0.25, // For faster testing

  requireHttps: environment.requireHttps,

  useHttpBasicAuth: true,

  scope: 'all',



  showDebugInformation: false,

  oidc: false

};
