import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AmountDTOResponse} from '../../../../../../model/dto/AmountDTOResponse';
//import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {Snackbar} from '../../../../../../util/snackbar';
import {ErrorUtil} from 'src/util/error';
import {ProductDTOResponse} from '../../../../../../model/dto/ProductDTOResponse';
import {CadastroUnidadeComponent} from '../../unidades/cadastro-unidade/cadastro-unidade.component';
import {ProductProvider} from 'src/providers/nfe/product';
import {UnitProvider} from 'src/providers/produto/unidade';
import Swal from 'sweetalert2';
import {CheckPermission} from 'src/service/checkPermission';
import {MatAutocompleteTrigger} from "@angular/material";

@Component({
  selector: 'app-cadastro-produto',
  templateUrl: './cadastro-produto.component.html',
  styleUrls: ['./cadastro-produto.component.scss']
})
export class CadastroProdutoComponent implements OnInit {

  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;

  editar: boolean = false;
  produtoForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;
  unidade: AmountDTOResponse = new AmountDTOResponse();
  produto: ProductDTOResponse = new ProductDTOResponse();
  public isShared: boolean = false;
  public amount: any[];
  enviandoRequisicao: Boolean = false;
  @Input() public unitSelect;
  @Input() public produtoExistente;
  @Input() public produtos;
  @Input() public unidades: Unidade[] = [];

  // @ts-ignore
  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public snackBar: Snackbar,
              public fb: FormBuilder,
              public erroUtil: ErrorUtil,
              public productProvider: ProductProvider,
              public unitProvider: UnitProvider,
              public checkPermission: CheckPermission
  ) {}

  ngOnInit() {
    try {
      this.getUnidades();
      this.produtoForm = this.fb.group({
        code: [''],
        description: ['', Validators.required],
        unitId: ['', Validators.required],
        unitTributaryId: ['', Validators.required],
        ncm: ['', Validators.required],
        gtin: ['', Validators.required],
        origin: ['', Validators.required],
      });
    } catch (exception) {
      console.error(exception);
    }
    if (this.editar == true) {
      this.setForm();
    }
  }

  setForm() {
    this.produtoForm.patchValue({
      code: this.produtoExistente.code,
      description: this.produtoExistente.description,
      unitId: this.produtoExistente.unit.id,
      unitTributaryId: this.produtoExistente.unitTributary ? this.produtoExistente.unitTributary.id : this.produtoExistente.unit.id,
      gtin: this.produtoExistente.gtin,
      origin: this.produtoExistente.productOrigin.code.toString(),
      ncm: this.produtoExistente.ncm,
    })
    this.isShared = this.produtoExistente.isShared;
    this.setUnidades(this.produtoExistente.unitTributary, 'tributary');
  }

  resetModal() {
    this.enviado = false;
    this.enviando = false;
    this.editar = false;
    this.produtoForm.reset();
    this.setForm();
  }

  getUnidades() {
    this.unidades = [];
    return new Promise((resolve, reject) => {
      this.unitProvider.getUnit().then((result: Unidade) => {
        Object.keys(result).forEach((item) => {
          this.unidades.push(result[item]);
        });
      }).catch((result) => {
        reject(result);
      });
    });
  }

  setUnidades(value, type) {
    if (type == 'unit') {
      this.produtoForm.get('unitId').setValue(value.id);
    } else if (type == 'tributary') {
      this.produtoForm.get('unitTributaryId').setValue(value.id);
    }
  }

  newUnidade(type) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroUnidadeComponent, modalOptions);
    modalRef.result.then((result) => {
      this.getUnidades();
      this.setUnidades(result, type);
    }, () => {
    });
  }

  postProduto(obj, close?) {
    this.productProvider.postProduct(obj).then((result) => {
      this.enviandoRequisicao = false;
      this.enviado = false;
      this.enviando = false;
      Swal.fire({
        title: 'Sucesso!',
        text: 'Produto cadastrado com sucesso.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        if (close) {
          this.activeModal.close();
        } else {
          this.produtoExistente = result;
          this.resetModal();
        }
      });
    }).catch(err => {
      this.enviandoRequisicao = false;
      this.enviado = false;
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    });
  }


  putProduto(obj, close?) {
    this.productProvider.putProduct(obj).then((result) => {
      this.enviandoRequisicao = false;
      this.enviado = false;
      this.enviando = false;
      Swal.fire({
        title: 'Sucesso!',
        text: 'Produto atualizado com sucesso.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        if (close) {
          this.activeModal.close('success');
        } else {
          this.resetModal();
        }
      });
    }).catch(err => {
      this.enviandoRequisicao = false;
      this.enviado = false;
      this.enviando = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    });

  }

  submitForm() {
    this.enviando = true;
    this.enviandoRequisicao = true;
    this.enviado = true;
    if (this.produtoForm.valid) {
      let obj = this.produtoForm.value;
      obj.isShared = this.isShared;
      if (this.editar) {
        obj.id = this.produtoExistente.id;
        this.putProduto(obj, true);
      } else {
        this.postProduto(obj, true);
      }
    } else {
      this.enviandoRequisicao = false;
      this.enviando = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios', 'erro');
    }
  }

  submitAndKeepData() {
    this.enviando = true;
    this.enviado = true;
    this.enviandoRequisicao = true;
    if (this.produtoForm.valid) {
      let obj = this.produtoForm.value;
      if (this.editar) {
        obj.id = this.produtoExistente.id;
        this.putProduto(obj, false);
      } else {
        this.postProduto(obj, false);
      }
    } else {
      this.enviando = false;
      this.enviandoRequisicao = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios', 'erro');
    }
  }
}
