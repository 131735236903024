import { Component, Input, OnInit } from '@angular/core';
import {NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-escolha-de-prop',
  templateUrl: './escolha-de-prop.component.html',
  styleUrls: ['./escolha-de-prop.component.scss']
})
export class EscolhaDePropComponent implements OnInit {

  public returnedArray = [];
  public contentArray = [];
  @Input() public EnderecoRecebido = [];

  constructor(

    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit() {
    //this.activeModal.
    try{
    this.EnderecoRecebido.forEach((element) =>{
      
      element.enderecos.forEach((element2) =>{
      
        element2.cpfCnpj = element.cpfCnpj;
      
        this.contentArray.push(element2);
      })
  })
    }catch(error)
    {

    }
  }
  selecionarEndereco(a){
    this.activeModal.close(a);
  }
  pageChanged(event): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.contentArray.slice(startItem, endItem);
 }
}
