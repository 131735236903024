import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {CheckPermission} from "./checkPermission";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private oauthService: OAuthService, private checkPermision: CheckPermission) {}
  canActivate(): boolean {
    if (!this.oauthService.hasValidAccessToken() || !this.verificaPermissao()) {
      this.router.navigate(['login']);
      return false;
    }
      return true;
  }

  private givenAuthorities(claim) {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims[claim];

  }

  private verificaPermissao() {
    // tslint:disable-next-line: max-line-length
    return (this.checkPermision.isAccountant() || this.checkPermision.isSuperuser() ||  this.checkPermision.isFarmer() || this.checkPermision.isConsultant());
    // return this.checkPermision.isAccountant() || this.checkPermision.isSuperuser() ||  this.checkPermision.isFarmer();
  }

  private allowCompany(): boolean {
    if(localStorage.getItem('allow') == null){
      this.router.navigate(['manutencao']);
    }
    // //console.logthis.givenAuthorities('details'));
    let companyId = this.givenAuthorities('details').office.id;
    if (companyId == undefined) {
      return true;
    } else {
      let list = JSON.parse(localStorage.getItem('allow'));
      // //console.loglist.findIndex(val => val == companyId));
      return list.findIndex(val => val == companyId) >= 0;

    }
  }

}

