/* tslint:disable:quotemark */
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkThemePickerService {

  constructor() { }

  setTheme() {
    setTimeout(() => {
      this.selectTheme(localStorage.getItem('theme') === 'dark');
    }, 100);
  }

  selectTheme(theme: boolean) {
    document.getElementsByClassName("section-container")[0].classList.toggle("dark-theme", theme);
    document.getElementsByClassName("footer-container")[0].classList.toggle("dark-theme", theme);
    // document.getElementsByTagName("app-layout")[0].classList.toggle("dark-theme", theme);
    document.body.classList.toggle("dark-theme-body", theme);
    localStorage.setItem('theme', theme ? 'dark' : 'light');
  }
}
