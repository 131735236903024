import {Injectable} from "@angular/core";
import {Estate} from "../model/basicos/Estate";
import {City} from "../model/basicos/City";
import {Country} from "../model/basicos/Country";
import {CitiesProvider} from "../providers/basicos/cities";
import {StatesProvider} from "../providers/basicos/states";
import {CountriesProvider} from "../providers/basicos/countries";

@Injectable()
export class EstadosMunicipiosUtil {

  estados: Estate[];
  cidades: City[];
  cidadesAux: City[];
  paises: Country[];

  constructor(
    public citiesProvider: CitiesProvider,
    public statesProvider: StatesProvider,
    public countriesProvider: CountriesProvider
  ) {
  }

  setCountry() {
    if (localStorage.getItem("paises") == null) {
      this.countriesProvider.getCountry().then((paises: Country[]) => {
        localStorage.setItem('paises', JSON.stringify(paises));
        this.paises = paises;
        this.paises.sort(function (a, b) {
          return removeAcento(a.name) < removeAcento(b.name) ? -1 : removeAcento(a.name) > removeAcento(b.name) ? 1 : 0;
        });
        this.setState();
      });
    } else {
      this.paises = JSON.parse(localStorage.getItem("paises"));
      this.paises.sort(function (a, b) {
        return removeAcento(a.name) < removeAcento(b.name) ? -1 : removeAcento(a.name) > removeAcento(b.name) ? 1 : 0;
      });
      this.setState();
    }
  }


  setState() {
    if (localStorage.getItem("estados") == null) {
      this.statesProvider.getAllStates().then((estados: Estate[]) => {
        localStorage.setItem('estados', JSON.stringify(estados));
        this.estados = estados;
        estados.forEach((estado: Estate) => {
          this.setCity(estado.id);
        });
        this.estados.sort(function (a, b) {
          return removeAcento(a.name) < removeAcento(b.name) ? -1 : removeAcento(a.name) > removeAcento(b.name) ? 1 : 0;
        });
      });
    } else {
      this.estados = JSON.parse(localStorage.getItem("estados"));
      this.estados.sort(function (a, b) {
        return removeAcento(a.name) < removeAcento(b.name) ? -1 : removeAcento(a.name) > removeAcento(b.name) ? 1 : 0;
      });
    }
  }

  setCity(uf: any) {

    this.estados.forEach((estado: Estate, index: number) => {
      if (estado.id == uf) {
        if (estado.cities != undefined) {
          this.cidades = estado.cities;
          this.cidadesAux = estado.cities;
        } else {
          this.citiesProvider.getCityByState(uf).then((cidades: City[]) => {
            this.cidades = cidades;
            this.cidadesAux = cidades;
            estado.cities = cidades;
            localStorage.setItem('estados', JSON.stringify(this.estados));
          });
        }
      }
    });
  }

}

function removeAcento(text) {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text;
}
