import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    valForm: FormGroup;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  historicCtrl = new FormControl();
  filteredHistorics: Observable<string[]>;
  historics: string[] = [];
  historico: string;
  allHistorics: string[] = ['Pagamento', 'Participante', 'Imovel', 'Lançamento referente', 'dfe'];

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

    constructor(public settings: SettingsService, fb: FormBuilder) {
        this.valForm = fb.group({
            email: [null, Validators.compose([Validators.required, CustomValidators.email])]
        });
        this.filteredHistorics = this.historicCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filterHistoric(fruit) : this.allHistorics.slice()));
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
      // tslint:disable-next-line:forin
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            //console.log'Valid!');
            //console.logvalue);
        }
    }

    ngOnInit() {
    }

  addHistoric(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.historics.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.historicCtrl.setValue(null);
  }

  removeHistoric(fruit: string): void {
    const index = this.historics.indexOf(fruit);

    if (index >= 0) {
      this.historics.splice(index, 1);
    }
  }

  selectedHistoric(event: MatAutocompleteSelectedEvent): void {
    this.historics.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.historicCtrl.setValue(null);
  }

  private _filterHistoric(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allHistorics.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  genereteHistoric() {
      if (this.historics.length !== 0) {
        let historic = '';
        this.historics.forEach(option => {
          historic = historic + ' ' + option;
        });
        //console.loghistoric);
        this.historico = historic;
      }
  }
}
