import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {RascunhosProvider} from '../../../../../providers/nfe/rascunhos';
import {Router} from '@angular/router';
import {CheckPermission} from "../../../../../service/checkPermission";
import {NovaNotaFiscalComponent} from "../../emitirNovaNotaFiscal/emitirNovaNotaFiscal";
import {SharedService} from "../../../../../service/shared";

@Component({
  selector: 'app-rascunhos',
  templateUrl: './rascunhos.component.html',
  styleUrls: ['./rascunhos.component.scss']
})
export class RascunhosComponent implements OnInit {

  public dataSource: MatTableDataSource<any>;
  public displayedColumns = ['editar', 'excluir', 'id', 'isShared', 'description'];

  public rascunhos: any = [];

  public panelExpanded: boolean = false;
  public carregando: boolean = false;
  public enviandoRequisicao: boolean = false;

  public emitirNovaNotaFiscalComponent = NovaNotaFiscalComponent;
  public isEmitirNovaNotaFiscalActive: boolean = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(public modalService: NgbModal,
              public router: Router,
              private sharedService: SharedService,
              public rascunhosProvider: RascunhosProvider,
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    try {
      this.generateTable();
    } catch (exception) {
      console.error(exception);
    }
  }

  generateTable() {
    this.carregando = true;
    this.rascunhos = [];
    this.getRascunhos().then(() => {
      this.loadTable();
      this.carregando = false;
    }).catch(err => console.log(err));
  }

  loadTable() {
    this.dataSource = new MatTableDataSource<any>(this.rascunhos);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.dataSource._orderData(this.dataSource.data);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getRascunhos() {
    return new Promise((resolve, reject) => {
      this.rascunhosProvider.getDrafts().then((result) => {
        this.rascunhos = result;
        resolve(true);
      }).catch(err => {
        this.carregando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        reject(err);
      });
    });
  }

  newRascunhos() {
    this.enviandoRequisicao = true;
    this.isEmitirNovaNotaFiscalActive = true;
    let params = {draft: true};
    this.sharedService.changeData(params);
    // this.router.navigateByUrl('/notafiscal/emitir-nova-nota-fiscal', {state: {loading: true, draft: true}, replaceUrl: false});
  }

  editRascunhos(row: any) {
    if (row.isShared == true && !this.checkPermission.canSetSharedItem()) {
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6'
      });
      return;
    }
    this.enviandoRequisicao = true;
    this.isEmitirNovaNotaFiscalActive = true;
    let params = {
      draft: true,
      editDraft: true,
      obj: row
    };
    this.sharedService.changeData(params);

    // this.router.navigateByUrl('/notafiscal/emitir-nova-nota-fiscal', {
    //   state: {
    //
    //   }
    // });
  }

  deleteRascunho(row: any) {
    if (row.isShared == true && !this.checkPermission.canSetSharedItem()) {
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6'
      });
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar?',
      text: 'Não há como reverter isso!',
      reverseButtons: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#f05050',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando = true;
        this.rascunhosProvider.deleteDraft(row.id).then(() => {
          this.generateTable();
          Swal.fire(
            'Sucesso!',
            'O rascunho foi deletado!',
            'success'
          );
        }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        });
      }
    });
  }

  formatDate(date: string) {
    let dateObj = new Date(date);
    return dateObj.toLocaleDateString('pt-BR');
  }
}
