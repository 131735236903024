import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment";
import {AccountComponent} from "../../model/dto/AccountComponent/AccountComponent";

@Injectable()
export class BackupProvider {

  constructor(public http: HttpClient, private oauthService: OAuthService) {
  }

  apiBase: string = environment.apiLogin + "/backup/v1/";

  getOfficeBackup() {
    return new Promise((resolve, reject) => {

      this.http.get(this.apiBase + 'generate', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putRestoreBackup(companyId, file) {
    return new Promise((resolve, reject) => {

      this.http.put(this.apiBase + '?companyId=' + companyId, file, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}

