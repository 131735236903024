import {Component, OnInit, ViewChild} from '@angular/core';
import {DatePicker} from '../../../../util/datePicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ReceiverProvider} from '../../../../providers/nfe/receiver';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {AggregateRowModel} from '@syncfusion/ej2-grids/src/grid/models/models';
import {MatPaginator} from '@angular/material/paginator';
import {FilterSettingsModel, SelectionSettingsModel} from '@syncfusion/ej2-grids/src/grid/base/grid-model';
import {DateUtil} from '../../../../util/dateUtil';
import {GridComponent, SelectionService} from '@syncfusion/ej2-angular-grids';
import Swal from 'sweetalert2';
import {Snackbar} from 'src/util/snackbar';
import {PdfViewerComponent} from '../pdf-viewer/pdf-viewer.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {MatSort} from '@angular/material/sort';
import {CancelarNotaFiscalComponent} from './cancelar-nota-fiscal/cancelar-nota-fiscal.component';
import {EditarNotaFiscalComponent} from './editar-nota-fiscal/editar-nota-fiscal.component';
import {Router} from '@angular/router';
import {ModalEventsComponent} from './modal-events/modal-events.component';
import {EventsProvider} from 'src/providers/events/events';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import { UserProvider } from 'src/providers/user/user';
import { ConsultaPdfXml } from 'src/providers/nfe/consultaPdfXml';

@Component({
  selector: 'app-notasfiscais',
  templateUrl: './notasFiscais.component.html',
  styleUrls: ['./notasFiscais.component.scss'],
  providers: [SelectionService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class NotasFiscaisComponent implements OnInit {
  //public displayedColumns = ['id','number','participantName','description', 'fromDate','status', 'key'];
  public data: Object[] = [];
  public notasFiscais;
  public enviando = true;
  public aggregates: AggregateRowModel[];
  public filterSettings: FilterSettingsModel;
  public nfTypes = [0, 0, 0];
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public activeMonth: any;
  public years: any;
  public docsVisible = true;
  public dfeSelected = [];
  public selected = [];
  public year: any;
  public links = ['Mensal', 'Personalizado'];
  public activeLink = 'Mensal';
  public showNotasReprovadas: boolean = false;
  //public activeMonth:any
  public mensal: boolean = true;
  public selectedAll: boolean = false;
  public dateRange = new ConsultDate();
  public selectionSettings: SelectionSettingsModel;
  public dataSource: MatTableDataSource<NotaFiscal>;
  public keyword: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('grid', {static: false}) public grid: GridComponent;

  constructor(
    public datepicker: DatePicker,
    public dateUtil: DateUtil,
    public snackBar: Snackbar,
    public http: HttpClient,
    private brMasker: BrMaskerIonicServices3,
    private eventsProvider: EventsProvider,
    // private donwloadLotePdf: DonwloadLote,
    private receiverProvider: ReceiverProvider,
    private _adapter: DateAdapter<any>,
    public modalService: NgbModal,
    public router: Router,
    public userProvider: UserProvider,
    public consultaPdfXml: ConsultaPdfXml) {
  }

  ngOnInit() {
    this._adapter.setLocale('pt-BR');
    this.filterSettings = {type: 'Menu'};
    this._adapter.setLocale('pt-BR');
    let workDate = new Date((new Date().toISOString()).slice(0, -1) + '+03:00').toISOString().slice(0, 19).slice(0, 10);

    this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1]; // set month
    // //console.log("aaa",+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1)
    this.dateRange.startDate = moment(workDate).locale('pt-BR').startOf('year').format('YYYY-MM-DD');
    this.dateRange.endDate = moment(workDate).locale('pt-BR').endOf('year').format('YYYY-MM-DD');

    this.year = +moment.tz(workDate, 'America/Sao_Paulo').format('YYYY'); // set year
    this.years = getYears(+moment().format('YYYY') - 20);
    this.makeRequestMensal();
    this.data = this.notasFiscais;
    this.enviando = false;
    this.selectionSettings = {persistSelection: true, type: 'Multiple', checkboxOnly: true};
  }

  tabs(link: string) {
    if (link === 'Mensal') {
      this.mensal = true;
      this.makeRequestMensal();
    } else {
      this.mensal = false;
      this.makeRequestPersonalizado();
    }
    this.selectedAll = false;
    this.selected = [];
  }

  changeYears() {
    this.makeRequestMensal();
  }

  activeMonthChange(event) {
    this.activeMonth = this.months[event];
    this.makeRequestMensal();
  }

  makeRequestMensal() {
    this.selectedAll = false;
    this.selected = [];
    const month = this.getActiveMonth(this.activeMonth);
    const date = this.year + '-' + month + '-' + '01';
    const consultDate = new ConsultDate();
    consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
    consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
    this.enviando = true;
    this.getFarmerUserPessoaFiscal(consultDate.startDate, consultDate.endDate).then(() => {
      this.enviando = false;
    });
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, 400);
  }

  setShowReprovadas(value) {
    this.showNotasReprovadas = value;
    this.mensal ? this.makeRequestMensal() : this.makeRequestPersonalizado();
  }

  makeRequestPersonalizado() {
    this.selectedAll = false;
    this.selected = [];
    this.enviando = true;
    this.dateRange.startDate = moment(this.dateRange.startDate).locale('pt-BR').format('YYYY-MM-DD');
    this.dateRange.endDate = moment(this.dateRange.endDate).locale('pt-BR').format('YYYY-MM-DD');
    this.getFarmerUserPessoaFiscal(this.dateRange.startDate, this.dateRange.endDate).then(() => {
      //this.loadTable();
      this.enviando = false;
    });
  }

  getFileDfe(id, type, location) {
    this.enviando = true;
    var nota = this.notasFiscais.filter(obj => {
      return obj.id == id;
    });
    this.consultaPdfXml.getXml({ chave: nota[0].key }).then((resultXml: any) => {
      this.enviando = false;
      switch (type) {
        case 'xml': {
          this.getXml(nota[0].key, resultXml.xml);
          break;
        } case 'pdf': {
          this.getPdf(nota[0].key, resultXml.xml, location);
          break;
        }
      }
    }).catch((error: HttpErrorResponse) => {
      this.enviando = false;
      console.error(error);
      this.snackBar.openLong('Não foi possível consultar o PDF/XML.', 'erro');
    });
  }

  getXml(key, xml) {
    this.enviando = true;
    try {
      var blob = new Blob([xml], { type: "text/xml" });
      var blobUrl = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = blobUrl;
      a.download = key + '.xml';
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
      this.enviando = false;
    } catch (e) {
      this.enviando = false;
      console.error(e);
      this.snackBar.openLong('Não foi possível baixar o XML.', 'erro');
    }
  }

  getPdf(key, xml, location) {
    this.enviando = true;
    switch (location) {
      case 'modal':
        this.consultaPdfXml.getPdfModal({ chave: key, modelo: 'nfe', xml: xml }).then((result) => {
          this.enviando = false;
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          console.error(result);
          this.snackBar.openLong('Não foi possível consultar o PDF/XML.', 'erro');
        });
        break;
      case 'blank':
        this.consultaPdfXml.getPdf({ chave: key, modelo: 'nfe', xml: xml }).then((result) => {
          this.enviando = false;
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          console.error(result);
          this.snackBar.openLong('Não foi possível consultar o PDF/XML.', 'erro');
        });
        break;
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Chave copiada!', 'success');
  }

  cancelarNota(value) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CancelarNotaFiscalComponent, modalOptions);
    modalRef.componentInstance.chave = value;
    modalRef.result.then((result) => {
      this.generateTable();
    });
  }

  novaNota(value) {
    Swal.fire({
      title: 'Preencher nova nota fiscal',
      text: 'Deseja preencher uma nota fiscar a partir desta?',
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, criar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {userId: localStorage.getItem('userId'), nfeId: value.id};
        this.router.navigateByUrl('/notafiscal/emitir-nova-nota-fiscal', {state: obj});
      }
    });
  }

  editarNota(value) {
    // console.log(value);
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarNotaFiscalComponent, modalOptions);
    modalRef.componentInstance.chave = value;
    modalRef.result.then((result) => {
      this.generateTable();
    });
  }

  changeYear(type: string) {
    if (type === '+') {
      this.year = +this.year + 1;
    } else {
      this.year = +this.year - 1;
    }
    let existe = 0;
    this.years.forEach((ano: number) => {
      if (this.year === ano) {
        existe++;
      }
    });
    if (existe === 0) {
      this.years.push(this.year);
      this.years.sort(
        // tslint:disable-next-line:only-arrow-functions
        function(b, a) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
    }
    this.makeRequestMensal();
  }

  getFarmerUserPessoaFiscal(dataInicial, dataFinal) {
    return new Promise((resolve, reject) => {
      this.receiverProvider.getAllReceiversByDate(dataInicial, dataFinal).then((notasFiscais: any) => {
        if (this.showNotasReprovadas) {
          this.receiverProvider.getAllReprovedReceiversByDate(dataInicial, dataFinal).then((notasFiscaisReprovadas: any) => {
            this.setNotasFiscais(notasFiscais.concat(notasFiscaisReprovadas));
          }).catch((err) => {
            //console.log("err", err);
          });
        } else {
          this.setNotasFiscais(notasFiscais);
        }
      }).catch((notasFiscais: HttpErrorResponse) => {
        //console.log("AAAAAAAAAAAAAAAAAAAIAIAIAIAIAIAI")
      });
    });
  }

  setNotasFiscais(notasFiscais) {
    this.notasFiscais = notasFiscais;
    this.nfTypes[0] = 0;
    this.nfTypes[1] = 0;
    this.nfTypes[2] = 0;
    notasFiscais.forEach(element => {
      element.value ? '' : element.value = '';
      if (element.status == 'reprovado') {
        this.nfTypes[0] = this.nfTypes[0] + 1;
      } else if (element.status == 'aprovado') {
        this.nfTypes[1] = this.nfTypes[1] + 1;
      } else if (element.status == 'cancelado') {
        this.nfTypes[2] = this.nfTypes[2] + 1;
      }
    });
    this.enviando = false;
  }

  changePeriodInicial(event) {
    this.makeRequestPersonalizado();
  }


  // aplica o filtro na tabela
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Coloca o valor do notasfiscais no datasource para montar a tabela
  loadTable() {
    this.dataSource = new MatTableDataSource<NotaFiscal>(this.notasFiscais);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  autoFill() {
    this.grid.autoFitColumns(['date', 'options', 'status', 'number', 'participantName', 'value', 'ie', 'description']);
    this.grid.refreshHeader();
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
  }

  // Organiza as funções necessarias para renderizar a tabela, utilizado para quando precisa recarregar a tabela
  generateTable() {
    this.enviando = true;
    this.makeRequestMensal();
    this.loadTable();
    this.enviando = false;
  }


  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  openModalEvents(dados){
      this.eventsProvider.getEventsById(dados.id).then((events: any) => {
        let arr = this.formatEvents(events);
        let modalOptions: NgbModalOptions = {
          backdrop: 'static',
          windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
        };
        const modalRef = this.modalService.open(ModalEventsComponent, modalOptions);
        modalRef.componentInstance.events = arr;
        modalRef.componentInstance.documento = dados;
      }).catch(err => {
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
    });
  }


  formatEvents(events) {
    let arr = [];
    events.forEach(element => {
      let date = new Date(element.dhRegEvento);
      let type = element.type.charAt(0).toUpperCase() + element.type.slice(1).toLowerCase();
      //console.log(element.type);

      if (element.type == 'CARTA_DE_CORRECAO') {
        type = 'Carta de correção';
      }
      if (type.includes('_')) {
        type = type.replaceAll('_', ' ');
      }

      let tmp = {
        id: element.id,
        danfe: element.danfe,
        xml: element.xml,
        date: date.toLocaleString(),
        status: element.status,
        type: type
      };

      arr.push(tmp);
    });

    arr.sort((a, b) => {
      return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
    });

    return arr;
  }

  formatarValor(valorTotal: string) {
    if (valorTotal == '') {
      return '';
    }

    return 'R$ ' + this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ',',
      len: 0,
      mask: '',
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: '.',
      type: undefined,
      userCaracters: false,
      money: true
    });
  }

  donwloadLote(type: string) {
    if (this.selected.length === 0) {
      this.snackBar.openLong('Nenhum documento selecionado! Selecione ao menos um!', 'erro');
    } else {
      this.snackBar.openLong('O arquivo em lote está sendo gerado...', 'atencao');
      this.getFileDfeLote(this.selected, type);
    }
  }

  getFileDfeLote(documents, type) {
    //console.log('documents', documents);
    let ids = documents.map((doc) => doc.id);
    this.receiverProvider.getFileDfeLote(ids, type.toUpperCase()).then((value: any) => {
      const blob = new Blob([value], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = type +"s-lote.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      this.snackBar.openLong('Arquivo ' + type.toUpperCase() + ' gerado com sucesso!', 'success');
    }).catch(() => {
      this.snackBar.openLong('Não foi possível gerar o arquivo em lote.', 'erro');
    });
  }

  calculaTotal(): number {
    let total = 0
    this.selected.forEach(element => {
      total += parseFloat(element.value);
    });
    return total;
  }

  getRow(event) {
    let data = (this.grid.getRowObjectFromUID(event.row.getAttribute('data-uid')).data);
    this.toggleSelectRows(data);
  }

  searchUser(data){
    if(data.user)
      return
   // data.user = 'teste'
   let userId = data.userId
    console.log("USERID: ",userId)
    this.userProvider.getUser(userId).then((result) =>{
      console.log(result)
      data.user = result
          return data.user.name

    })
  }

  returnMatToolTipValue(data){
    if(data.user)
      return data.user.name + " " + data.user.lastName
    else
      return 'Visualizar usuário emissor'
  }

  togglecheckAll() {
    this.selectedAll = !this.selectedAll;
    if (this.selectedAll) {
      this.grid.selectRowsByRange(0, this.grid.getRows().length - 1);
      this.selected = this.grid.getRowsObject().map((row) => row.data)
    } else {
      this.grid.clearSelection();
      this.selected = [];
    }
  }

  toggleSelectRows(document) {
    this.selectedAll = false;
    if (this.checkIfDfeAlreadySelected(document)) {
      this.selected.push(document);
    } else {
      this.selected.splice(this.selected.findIndex(val => val.id === document.id), 1);
    }
  }

  checkIfDfeAlreadySelected(document): boolean {
    return this.selected.findIndex(val => val.id === document.id) === -1;
  }

  setFirstPageTableData(event) {
    this.dfeSelected = [];
    if (event.rows !== undefined) {
      event.rows.forEach(row => {
        this.dfeSelected.push(row.data);
      });
    }
  }

  clearSelection(){
    this.selected = [];
    this.grid.clearSelection();
  }

  removeSelection(doc){
    this.selectedAll = false;
    this.selected.splice(this.selected.findIndex(val => val.id === doc), 1);
    this.grid.clearSelection();
    let index = [];
    this.selected.forEach(element => {
      index.push(this.grid.getRowIndexByPrimaryKey(element.id));
    });
    this.grid.selectRows(index);
  }
}

class NotaFiscal {
  id: number;
  number: number;
  key: number;
  participantName: string;
  natureOperation: string;
  value: number;
  description: string;
  fromDate: string;
  status: string;
}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
