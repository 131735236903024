// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  appVersion: require('../../package.json').version,
  // appVersion: '1.0.23',
  production: false,
  clientId: +localStorage.getItem('idEmpresa'),
  apiLogin: 'https://api.aplicativo.farmcont.com.br',       // MAIN

  // apiLogin: 'http://192.168.0.27:6060',                    // TEST ENV - INTERNO
  // apiLogin: 'http://187.39.52.234:6060',                   // TEST ENV - EXTERNO

  // apiLogin: 'http://ambtestesexterno.ddns.net:6000',                      // LOCAL ENV - EDIMAR
  
  farmcont: 'http://localhost:4401',
  requireHttps: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
