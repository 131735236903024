import {Pipe, PipeTransform} from "@angular/core";
import {ImovelDTO} from "../../model/dto/ImovelDTO";
import {Imovel} from "../../model/Imovel";
import {AddressDTOResponse} from "../../model/dto/AddressDTOResponse";
import {ExplorationType} from "../../model/enum/ExplorationType";

@Pipe({
  name: 'convertImovelDTO',
})
export class ConvertImovelDTO implements PipeTransform {

  transform(value: ImovelDTO): Imovel {
    let imovelDTO: ImovelDTO;
    imovelDTO = value;
    let imovel = new Imovel();
    imovel.propertyCode = imovelDTO.propertyCode;
    imovel.companyId = +localStorage.getItem('idEmpresa');
    imovel.name = imovelDTO.name;
    imovel.itrCode = imovelDTO.itrCode;
    imovel.currency = imovelDTO.currency;
    imovel.explorationType = imovelDTO.explorationType;
    imovel.street = imovelDTO.address.street;
    imovel.houseNumber = imovelDTO.address.houseNumber;
    imovel.neighborhood = imovelDTO.address.neighborhood;
    imovel.complement = imovelDTO.address.complement;
    imovel.cep = imovelDTO.address.cep;
    imovel.countryId = imovelDTO.address.countryDTORespComp.id;
    if (imovelDTO.address.countryDTORespComp.id == 32) {
      imovel.cityId = imovelDTO.address.countryDTORespComp.stateDTORespComp.cityDTORespComp.id;
      imovel.stateId = imovelDTO.address.countryDTORespComp.stateDTORespComp.id;
    }
    return imovel;
  }
}
