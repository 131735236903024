import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";


@Injectable()
export class InfosAdicionaisProvider {

    constructor(public http: HttpClient, public oauthService: OAuthService) {
    }
    apiBase: string = "";

    setApiBase() {
        this.apiBase = `/invoice-issuer/v1/additional-infos/shared/${localStorage.getItem('userId')}`;
    }

    postInfosAdicionais(infosAdicionais) {
        this.setApiBase();
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiLogin + this.apiBase, infosAdicionais, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        })
    }

    getInfosAdicionais() {
        this.setApiBase();
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    putInfosAdicionais(infosAdicionais) {
        this.setApiBase();
        return new Promise((resolve, reject) => {
            this.http.put(environment.apiLogin + this.apiBase, infosAdicionais, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    deleteInfosAdicionais(id) {
        this.setApiBase();
        return new Promise((resolve, reject) => {
            this.http.delete(environment.apiLogin + this.apiBase + `/${id}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        })
    }
}
