import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'groupByMonth',
})
export class GroupByPipeMonth implements PipeTransform {
  transform(value: any, groupByKey: string) {
    const events: any[] = [];
    const groupedElements: any = {};

    if(groupByKey === 'account') {
      value.forEach((obj: any) => {
        if (!(obj.account.description in groupedElements)) {
          groupedElements[obj.account.description] = [];
        }
        groupedElements[obj.account.description].push(obj);
      });

      for (let prop in groupedElements) {
        if (groupedElements.hasOwnProperty(prop)) {
          events.push({
            key: prop,
            sum: this.getSumProp(groupedElements[prop]),
            list: groupedElements[prop]
          });
        }
      }
    } else {
      value.forEach((obj: any) => {
        if (!(obj[groupByKey] in groupedElements)) {
          groupedElements[obj[groupByKey]] = [];
        }
        groupedElements[obj[groupByKey]].push(obj);
      });

      for (let prop in groupedElements) {
        if (groupedElements.hasOwnProperty(prop)) {
          events.push({
            key: prop,
            list: groupedElements[prop]
          });
        }
      }
    }

    return events;
  }

  getSumProp(list) {
    return list.reduce((total, valor) => total + valor.amountPaid, 0);
  }

}
